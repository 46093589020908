import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { RadioInput } from '@src/components/Inputs'
import { SharedAddress } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import { AddressesChildName, MainRouteName } from '@src/hooks/useAccountRouter'

import {
  Container,
  Header,
  Name,
  EditButton,
  Line,
  FirstLine,
  Splitter,
  DefaultAddressRadioInput,
} from './Address.styles'

const regionIntl = new Intl.DisplayNames([], { type: 'region' })

export const Address: React.FC<{
  index: number
  address: SharedAddress
}> = ({ index, address }) => {
  const { t } = useTranslation(['addressBook'])

  return (
    <Container>
      <Header>
        <Name>
          {address.name ?? t('generated_name', { number: index + 1 })}
        </Name>

        <EditButton
          route={{
            mainRouteName: MainRouteName.ADDRESSES,
            childRouteName: AddressesChildName.EDIT,
            pageDataId: address.id,
          }}
          dataTestId={`saved-address-edit-${index}`}
        >
          {t('edit_address')}
        </EditButton>
      </Header>

      <FirstLine>
        {address.firstLine}
        {address.secondLine && `, ${address.secondLine}`}
      </FirstLine>
      {address.thirdLine && <Line>{address.thirdLine}</Line>}
      <Line>{`${address.city} • ${address.postcode}`}</Line>
      <Line>{regionIntl.of(address.countryCode)}</Line>
      <DefaultAddressRadioInput>
        <RadioInput
          name="defaultAddressId"
          value={address.id}
          label={t('default.label')}
        />
      </DefaultAddressRadioInput>
      <Splitter />
    </Container>
  )
}

export const AddressSkeleton: React.VFC = () => {
  return (
    <SkeletonTheme
      baseColor="rgba(150,150,150, 0.1)"
      highlightColor="rgba(255,255,255, 0.1)"
      enableAnimation={false}
    >
      <Container>
        <Header>
          <Skeleton height={40} width={90} />
          <Skeleton height={40} width={72} />
        </Header>
        <Line>
          <Skeleton height={24} width={120} />
        </Line>
        <Line>
          <Skeleton height={24} width={150} />
        </Line>
      </Container>
    </SkeletonTheme>
  )
}
