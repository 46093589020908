import { useQuery, useReactiveVar } from '@apollo/client'

import { OrderStatus } from '@src/graphql-types'
import { jwtVar } from '@src/models/customer/jwt'

import { getMyOrdersHistoryDocument } from './sharedQueries/orderHistoryQuery/queries/__generated__/getMyOrdersHistory.graphql-interface'

const PAGE_SIZE = 5

const activeOrderStatuses: OrderStatus[] = [
  OrderStatus.PENDING,
  OrderStatus.AUTH_ACTION_PENDING,
  OrderStatus.AWAITING_PAYMENT,
  OrderStatus.AWAITING_DELIVERY_NETWORK,
  OrderStatus.READY,
]

export const useHasActiveOrders = () => {
  const jwt = useReactiveVar(jwtVar)

  const { data } = useQuery(getMyOrdersHistoryDocument, {
    skip: !jwt,
    variables: {
      input: { pageSize: PAGE_SIZE },
    },
  })

  const hasActiveOrders =
    data &&
    data.orders.edges.some(
      order =>
        order.node.orderStatus &&
        activeOrderStatuses.includes(order.node.orderStatus)
    )

  return { hasActiveOrders }
}
