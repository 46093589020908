import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const CheckoutTextContainer = styled.div`
  border-radius: 4px;
  color: #2e3333;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
`

export const IconContainer = styled.div`
  width: 28px;
  height: 24px;
  background-color: #ebecf0;
  border-radius: 12px;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`

export const CheckoutText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin: 12px 8px;
  width: 100%;
`

export const Container = styled.div`
  padding: 0px 24px;
  flex-grow: 1;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 0 32px;
  }
`

export const Description = styled.h3`
  font-size: 20px;
  margin-top: 28px;
  font-weight: 700;
`

export const PaymentError = styled.h3`
  font-size: 18px;
  margin-top: 28px;
  font-weight: 700;
  color: red;
`

export const PaymentMethodContainer = styled.div<{
  disabled?: boolean
}>`
  width: 100%;
  font-weight: 700;
  margin-bottom: 8px;

  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`

export const RadioInfoLabel = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400px;

  p {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
  }
`

export const RadioWrapper = styled.div`
  margin-top: 18px;
`

export const InnerContainer = styled.div<{
  selected: boolean
}>`
  border-radius: 8px;
  padding: 0 0 0 16px;
  padding-left: ;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  margin: 0 16px 4px 0;

  ${({ selected }) =>
    selected &&
    css`
      background: #f6f6f9;
      padding: 0px 16px 8px 16px;
      margin: 8px 0 0px 0;
    `};
`

export const AddCardContainer = styled.div`
  margin-bottom: 8px;
`
