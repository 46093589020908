import React from 'react'
import { useTranslation } from 'react-i18next'

export const InfoSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <title id={`${id}Title`}>{title || t('basket_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <g fill="currentColor">
        <circle
          cx="9"
          cy="9"
          r="7.25"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></circle>
        <line
          x1="9"
          y1="12.819"
          x2="9"
          y2="8.25"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          data-color="color-2"
        ></line>
        <path
          d="M9,6.75c-.552,0-1-.449-1-1s.448-1,1-1,1,.449,1,1-.448,1-1,1Z"
          fill="currentColor"
          data-color="color-2"
          data-stroke="none"
          stroke="none"
        ></path>
      </g>
    </svg>
  )
}
