import { useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { apolloClient } from '@src/apolloClient'
import { TextInput, FormError } from '@src/components/Inputs'
import { LocationType } from '@src/graphql-types'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSecondaryNavigation } from '@src/hooks/useSecondaryNavigation'
import { jwtVar } from '@src/models/customer/jwt'

import {
  Container,
  ForgotPasswordButton,
  ForgotPasswordContainer,
  JoinNowLink,
  SubmitButton,
  TopRow,
  CreateAccountButton,
  SignUpLink,
  Header,
  HeaderContainer,
} from './Login.styles'
import { loginDocument } from './mutations/__generated__/login.graphql-interface'
import { LoginInput, loginInputSchema } from './validation/loginStructure'

import { StyledButton, StyledArrowLeftSVG } from '../../Drawer/Drawer.styles'
import { DrawerHeader, DrawerHeaderActions } from '../../Drawer/DrawerHeader'

const initialValues: LoginInput = {
  email: '',
  password: '',
}

export const Login: React.FC<{
  isCheckout?: boolean
  onLoggingIn?: () => void
  navigateToSignUp?: () => void
  navigateToForgotPassword?: () => void
}> = ({
  isCheckout,
  onLoggingIn,
  navigateToSignUp,
  navigateToForgotPassword,
}) => {
  const {
    data: { where: fulfilmentFilterWhere },
    setLocationType,
  } = useFulfilmentFilter()
  const { key: marketplaceKey } = useMarketplace()
  const { navigateToMainContent } = useSecondaryNavigation()
  const { t } = useTranslation('signIn')
  const [login, { loading, error }] = useMutation(loginDocument, {
    onCompleted(data) {
      jwtVar(data.login.token)
      void apolloClient.refetchQueries({
        include: 'active',
      })
      if (onLoggingIn) {
        onLoggingIn()
      }
      const defaultAddressId = data.login.customer.deliveryAddresses.find(
        address => address.default
      )?.id
      if (
        fulfilmentFilterWhere.location.type === LocationType.EVERYWHERE &&
        defaultAddressId
      ) {
        setLocationType({
          type: LocationType.ADDRESS,
          addressId: defaultAddressId,
        })
      }
      isCheckout && onLoggingIn ? onLoggingIn() : navigateToMainContent()
    },
  })

  const checkoutRouter = useCheckoutRouter()
  const { featureRegister } = useMarketplace()

  return (
    <>
      {!isCheckout && (
        <DrawerHeader
          action={DrawerHeaderActions.BACK}
          headerText={t('sign_in')}
        />
      )}

      <Container isCheckout={isCheckout}>
        {isCheckout ? (
          <>
            <HeaderContainer>
              <Header>{t('sign_in')}</Header>
              <StyledButton onClick={checkoutRouter.prev}>
                <StyledArrowLeftSVG id="back_button" />
              </StyledButton>
            </HeaderContainer>
            <CreateAccountButton
              onClick={() => {
                if (navigateToSignUp) {
                  navigateToSignUp()
                }
              }}
            >
              {t('new_customer')} <SignUpLink>{t('sign_up')}</SignUpLink>
            </CreateAccountButton>
          </>
        ) : (
          <TopRow>
            {featureRegister && (
              <JoinNowLink
                to="/?account=register"
                passthroughQueryParams={false}
              >
                {t('new_customer')} <SignUpLink>{t('sign_up')}</SignUpLink>
              </JoinNowLink>
            )}
          </TopRow>
        )}

        {error && <FormError>{error.message}</FormError>}

        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(loginInputSchema)}
          validateOnChange
          validateOnBlur={false}
          onSubmit={loginDetails => {
            void login({
              variables: { ...loginDetails, marketplaceKey },
            })
          }}
        >
          <Form noValidate>
            <TextInput
              name="email"
              label={t('email_address')}
              autoComplete="username"
              required
              disabled={loading}
              focusOnLoad
              dataTestId="sign-in-email-address-input"
            />
            <TextInput
              type="password"
              name="password"
              label={t('password')}
              autoComplete="current-password"
              required
              disabled={loading}
              dataTestId="sign-in-password-input"
            />
            <ForgotPasswordContainer>
              {isCheckout ? (
                <CreateAccountButton
                  onClick={() => {
                    navigateToForgotPassword?.()
                  }}
                  type="button"
                >
                  {t('forgot_password')}
                </CreateAccountButton>
              ) : (
                <ForgotPasswordButton
                  to="/?account=login&accountChild=reset-password"
                  passthroughQueryParams={false}
                >
                  {t('forgot_password')}
                </ForgotPasswordButton>
              )}
            </ForgotPasswordContainer>

            <SubmitButton
              type="submit"
              loading={loading}
              content={t('sign_in')}
              isCheckout={isCheckout}
              dataTestId="sign-in-submit-button" // different to sign-in-button in the burger menu
            />
          </Form>
        </Formik>
      </Container>
    </>
  )
}
