import * as Types from '../../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type addOnMenuItemsQueryVariables = Types.Exact<{
  outletId: Types.Scalars['ID']
  fulfilmentMethods:
    | Array<Types.MenuItemGroupFulfillmentMethod>
    | Types.MenuItemGroupFulfillmentMethod
  subTotal: Types.Scalars['Int']
  endOfPrep?: Types.InputMaybe<Types.Scalars['DateTime']>
}>

export type addOnMenuItemsQuery = {
  addOnMenuItems: Array<{
    __typename: 'OutletMenuItem'
    id: string
    menuItemId: string
    name: string
    addOnMaxPrice: number | null
    maxPurchaseQuantity: number | null
    price: number
    isVegetarian: boolean | null
    isVegan: boolean | null
    isGlutenFree: boolean | null
    isDairyFree: boolean | null
    isKeto: boolean
    spiceLevel: number
    ageRestricted: boolean | null
    image: string | null
    description: string | null
  }>
}

export const addOnMenuItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'addOnMenuItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentMethods' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'MenuItemGroupFulfillmentMethod',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subTotal' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endOfPrep' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addOnMenuItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fulfilmentMethods' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fulfilmentMethods' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subTotal' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subTotal' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endOfPrep' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endOfPrep' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'menuItemId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addOnMaxPrice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxPurchaseQuantity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isVegetarian' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isVegan' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isGlutenFree' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isDairyFree' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isKeto' } },
                { kind: 'Field', name: { kind: 'Name', value: 'spiceLevel' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ageRestricted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<addOnMenuItemsQuery, addOnMenuItemsQueryVariables>
