import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

import { BasketSVG } from '../../SVGS/BasketSVG'

export const EmptyBasketOuterContainer = styled.div`
  height: 0;
  padding: 16px;

  @media (min-width: ${breakpoints.tabletMisc}px) {
    padding: 32px;
  }
`

export const EmptyBasketContainer = styled.div`
  background-color: #f6f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  padding: 64px 16px;
  margin-bottom: 16px;
  flex-direction: column;
`

export const StyledBasketSVG = styled(BasketSVG)`
  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    80% {
      transform: rotate(0deg);
    }
    85% {
      transform: rotate(10deg);
    }
    95% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  animation: wiggle 2.5s infinite;
  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.colors.brand};
  margin-bottom: 1px;
`
