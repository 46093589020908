import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const DealsAndOffersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 22px 0px 16px 16px;
  overflow-y: hidden;
  overflow-x: scroll;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 32px;
  }
`

export const SingleDiscountContainer = styled.div`
  display: flex;
`
