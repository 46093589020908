import React from 'react'

export const SmallSuccessTick: React.FC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      height="18"
      width="18"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <circle cx="9" cy="9" r="7.25"></circle>
        <polyline
          points="5.75 9.25 8 11.75 12.25 6.25"
          data-color="color-2"
        ></polyline>
      </g>
    </svg>
  )
}
