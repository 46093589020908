import styled, { css } from 'styled-components'

import { InfoSVG } from '@src/components/SVGS/InfoSVG'
import { breakpoints } from '@src/constants/breakpoints'

import { CartSmallSVG } from '../../../components/SVGS/CartSmallSVG'
import { StarSVG } from '../../../components/SVGS/StarSVG'

export const Container = styled.div<{
  compactStyle: boolean
  isLongTitle: boolean
}>`
  background-color: white;
  padding: 0px 8px 0px 8px;
  transition: ease-in-out 0.3s;
  height: ${({ compactStyle, isLongTitle }) => {
    if (compactStyle) {
      if (isLongTitle) {
        return '80px'
      }
      return '56px'
    }
    return 'auto'
  }};
  overflow: hidden;
  z-index: 20;

  @media (min-width: ${breakpoints.tablet}px) {
    height: ${({ compactStyle }) => (compactStyle ? '120px' : 'auto')};
    padding: 0px 16px 0px 16px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    height: ${({ compactStyle }) => (compactStyle ? '90px' : 'auto')};
  }
`

export const FirstRow = styled.div<{ compactStyle: boolean }>`
  display: flex;
  justify-content: space-between;
`

export const ButtonRow = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 8px 0px 0px 38px;

  @media (min-width: ${breakpoints.tablet}px) {
    align-items: center;
  }
`

export const DealsRow = styled.div<{
  compactStyle: boolean
  isLongTitle: boolean
}>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.flag};
  font-weight: 700;
  margin: ${({ isLongTitle }) => (isLongTitle ? '0px' : '4px 0 0')};
  line-height: 15px;
  font-size: 14px;
  width: 95%;
  display: ${({ compactStyle }) => (compactStyle ? 'none' : 'relative')};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 18px;
    margin: ${({ isLongTitle }) => (isLongTitle ? '0px 0px 8px;' : '8px 0')};
    line-height: 20px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    font-size: 18px;
    margin: ${({ isLongTitle }) => (isLongTitle ? '8px 0 ' : '10px 0 8px ')};
    line-height: 20px;
    width: 100%;
  }
`

export const StarSVGStyled = styled(StarSVG)`
  width: 18px;
  height: 18px;
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.flag};
`

export const OutletInfoOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;

  @media (min-width: ${breakpoints.largeTablet}px) {
    padding-bottom: 0px;
    margin-left: 45px;
    width: 80%;
    margin-bottom: 16px;
  }
`

export const OutletInfoRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 8px;
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
  }
`

export const CuisinesAndMinOrderContainer = styled.div`
  display: flex;
  margin-right: 20px;

  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: row;
    margin-right: 0px;
  }
`

export const OutletDetailsContainer = styled.div<{ compactStyle: boolean }>`
  white-space: ${({ compactStyle }) => (compactStyle ? 'no-wrap' : 'pre-wrap')};
  margin-top: 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-top: 8px;
    line-height: normal;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    flex-direction: row;
  }
`

export const InnerDetails = styled.div`
  margin-top: 8px;
  margin-left: 2px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-top: 16px;
    margin-left: 22px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-top: 16px;
    margin-left: 10px;
  }
`
export const TitleBar = styled.div<{
  isLongTitle: boolean
  compactStyle: boolean
}>`
  font-size: 20px;
  font-weight: 700;
  display: flex;
  color: #2e3333;
  text-overflow: ellipsis;
  align-items: flex-start;
  width: 90%;
  white-space: nowrap;
  line-height: 1.2;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 38px;
    align-items: center;
    margin-bottom: 4px;

    line-height: ${({ compactStyle, isLongTitle }) =>
      compactStyle && isLongTitle ? '1.2' : '45px'};
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-left: 0px;
    margin-bottom: 0px;
    align-items: center;
    margin-right: ${({ compactStyle }) => !compactStyle && 'auto'};
    line-height: 1.2;
    max-width: ${({ compactStyle, isLongTitle }) =>
      compactStyle && isLongTitle ? 'auto' : '72%'};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    max-width: ${({ compactStyle, isLongTitle }) =>
      compactStyle && isLongTitle ? 'auto' : '80%'};
  }
`

export const OutletTitle = styled.div<{
  isLongTitle: boolean
  compactStyle: boolean
}>`
  ${({ isLongTitle, compactStyle }) =>
    isLongTitle &&
    css`
      padding-bottom: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${compactStyle ? '1' : '2'};
      -webkit-box-orient: vertical;
      white-space: pre-wrap;
      @media (min-width: ${breakpoints.tablet}px) {
        line-height: ${compactStyle ? 'inherit' : '45px'};
        font-size: ${compactStyle ? '22px' : 'inherit'};
        box-sizing: border-box;
      }

      @media (min-width: ${breakpoints.largeTablet}px) {
        font-size: ${compactStyle ? '22px' : 'inherit'};
        position: relative;
        top: ${!compactStyle && isLongTitle ? '-7px' : '0'};
      }
    `}
`

export const FulfilmentPill = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin-right: 40px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 5px 16px 5px 20px;
    border-radius: 50px;
    background-color: ${({ theme, status }) => {
      if (status === 'open' || status === 'order') {
        return `${theme.colors.openStatus}20`
      }
      if (status === 'pre_order') {
        return `${theme.colors.preorderStatus}20`
      }
      return '#93939320'
    }};
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-left: 20px;
  }
`

export const DetailsButton = styled.span<{ compactStyle: boolean }>`
  color: ${({ theme }) => theme.colors.brand};
  font-weight: 700;
  text-decoration: underline;
  font-size: 12px;
  transition: ease-in-out 0.3s visibility;
  margin-top: 6px;
  display: flex;
  cursor: pointer;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
    margin-top: 0px;
  }

  &:hover {
    text-decoration: none;
  }
`

export const CartSmallSVGStyled = styled(CartSmallSVG)`
  margin-right: 4px;
  width: 12px;
  height: 12px;
  @media (min-width: ${breakpoints.tablet}px) {
    width: 16px;
    height: 16px;
  }
`

export const InfoSVGStyled = styled(InfoSVG)`
  width: 14px;
  height: 14px;
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.brand};

  @media (min-width: ${breakpoints.tablet}px) {
    width: 18px;
    height: 18px;
  }
`

export const OpenStatus = styled.p<{ status: string }>`
  font-size: 12px;
  font-weight: 700;
  padding-right: 8px;
  margin: 0;
  color: ${({ theme, status }) => {
    if (status === 'open' || status === 'order') {
      return theme.colors.openStatus
    }
    if (status === 'pre_order') {
      return theme.colors.preorderStatus
    }
    return '#939393'
  }};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

export const MinOrderText = styled.span<{ compactStyle: boolean }>`
  display: flex;
  align-items: center;

  color: #939393;
  font-size: 12px;
  transition: ease-in-out 0.3s visibility;

  line-height: 15px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Cuisines = styled.div<{ compactStyle: boolean }>`
  display: ${({ compactStyle }) => (compactStyle ? 'none' : 'realtive')};
  color: #939393;
  font-size: 12px;
  line-height: 15px;
  transition: ease-in-out 0.3s visibility;
  margin-right: 12px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
    line-height: 20px;
    margin-right: 20px;
  }
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
  compactStyle: boolean
  permanentCheckout?: boolean
}>`
  background-size: cover;
  min-width: 48px;
  height: 48px;
  background-color: #f6f6f9;
  transition: ease-in-out 0.2s;
  opacity: 1;
  filter: drop-shadow(-2px 2px 1px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  margin: 10px 2px 0px 0px;
  position: relative;

  @media (min-width: ${breakpoints.tablet}px) {
    min-width: 116px !important;
    height: 116px !important;
    margin: 16px 0 0 0;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    min-width: 150px !important;
    height: 150px !important;
    position: absolute;
    right: 190px;
    top: 85px;
  }

  @media (min-width: ${breakpoints.wideDesktop}px) {
    ${({ permanentCheckout }) =>
      permanentCheckout &&
      css`
        right: 664px;
      `};
  }

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    ${({ permanentCheckout }) =>
      permanentCheckout &&
      css`
        right: 679px;
      `};
  }

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};

  ${({ compactStyle }) =>
    compactStyle
      ? css`
          min-width: 0;
          width: 0;
          height: 0;
          opacity: 0;
          padding: 0;
          margin: 0;
        `
      : css`
          @media (min-width: ${breakpoints.tablet}px) {
            min-width: 120px;
            height: 120px;
            border-radius: 8px;
          }
        `}
`

export const CoverImage = styled.div<{
  imageUrl: string
  compactStyle: boolean
}>`
  background-size: cover;
  min-width: 42px;
  height: 42px;
  background-color: #f6f6f9;
  transition: ease-in-out 0.2s;
  opacity: 1;
  margin-top: 8px;
  margin-right: 2px;
  display: flex;
  border-radius: 4px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    min-width: 64px;
    height: 64px;
    margin-top: 16px;
    margin-right: 4px;
  }

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};

  ${({ compactStyle }) =>
    compactStyle
      ? css`
          display: none;
        `
      : css`
          @media (min-width: ${breakpoints.tablet}px) {
            min-width: 200px;
            height: 200px;
            border-radius: 8px;
          }
        `}
`

export const MenuWrapper = styled.div<{
  headerHeight: number | null
}>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ headerHeight }) =>
    headerHeight ? `${headerHeight}px` : '330px'};

  @media (min-width: ${breakpoints.tablet}px) {
    padding-top: ${({ headerHeight }) =>
      headerHeight ? `${headerHeight}px` : '420px'};
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    padding-top: ${({ headerHeight }) =>
      headerHeight ? `${headerHeight}px` : '396px'};
  }
`

export const ObservableDiv = styled.div`
  position: absolute;
  background-color: transparent;
  width: 1px;
  height: 200px;
  top: 60px;
`

export const CompactHeaderTabContainer = styled.div`
  display: flex;
  margin-left: auto;
`

export const TabletTitleAndPillContainer = styled.div<{
  compactStyle: boolean
}>`
  cursor: ${({ compactStyle }) => compactStyle && 'pointer'};
  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
    display: flex;
    margin-bottom: 8px;
  }

  @media (min-width: ${breakpoints.largeTablet}px) {
    margin-bottom: 0px;
  }
`

export const HeaderContainer = styled.div<{
  compactStyle: boolean
  isLongTitle: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;

  @media (min-width: ${breakpoints.tablet}px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0px;
  }
  @media (min-width: ${breakpoints.largeTablet}px) {
    flex-direction: row;
    width: 100%;

    align-items: ${({ isLongTitle, compactStyle }) =>
      isLongTitle && !compactStyle ? 'flex-start' : 'center'};
  }
`

export const TitleAndPillContainer = styled.div<{
  compactStyle: boolean
  isLongTitle: boolean
}>`
  display: flex;
  flex-direction: column-reverse;
  cursor: ${({ compactStyle }) => compactStyle && 'pointer'};

  align-items: flex-start;
  justify-content: flex-start;
  max-width: ${({ compactStyle }) => (compactStyle ? '80%' : '85%')};

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
    max-width: ${({ compactStyle }) => (compactStyle ? '100%' : '90%')};
  }
  @media (min-width: ${breakpoints.largeTablet}px) {
    flex-direction: row;
    align-items: ${({ isLongTitle, compactStyle }) =>
      isLongTitle && !compactStyle ? 'flex-start' : 'center'};
    min-height: 0;
    justify-content: flex-start;
    max-width: ${({ compactStyle }) => (compactStyle ? '50%' : '80%')};
  }

  @media (min-width: ${breakpoints.desktop}px) {
    max-width: ${({ compactStyle, isLongTitle }) => {
      if (isLongTitle) {
        if (compactStyle) {
          return '50%'
        }
        return '85%'
      }
      return '100%'
    }}
`
