import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { Carousel } from '@src/components/Carousel'

import {
  HeroContainer,
  OutletContainer,
  HeaderSkeleton,
  FeaturedOutletsContainerSkeleton,
} from './FeaturedOutlets.styles'

import { OutletSlideSkeleton } from '../OutletSlide/OutletSlideSkeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const FeaturedOutletsSkeleton: React.VFC = () => {
  return (
    <SkeletonTheme
      baseColor="rgba(150,150,150, 0.1)"
      highlightColor="rgba(255,255,255, 0.1)"
      enableAnimation={false}
    >
      <OutletContainer>
        <HeroContainer>
          <HeaderSkeleton>
            <Skeleton width={200} />
            <Skeleton width={300} />
          </HeaderSkeleton>
        </HeroContainer>
        <FeaturedOutletsContainerSkeleton>
          <Carousel
            slides={Array(8)
              .fill(0)
              .map((_, i) => (
                <OutletSlideSkeleton key={i} />
              ))}
          />
        </FeaturedOutletsContainerSkeleton>
      </OutletContainer>
    </SkeletonTheme>
  )
}
