import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { effectiveFulfillmentMethod } from '@src/components/SingleOutlet/utils/effectiveFulfilmentMethod'
import { TextClamp } from '@src/components/Text/Clamp'
import { breakpoints } from '@src/constants/breakpoints'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { screenResolutionVar } from '@src/models/screenResolution'
import {
  MenuItemCounter,
  MenuItemCounterText,
} from '@src/pages/OutletPage/OutletMenu/MenuItem/MenuItem.styles'
import { imageJitURL } from '@src/utils/imageJitURL'

import { StyledCartSVG } from './OutletCard.styles'
import {
  OutletDetailsContainer,
  OutletName,
  CuisinesContainer,
  CuisineTag,
  Cuisine,
  OutletOpeningContainer,
  LongSquareButton,
  RestaurantLogo,
  CoverImage,
  CuisineDot,
  RestaurantContainer,
  DiscountName,
  DiscountContainer,
  DiscountsContainer,
  OfferContainer,
  OfferText,
  InfoContainer,
  StyledDiscountSVG,
  OrderInfoContainer,
  OpenStatusContainer,
  LinkToOutletContainer,
  StarSVGStyled,
  StyledDiscountManySVG,
} from './SingleOutlet.styles'
import {
  SingleOutlet as SingleOutletType,
  SingleOutletDirection,
} from './types'

import { OutletStatusTranslationText } from '../OutletStatus/OutletStatusTranslationText'

export const SingleOutlet: React.FC<{
  outlet: Pick<
    SingleOutletType,
    | 'id'
    | 'displayName'
    | 'ASAPDeliveryDuration'
    | 'prepTime'
    | 'restaurant'
    | 'availableFulfilmentInputMethods'
    | 'deliveryMinimumOrderValue'
    | 'collectionMinimumOrderValue'
    | 'distanceFromUserKM'
    | 'coverImage'
    | 'statusText'
    | 'isOpen'
    | 'outletLogoOverride'
    | 'isOnline'
    | 'nextOpenDate'
    | 'openingTimesArray'
    | 'asapAllowed'
    | 'allowPreorders'
    | 'collectionPreorderTimes'
    | 'fulfilmentRange'
    | 'outletAllDiscounts'
    | 'specialOfferFlags'
    | 'outletCuisines'
  >
  direction?: SingleOutletDirection
  isSlide?: boolean
  showFeaturedFlags?: boolean
  showDiscountLabels?: boolean
  dataTestId?: string
}> = ({
  outlet,
  direction = SingleOutletDirection.COLUMN,
  isSlide = false,
  showFeaturedFlags = true,
  showDiscountLabels = true,
  dataTestId,
}) => {
  const screenResolution = useReactiveVar(screenResolutionVar)
  const basketItems = useBasketItems()
  const { t } = useTranslation(['discounts', 'outletCard', 'outlet'])
  const formatCurrency = useFormatCurrency(false)
  const {
    data: { priorityFulfilmentMethod },
  } = useFulfilmentFilter()

  const effectiveFulfilmentMethod = effectiveFulfillmentMethod(
    outlet.availableFulfilmentInputMethods,
    priorityFulfilmentMethod
  )

  const fulfilmentToDealMap: Record<
    NarrowFulfilmentMethodInputType,
    string | undefined | null
  > = {
    [NarrowFulfilmentMethodInputType.COLLECTION]:
      outlet.specialOfferFlags?.collectionDeal,
    [NarrowFulfilmentMethodInputType.DELIVERY]:
      outlet.specialOfferFlags?.deliveryDeal,
    [NarrowFulfilmentMethodInputType.TABLE]:
      outlet.specialOfferFlags?.tableDeal,
  }
  const deal = fulfilmentToDealMap[effectiveFulfilmentMethod]

  const showCoverImage = isSlide || screenResolution.width >= breakpoints.tablet

  const outletWithBasketItems = basketItems.items.some(
    basket => basket.outletMenuItemId.split(':')[0] === outlet.id
  )

  const basketItemCount =
    outletWithBasketItems &&
    basketItems.items.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)

  return (
    <LinkToOutletContainer
      outletId={outlet.id}
      name={outlet.displayName}
      status={outlet.statusText.orderButtonTranslation}
      dataTestId={dataTestId}
    >
      {showFeaturedFlags && deal && (
        <OfferContainer>
          <StarSVGStyled />
          <OfferText>
            <TextClamp clamp={1}>{deal}</TextClamp>
          </OfferText>
        </OfferContainer>
      )}

      {effectiveFulfilmentMethod !== NarrowFulfilmentMethodInputType.TABLE && (
        <>
          {showDiscountLabels && outlet.outletAllDiscounts && (
            <DiscountsContainer>
              {outlet.outletAllDiscounts?.length > 1 ? (
                <DiscountContainer>
                  <DiscountName>
                    <StyledDiscountManySVG id={'many-discount-svg'} />
                    <TextClamp>
                      {t('x_offers_available', {
                        discountCount: outlet.outletAllDiscounts.length,
                        ns: 'discounts',
                      })}
                    </TextClamp>
                  </DiscountName>
                </DiscountContainer>
              ) : (
                outlet.outletAllDiscounts.map(discount => (
                  <DiscountContainer key={discount.id}>
                    <DiscountName>
                      <StyledDiscountSVG id={discount.id} />
                      <TextClamp>{discount.name}</TextClamp>
                    </DiscountName>
                  </DiscountContainer>
                ))
              )}
            </DiscountsContainer>
          )}
        </>
      )}

      <RestaurantContainer
        hasOffer={isSlide || !!deal}
        direction={direction}
        isSlide={isSlide}
      >
        <RestaurantLogo
          role="img"
          aria-label={`${outlet.displayName} logo`}
          imageUrl={imageJitURL(
            outlet.outletLogoOverride || outlet.restaurant.image,
            {
              resize: {
                width: 88,
                height: 88,
                fit: 'cover',
              },
            }
          )}
          isSlide={isSlide}
        >
          {outletWithBasketItems && basketItemCount && (
            <MenuItemCounter isOutletCard={true}>
              <MenuItemCounterText>{basketItemCount}</MenuItemCounterText>
            </MenuItemCounter>
          )}
        </RestaurantLogo>

        {showCoverImage && (
          <CoverImage
            title={`${outlet.displayName} cover image`}
            role="img"
            aria-label={`${outlet.displayName} cover image`}
            imageUrl={imageJitURL(outlet.coverImage, {
              resize: {
                width: 300,
                height: 176,
                fit: 'cover',
              },
            })}
          />
        )}

        <OutletDetailsContainer isSlide={isSlide}>
          <InfoContainer>
            <OutletName>
              <TextClamp>{outlet.displayName}</TextClamp>
            </OutletName>
            {/* TODO: make this a component as it is reused in OutletOverview.tsx */}
            <CuisinesContainer isSlide={isSlide}>
              {outlet.outletCuisines
                .slice(0, 3)
                .map((cuisine: { name: string }, index: number) => {
                  return (
                    <CuisineTag key={index}>
                      {index !== 0 ? <CuisineDot /> : null}

                      <Cuisine>{cuisine.name}</Cuisine>
                    </CuisineTag>
                  )
                })}
            </CuisinesContainer>
          </InfoContainer>

          <OutletOpeningContainer>
            <OpenStatusContainer>
              <OutletStatusTranslationText
                statusText={outlet.statusText}
                isOutletPage={false}
              />
              <OrderInfoContainer>
                {effectiveFulfilmentMethod ===
                NarrowFulfilmentMethodInputType.DELIVERY ? (
                  <>
                    {outlet.deliveryMinimumOrderValue ? (
                      <span>
                        <StyledCartSVG id="min-order-val" />
                        {formatCurrency(outlet.deliveryMinimumOrderValue)}{' '}
                        {t('minimum_order', { ns: 'outletCard' })}
                      </span>
                    ) : undefined}
                  </>
                ) : undefined}
                {effectiveFulfilmentMethod ===
                NarrowFulfilmentMethodInputType.COLLECTION ? (
                  <>
                    {outlet.collectionMinimumOrderValue ? (
                      <span>
                        <StyledCartSVG id="min-order-val" />
                        {formatCurrency(
                          outlet.collectionMinimumOrderValue
                        )}{' '}
                        {t('minimum_order', { ns: 'outletCard' })}
                      </span>
                    ) : undefined}
                  </>
                ) : undefined}
              </OrderInfoContainer>
            </OpenStatusContainer>
            <LongSquareButton
              status={outlet.statusText.orderButtonTranslation}
              content={t(outlet.statusText.orderButtonTranslation, {
                ns: 'outlet',
              })}
            />
          </OutletOpeningContainer>
        </OutletDetailsContainer>
      </RestaurantContainer>
    </LinkToOutletContainer>
  )
}
