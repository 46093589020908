export const addBusiness = {
  add_business: 'Add Your Business',
  required: '* Required',
  add_business_desc:
    'If you would like to join {{marketplaceName}} please complete this application and we will be in touch with more details.',
  business_details: 'Business Details',
  business_name: {
    label: 'Business Name',
    required: 'Business Name is Required',
    invalid_length: 'Business name must contain 3 or more characters',
  },
  business_email: {
    label: 'Business Email Address',
    required: 'Business Email is Required',
    invalid_format: 'Valid Email is Required',
  },
  business_address: {
    label: 'Business Address',
    first_line: {
      label: 'House Number/Name',
      required: 'House Number/Name is Required',
    },
    second_line: {
      label: 'Street Name',
      required: 'Street Name is Required',
      invalid_length: 'Street name must contain 2 or more characters',
    },
    city: {
      label: 'Town',
      required: 'Town is Required',
      invalid_length: 'Town must have at least two characters',
    },
    postcode: {
      label: 'Postcode',
      required: 'Postcode is Required',
    },
    country_code: {
      label: 'Country',
      required: 'Country is Required',
    },
  },
  business_phone: {
    label: 'Business Phone Number',
    required: 'Phone Number is Required',
    invalid_format: 'Invalid Phone Number',
  },
  owner_name: { label: 'Owner Name', required: 'Owner Name is Required' },
  owner_details: 'Owner Details',
  owner_address: {
    label: 'Owner Address',
    first_line: {
      label: 'House Number/Name',
      required: 'House Number/Name is Required',
    },
    second_line: {
      label: 'Street Name',
      required: 'Street Name is Required',
      invalid_length: 'Street name must contain 2 or more characters',
    },
    city: {
      label: 'Town',
      required: 'Town is Required',
      invalid_length: 'Town must have at least two characters',
    },
    postcode: {
      label: 'Postcode',
      required: 'Postcode is Required',
    },
    country_code: {
      label: 'Country',
      required: 'Country is Required',
    },
  },
  owner_phone: {
    label: 'Owner Phone Number',
    required: 'Phone number is Required',
    invalid_format: 'Invalid Phone Number',
  },
  owner_email: {
    label: 'Owner Email Address',
    required: 'Owner Email is Required',
    invalid_format: 'Valid Email is Required',
  },
  submit: 'Send Request',
  request_sent: 'Application Sent',
  request_sent_desc:
    "We will be in touch once we've processed your application to join {{marketplaceName}}.",
  error_submitting: 'Something went wrong',
}
