import React from 'react'

import { SpicyChiliSVG } from '@src/components/SVGS/SpicyChiliSVG'

interface SpiceLevelProps {
  level: number
}

export const SpiceLevel: React.FC<SpiceLevelProps> = ({ level }) => {
  if (level <= 0) return null
  return (
    <>
      {Array.from({ length: level }).map((_, index) => (
        <SpicyChiliSVG width={20} height={20} key={index} />
      ))}
    </>
  )
}
