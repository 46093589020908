import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'

import { FulfilmentWhereLabelDeliveryZone } from './FulfilmentWhereLabelDeliveryZone'
import { FulfilmentWhereLabelSavedAddress } from './FulfilmentWhereLabelSavedAddress'
import { FulfilmentWhereLabelTable } from './FulfilmentWhereLabelTable'

export const FulfilmentWhereLabel: React.FC<{
  stateType: OutletFulfilmentStateType
}> = ({ stateType }) => {
  const {
    outlet,
    data: { currentFulfilment },
  } = useOutletFulfilment({ stateType })
  const { t } = useTranslation('serviceNavigation')

  if (
    (!outlet.isOpen || !outlet.isOnline) &&
    [
      CurrentFulfilmentType.TABLE,
      CurrentFulfilmentType.TABLE_UNSELECTED,
    ].includes(currentFulfilment.type)
  ) {
    return <span>{t('closed')}</span>
  }

  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_EVERYWHERE) {
    return <span>{t('select_a_location')}</span>
  }

  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_POSTCODE) {
    return <span>{currentFulfilment.postAndCountryCode.postcode}</span>
  }

  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_COORDINATES) {
    return <span>{t('near_me')}</span>
  }

  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS) {
    return (
      <FulfilmentWhereLabelSavedAddress
        addressId={currentFulfilment.addressId}
      />
    )
  }

  if (currentFulfilment.type === CurrentFulfilmentType.DELIVERY_ZONE) {
    return (
      <FulfilmentWhereLabelDeliveryZone
        currentFulfilment={currentFulfilment}
        outletId={outlet.id}
      />
    )
  }

  if (currentFulfilment.type === CurrentFulfilmentType.COLLECTION) {
    return null
  }

  if (currentFulfilment.type === CurrentFulfilmentType.TABLE) {
    return (
      <FulfilmentWhereLabelTable
        tableId={currentFulfilment.tableId}
        outletId={outlet.id}
      />
    )
  }

  if (currentFulfilment.type === CurrentFulfilmentType.TABLE_UNSELECTED) {
    return <span data-test-id="select-a-table">{t('select_a_table')}</span>
  }

  return null
}
