import React from 'react'

export const RejectSVG: React.VFC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <circle cx="9" cy="9" r="7.25"></circle>
        <line
          x1="6.25"
          y1="6.25"
          x2="11.75"
          y2="11.75"
          data-color="color-2"
        ></line>
        <line
          x1="11.75"
          y1="6.25"
          x2="6.25"
          y2="11.75"
          data-color="color-2"
        ></line>
      </g>
    </svg>
  )
}
