import React from 'react'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs/CheckboxInput/CheckboxInput'
import { PaymentCard } from '@src/components/PaymentCard/PaymentCard'
import { Card } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'

import { RadioWrapper } from './PaymentMethods.styles'

export const CheckoutPaymentCard: React.FC<{
  onSelect: (card: Card) => void
  card: Card
  refetch: () => void
  isLoading: boolean
  isExpired: boolean
  isSelected: boolean
}> = ({ onSelect, isLoading, card, isExpired, isSelected, refetch }) => {
  return (
    <RadioWrapper key={card.id}>
      <UncontrolledRadioInput
        value={card.id}
        disabled={isLoading || isExpired}
        label={
          <PaymentCard
            card={card}
            refetchList={refetch}
            isExpired={isExpired}
          />
        }
        checked={isSelected}
        style={CheckboxInputStyle.TICK}
        onChange={() => onSelect(card)}
      />
    </RadioWrapper>
  )
}
