import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type BasketOutletMenuItemsQueryVariables = Types.Exact<{
  outletMenuItemIds: Array<Types.Scalars['ID']> | Types.Scalars['ID']
}>

export type BasketOutletMenuItemsQuery = {
  outletMenuItems: Array<{
    __typename: 'OutletMenuItem'
    id: string
    menuItemId: string
    maxPurchaseQuantity: number | null
    name: string
    description: string | null
    price: number
    ageRestricted: boolean | null
    spiceLevel: number
    allergens: Array<Types.Allergen> | null
    options: Array<{
      __typename: 'Option'
      id: string
      optionItems: Array<{
        __typename: 'OptionItem'
        id: string
        name: string
        price: number
        ageRestricted: boolean | null
      }>
    }>
  }>
}

export const BasketOutletMenuItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BasketOutletMenuItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletMenuItemIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'outletMenuItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletMenuItemIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletMenuItemIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUnavailableBySchedule' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'menuItemId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxPurchaseQuantity' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ageRestricted' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'spiceLevel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'allergens' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionItems' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ageRestricted' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BasketOutletMenuItemsQuery,
  BasketOutletMenuItemsQueryVariables
>
