import { useReactiveVar } from '@apollo/client'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { BottomSheetWrapper } from '@src/components/BottomSheetWrapper/BottomSheetWrapper'
import { Helmet } from '@src/components/Helmet'
import { breakpoints } from '@src/constants/breakpoints'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { allOutletDiscountsQuery } from '@src/hooks/sharedQueries/useAllDiscounts/queries/__generated__/getAllDiscounts.graphql-interface'
import { useFindMenuItemModalData } from '@src/hooks/useFindMenuItemModalData'
import { useMenuItemQueryParam } from '@src/hooks/useMenuItemQueryParam'
import { screenResolutionVar } from '@src/models/screenResolution'

import { MenuGroup } from './MenuGroup/MenuGroup'
import { MenuItemModal } from './MenuItemModal/MenuItemModal'
import {
  DealsAndOffersContainer,
  SingleDiscountContainer,
} from './OutletMenuStyles'

import { OutletMenuItemGroupTree } from '../menuItemGroupTreeType'
import { SingleDiscount } from '../OutletDiscounts/SingleDiscount'
import { ScrollToContainer } from '../OutletMenu/MenuGroup/MenuGroup.styles'

export const OutletMenu: React.FC<{
  activeMenuId: string | null
  allDiscounts: allOutletDiscountsQuery | undefined
  setActiveMenuId: (val: string | null) => void
  fullMenuItemGroupTree: OutletMenuItemGroupTree
  availableMenuItemGroupTree: OutletMenuItemGroupTree
  manualNavigation: boolean
}> = ({
  activeMenuId,
  setActiveMenuId,
  allDiscounts,
  fullMenuItemGroupTree,
  availableMenuItemGroupTree,
  manualNavigation,
}) => {
  const { outlet } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const [modalMenuItemId, setMenuItemQueryParam] = useMenuItemQueryParam()
  const { width } = useReactiveVar(screenResolutionVar)

  const { modalData } = useFindMenuItemModalData({
    fullMenuItemGroupTree,
    modalMenuItemId,
  })

  // seperate intersection observer for discounts
  const rootMargin = (): string => {
    if (width > breakpoints.tablet) {
      return `${-window.innerHeight * 0.4}px`
    } else return `${-window.innerHeight * 0.3}px`
  }

  const { ref, inView, entry } = useInView({
    root: null,
    rootMargin: `0px 0px  ${rootMargin()}
     0px`,
    threshold: 1,
    trackVisibility: true,
    delay: 300,
  })

  useEffect(() => {
    if (
      inView &&
      entry?.isIntersecting &&
      activeMenuId !== 'discountMenuItem' &&
      !manualNavigation
    ) {
      setActiveMenuId('discountMenuItem')
    }
  }, [ref, inView])

  return (
    <>
      <Helmet title={`${outlet.displayName} Menu`} />
      {allDiscounts?.allDiscountsByOutletId.length ? (
        <>
          <ScrollToContainer id={'discountMenuItem'} aria-hidden />
          <DealsAndOffersContainer ref={ref}>
            <SingleDiscountContainer>
              {allDiscounts.allDiscountsByOutletId.map(discount => (
                <div key={discount.id}>
                  <SingleDiscount key={discount.id} allDiscounts={discount} />
                </div>
              ))}
            </SingleDiscountContainer>
          </DealsAndOffersContainer>
        </>
      ) : null}
      <>
        {availableMenuItemGroupTree.map(outletMenuGroup => {
          return outletMenuGroup.parentMenu ? (
            <MenuGroup
              key={outletMenuGroup.parentMenu.id}
              outletId={outlet.id}
              outletParentMenu={outletMenuGroup.parentMenu}
              outletSubMenus={outletMenuGroup.subMenus}
              activeMenuId={activeMenuId}
              setActiveMenuId={setActiveMenuId}
              manualNavigation={manualNavigation}
            />
          ) : null
        })}
      </>

      <BottomSheetWrapper
        open={!!modalMenuItemId}
        onClose={() => {
          setMenuItemQueryParam(undefined, 'replaceIn')
        }}
        headerColor="#fff"
        backgroundColor="#fff"
      >
        {modalData && (
          <MenuItemModal
            modalData={modalData}
            closeModal={() => {
              setMenuItemQueryParam(undefined, 'replaceIn')
            }}
          />
        )}
      </BottomSheetWrapper>
    </>
  )
}
