import { useReactiveVar } from '@apollo/client'
import { without } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BackButton } from '@src/components/BackButton/BackButton'
import {
  SingleOutlet,
  SingleOutletDirection,
} from '@src/components/SingleOutlet/types'
import { breakpoints } from '@src/constants/breakpoints'
import { useCategories } from '@src/hooks/useCategories'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { screenResolutionVar } from '@src/models/screenResolution'

import {
  Container,
  HeaderContainer,
  CategoriesHeader,
  MainContainer,
  SingleCategoryButton,
  CategoryName,
  StyledCloseButton,
  NonIdealCategories,
  SubHeader,
  SubheaderAndBackButtonContainer,
} from './CategoryFilteredOutlets.styles'
import { OutletListContainer, StyledOutletCard } from './OutletList.styles'

export const CategoryFilteredOutlets: React.FC<{
  outlets: Array<
    Pick<
      SingleOutlet,
      | 'id'
      | 'displayName'
      | 'restaurant'
      | 'availableFulfilmentInputMethods'
      | 'description'
      | 'deliveryMinimumOrderValue'
      | 'collectionMinimumOrderValue'
      | 'isOnline'
      | 'isOpen'
      | 'outletLogoOverride'
      | 'distanceFromUserKM'
      | 'prepTime'
      | 'statusText'
      | 'daysOfferedInAdvanceMin'
      | 'nextOpenDate'
      | 'availableFulfillmentMethods'
      | 'ASAPDeliveryDuration'
      | 'fulfilmentRange'
      | 'outletAllDiscounts'
      | 'specialOfferFlags'
      | 'outletCuisines'
    >
  >
}> = ({ outlets }) => {
  const { t: commonT } = useTranslation()
  const { t: errorsT } = useTranslation('errors')
  const { width } = useReactiveVar(screenResolutionVar)
  const marketplace = useMarketplace()

  const { displayPermanentCheckout } = useCheckoutRouter()

  const { setCategoryNames, selectedCategories } = useCategories()

  const setNewSelectedCategories = (category: { id: string; name: string }) => {
    const categories = without(selectedCategories, category).map(
      ({ name }) => name
    )
    setCategoryNames({ categories: categories })
  }

  return (
    <Container>
      <HeaderContainer>
        <SubheaderAndBackButtonContainer>
          <BackButton
            onClick={() => {
              setCategoryNames({ categories: [] })
            }}
          />

          <SubHeader>
            {commonT('category_search_results', {
              urlPath:
                marketplace.urlPath.charAt(0).toUpperCase() +
                marketplace.urlPath.slice(1),
            })}
          </SubHeader>
        </SubheaderAndBackButtonContainer>

        <CategoriesHeader>
          {selectedCategories.map(category => (
            <SingleCategoryButton
              key={category.id}
              onClick={() => setNewSelectedCategories(category)}
            >
              <CategoryName>
                {category.id === 'deals' || category.id === 'featured'
                  ? commonT(category.id)
                  : category.name}
              </CategoryName>
              <StyledCloseButton id="close" />
            </SingleCategoryButton>
          ))}
        </CategoriesHeader>
      </HeaderContainer>

      <MainContainer permanentCheckoutOpen={displayPermanentCheckout}>
        <OutletListContainer isSearchResults={true}>
          {outlets.length ? (
            outlets.map(outlet => (
              <StyledOutletCard
                key={outlet.id}
                outlet={outlet}
                direction={
                  width < breakpoints.tablet
                    ? SingleOutletDirection.ROW
                    : SingleOutletDirection.COLUMN
                }
              />
            ))
          ) : (
            <NonIdealCategories>
              {errorsT('no_outlets_categories')}
            </NonIdealCategories>
          )}
        </OutletListContainer>
      </MainContainer>
    </Container>
  )
}
