import {
  isBefore,
  addDays,
  startOfDay,
  isEqual,
  isAfter,
  isToday,
} from 'date-fns'
import { orderBy } from 'lodash'

import { FulfilmentFilterData } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { MarketplaceCnameLookupFragment_categories } from '@src/hooks/useMarketplace/queries/__generated__/MarketplaceCnameLookupFragment'
import { outletList_outlets } from '@src/pages/OutletList/queries/__generated__/outletList'

import { DateifyOutlet } from './fulfilmentTimes/parsers'

import { Outlet, NarrowFulfilmentMethodInputType } from '../graphql-types'

const preorderAvailable = (outlet: Outlet) =>
  outlet.allowPreorders &&
  (!outlet.closedUntil ||
    (outlet.closedUntil &&
      isBefore(
        new Date(outlet.closedUntil),
        addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMax)
      ))) &&
  // check now + daysofferedinadvance >= next opening time
  (outlet.isOpen ||
    (!outlet.isOpen &&
      (isEqual(
        addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMin),
        startOfDay(new Date(outlet.nextOpenDate))
      ) ||
        isAfter(
          addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMin),
          startOfDay(new Date(outlet.nextOpenDate))
        ))) ||
    isEqual(
      addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMax),
      startOfDay(new Date(outlet.nextOpenDate))
    ) ||
    isAfter(
      addDays(startOfDay(new Date()), outlet.daysOfferedInAdvanceMax),
      startOfDay(new Date(outlet.nextOpenDate))
    ))

const outletIsOrderable = (outlet: Outlet) => {
  if (
    outlet.availableFulfilmentInputMethods.includes(
      NarrowFulfilmentMethodInputType.TABLE
    ) &&
    outlet.availableFulfilmentInputMethods.length === 1
  ) {
    return outlet.isOnline && outlet.isOpen
  } else return outlet.isOrderable
}

export const sortOutlets = (
  outlets: DateifyOutlet<outletList_outlets>[],
  fulfilmentFilterData: FulfilmentFilterData,
  selectedCategories?: MarketplaceCnameLookupFragment_categories[]
): DateifyOutlet<outletList_outlets>[] => {
  const sort: {
    iteratees: (string | ((outlet: Outlet) => any))[]
    orders: ('desc' | 'asc')[]
  } = {
    iteratees: [
      // Orderable, open, promoted regardless of pre-order
      (outlet: Outlet) =>
        outletIsOrderable(outlet) && outlet.isOpen && !!outlet.promoteOutlet,
      // Orderable, open, not promoted regardless of pre-order
      (outlet: Outlet) =>
        outletIsOrderable(outlet) && outlet.isOpen && !outlet.promoteOutlet,
      // Orderable, closed, promoted and pre-order is available
      (outlet: Outlet) =>
        outletIsOrderable(outlet) &&
        preorderAvailable(outlet) &&
        !outlet.isOpen &&
        !!outlet.promoteOutlet,
      // Orderable, closed, not promoted and preorder is available
      (outlet: Outlet) =>
        outletIsOrderable(outlet) &&
        preorderAvailable(outlet) &&
        !outlet.isOpen &&
        !outlet.promoteOutlet,
      // Not orderable, opens later today and is promoted
      (outlet: Outlet) =>
        !outletIsOrderable(outlet) &&
        isToday(new Date(outlet.nextOpenDate)) &&
        !!outlet.promoteOutlet &&
        outlet.isOnline,
      // Not orderable, opens later today and not promoted
      (outlet: Outlet) =>
        !outletIsOrderable(outlet) &&
        isToday(new Date(outlet.nextOpenDate)) &&
        !outlet.promoteOutlet &&
        outlet.isOnline,
      // Not orderable, is online, not open today, promoted
      (outlet: Outlet) =>
        !outletIsOrderable(outlet) &&
        outlet.isOnline &&
        !isToday(new Date(outlet.nextOpenDate)) &&
        !!outlet.promoteOutlet,
      // Not orderable, is online, not open today, not promoted
      (outlet: Outlet) =>
        !outletIsOrderable(outlet) &&
        !isToday(new Date(outlet.nextOpenDate)) &&
        outlet.isOnline &&
        !outlet.promoteOutlet,
    ],
    orders: ['desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc', 'desc'],
  }

  if (
    fulfilmentFilterData.priorityFulfilmentMethod ===
    NarrowFulfilmentMethodInputType.TABLE
  ) {
    sort.iteratees.push('distanceFromUserKM')
    sort.orders.push('asc')
  }

  const sortedOutlets = orderBy(
    fulfilmentFilterData.priorityFulfilmentMethod ===
      NarrowFulfilmentMethodInputType.DELIVERY
      ? (outlets as any[]) // actual workaround solution from similar lodash git issue 25758
      : outlets,
    sort.iteratees || [],
    sort.orders || []
  )

  const filteredOutlets =
    selectedCategories && selectedCategories.length
      ? sortedOutlets.sort((a, b) => {
          // sort the outlets based on whichever ones have the most cuisine matches to the selected categories
          const cuisinesA = a.outletCuisines
            .map(({ name }: { name: string }) => name)
            .filter((cuisine: string) =>
              selectedCategories.some(({ name }) => name === cuisine)
            )
          const cuisinesB = b.outletCuisines
            .map(({ name }: { name: string }) => name)
            .filter((cuisine: string) =>
              selectedCategories.some(({ name }) => name === cuisine)
            )

          return cuisinesB.length - cuisinesA.length
        })
      : sortedOutlets

  return filteredOutlets
}
