import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const NewCardContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  overflow: hidden;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  z-index: 2;
`

export const CardsContainer = styled.div`
  display: flex;
`

export const CardInput = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #f0f0f0;
  font-size: 12px;
  font-weight: 500;
  margin: 8px 0px;
  background-color: white;

  &:disabled {
    opacity: 0.5;
    cursor: wait;
  }
`

export const CardInputSmall = styled.div`
  width: 120px;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #f0f0f0;
  font-size: 12px;
  font-weight: 500;
  margin: 4px 0px;
`

export const CVCContainer = styled.div`
  position: relative;
  bottom: 50px;
  left: 94px;
`

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const SmallFont = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0px 0px 8px;
  width: 156px;
`

export const LoaderOverlay = styled.div`
  position: sticky;
  background-color: white;
  height: 340px;
  width: 328px;
  z-index: 1;

  @media (max-width: ${breakpoints.largeTablet}px) {
    background-color: #f6f6f9;
  }
`

/* Cannot unmount elements (i.e. conditionally display 'loading' instead of card input group, so set height and opacity when handling submit) */

export const CardOuter = styled.div<{ disabled: boolean }>`
  height: ${props => (props.disabled ? '0px' : '100%')};
  opacity: ${props => (props.disabled ? 0 : 1)};
`

export const CARD_ELEMENT_STYLE = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: 500,
      '::placeholder': {
        color: '#ababab',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa55a',
    },
  },
  placeholder: 'Card Number (16 Digit) *',
}

export const EXPIRY_STYLE = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: 500,
      '::placeholder': {
        color: '#ababab',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa55a',
    },
  },
  placeholder: 'Expiry *',
}

export const CVC_STYLE = {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: 500,
      position: 'relative',
      '::placeholder': {
        color: '#ababab',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa55a',
    },
  },
  placeholder: 'CVC *',
}
