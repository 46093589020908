import React from 'react'
import { useTranslation } from 'react-i18next'

import { LinkToOutlet } from '@src/components/LinkToOutlet'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { FilterSVG } from '@src/components/SVGS/FilterSVG'
import { TextClamp } from '@src/components/Text/Clamp'
import { CategoryEmojiOrImage } from '@src/graphql-types'
import { useCategories } from '@src/hooks/useCategories'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine,
  getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet,
} from './queries/__generated__/getSearchOutletsAndCuisinesMobile'
import {
  Container,
  Cuisine,
  EmojiContainer,
  NonIdealContainer,
  OutletName,
  ResultsContainer,
  SingleResultContainer,
  Underlay,
  ImageContainer,
  RestaurantLogo,
} from './SearchResultsStyles'

export const SearchResults: React.FC<{
  categories: getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Cuisine[]
  outlets: getSearchOutletsAndCuisinesMobile_searchOutletsAndCategoriesByName_Outlet[]
  error: boolean
  closeSearchResults: () => void
  setSearchTerm: (searchTerm: string) => void
  loading: boolean
  containerWidth: string
}> = ({
  categories,
  outlets,
  error,
  closeSearchResults,
  setSearchTerm,
  loading,
  containerWidth,
}) => {
  const { t } = useTranslation('serviceOverlay')
  const { categoryEmojiOrImage } = useMarketplace()

  const { setCategoryNames, selectedCategories } = useCategories()
  // categories are prioritized over outlets, but no more than 20 search results should be returned
  const maxOutlets = 20 - categories.length

  return (
    <>
      <Underlay onClick={closeSearchResults} />
      <Container width={containerWidth}>
        {error || loading ? (
          <NonIdealContainer>
            <LoadingSpinner showMargin={false} />
          </NonIdealContainer>
        ) : (
          <>
            {!!outlets.length || !!categories.length ? (
              <ResultsContainer>
                {categories.map((category, i) => (
                  <SingleResultContainer
                    key={category.id}
                    onClick={() => {
                      // add to existing category and category query params
                      if (
                        selectedCategories.some(({ id }) => id === category.id)
                      ) {
                        setCategoryNames({
                          categories: selectedCategories
                            .filter(({ id }) => id !== category.id)
                            .map(({ name }) => name),
                        })
                      } else
                        setCategoryNames({
                          categories: [
                            ...selectedCategories.map(({ name }) => name),
                            category.name,
                          ],
                        })
                      closeSearchResults()
                      setSearchTerm('')
                    }}
                    firstChild={i === 0}
                    hasImage={
                      categoryEmojiOrImage === CategoryEmojiOrImage.IMAGE &&
                      category.imageSrc !== null
                    }
                  >
                    {categoryEmojiOrImage === CategoryEmojiOrImage.IMAGE &&
                      (category.imageSrc ? (
                        <ImageContainer
                          role="img"
                          aria-label={category.name}
                          imageUrl={imageJitURL(category.imageSrc, {
                            resize: {
                              width: 512,
                              height: 512,
                              fit: 'outside',
                            },
                          })}
                        />
                      ) : (
                        <FilterSVG id="filter-svg-search-results" />
                      ))}
                    {categoryEmojiOrImage === CategoryEmojiOrImage.EMOJI &&
                      (category.emoji ? (
                        <EmojiContainer>{category.emoji}</EmojiContainer>
                      ) : (
                        <FilterSVG id="filter-svg-search-results" />
                      ))}

                    <OutletName>{category.name}</OutletName>
                  </SingleResultContainer>
                ))}
                {outlets.map((outlet, i) => (
                  <>
                    {i < maxOutlets && (
                      <LinkToOutlet
                        key={outlet.id}
                        outletId={outlet.id}
                        name={outlet.displayName}
                      >
                        <SingleResultContainer
                          firstChild={!categories.length && i === 0}
                        >
                          <div>
                            <RestaurantLogo
                              role="img"
                              aria-label={`${outlet.displayName} logo`}
                              imageUrl={imageJitURL(
                                outlet.outletLogoOverride ||
                                  outlet.restaurant.image,
                                {
                                  resize: {
                                    width: 88,
                                    height: 88,
                                    fit: 'cover',
                                  },
                                }
                              )}
                            />
                          </div>
                          <div>
                            <OutletName>
                              <TextClamp>{outlet.displayName}</TextClamp>
                            </OutletName>
                            <Cuisine>
                              {outlet.outletCuisines
                                .slice(0, 3)
                                .map(({ name }) => name)
                                .join(' • ')}
                            </Cuisine>
                          </div>
                        </SingleResultContainer>
                      </LinkToOutlet>
                    )}
                  </>
                ))}

                {/* TODO: RED-5924 All results for search */}
                {/* <SingleResultContainer onClick={() => {}}>
                  <SearchIconSVG id="search" />
                  <OutletName>
                    {t('all_results_for', { searchTerm: nameContains })}
                  </OutletName>
                </SingleResultContainer> */}
              </ResultsContainer>
            ) : (
              <NonIdealContainer>{t('non_ideal_state')}</NonIdealContainer>
            )}
          </>
        )}
      </Container>
    </>
  )
}
