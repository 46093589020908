import styled, { keyframes, css } from 'styled-components'

import { PlusSVG } from '@src/components/SVGS/PlusSVG'
import { SpinnerSVG } from '@src/components/SVGS/SpinnerSVG'
import { TickInCircleSVG } from '@src/components/SVGS/TickInCircleSVG'
import { breakpoints } from '@src/constants/breakpoints'

interface CheckState {
  isChecked: boolean
  disabled: boolean
}

function getBackgroundColor(props: CheckState) {
  if (props.disabled) {
    return '#ebecf0'
  }
  if (props.isChecked) {
    return 'black'
  }
  return 'white'
}

export const HiddenInput = styled.input`
  display: none;
`

export const TickIcon = styled(TickInCircleSVG)`
  fill: #000000;
  width: 20px;
  height: 20px;
  position: absolute;

  @media (max-width: ${breakpoints.tabletMisc}px) {
    width: 20px;
    height: 20px;
  }
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg); 
  }
`

export const SpinnerSVGStyled = styled(SpinnerSVG)`
  margin-right: 8px;
  flex-shrink: 0;
  animation: ${spin} 2s linear infinite;
`

export const StyledCrossSVG = styled(PlusSVG)`
  width: 14px;
  height: 14px;
  padding-top: 3px;
  padding-left: 3px;
  color: black;
`

export const RadioIcon = styled.svg`
  fill: #000000;
  width: 20px;
  height: 20px;
`

export const StyledRadio = styled.div<{
  isChecked: boolean
  disabled: boolean
}>`
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex: none;
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 50%;
  background: ${props => getBackgroundColor(props)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  border: 2px solid ${props => (props.isChecked ? 'black' : '#ebecf0')};
  margin-right: 8px;
  @media (max-width: ${breakpoints.tabletMisc}px) {
    width: 24px;
    height: 24px;
  }
  svg {
    color: ${props => (props.disabled ? 'white' : 'black')};
  }

  &:hover {
    ${RadioIcon} {
      visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
      left: 5px;
      top: 5px;

      @media (max-width: ${breakpoints.tabletMisc}px) {
        left: 3px;
        top: 3px;
      }
    }
  }

  ${RadioIcon} {
    visibility: ${props => (props.isChecked ? 'visible' : 'hidden')};

    &:hover {
      fill: ${props => (props.isChecked ? '#000000' : '#ebecf0')};
      visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
      fill: '#ebecf0';
      left: 5px;
      top: 5px;

      @media (max-width: ${breakpoints.tabletMisc}px) {
        left: 3px;
        top: 3px;
      }
    }
  }

  ${TickIcon} {
    visibility: ${props => (props.isChecked ? 'visible' : 'hidden')};
  }
`

export const CheckboxIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`

export const StyledCheckbox = styled.div<{
  isChecked: boolean
  disabled?: boolean
}>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${props => (props.isChecked ? '#000000' : 'white')};
  border-radius: 4px;
  border: 2px solid ${props => (props.isChecked ? 'black' : '#ebecf0')};
  opacity: ${({ disabled }) => (disabled ? 0.1 : 1)};
  margin-right: 8px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  ${CheckboxIcon} {
    visibility: ${props => (props.isChecked ? 'visible' : 'hidden')};

    &:hover {
      visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
      stroke-width: 3px;
      svg {
        stroke: black;
      }
      path {
        stroke: black;
        fill: none;
      }
    }
  }
`

export const TickStyledCheckbox = styled.div<{
  isChecked: boolean
  disabled?: boolean
}>`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${props => (props.isChecked ? '#191919' : 'white')};
  border-radius: 4px;
  border: 2px solid #191919;
  opacity: ${({ disabled }) => (disabled ? 0.1 : 1)};
  transition: all 0.2s;
  margin-right: 8px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media (max-width: ${breakpoints.tabletMisc}px) {
    width: 20px;
    height: 20px;
  }

  ${TickIcon} {
    visibility: ${props => (props.isChecked ? 'visible' : 'hidden')};
  }

  ${StyledCrossSVG} {
    visibility: ${props => (props.isChecked ? 'hidden' : 'visible')};
  }
`

export const Label = styled.label<{
  labelOnLeft?: boolean
  disabled?: boolean
  bold?: boolean
}>`
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  transition: all 0.2s;

  button {
    visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
  }

  ${props =>
    props.labelOnLeft &&
    css`
      flex-direction: row-reverse;
      justify-content: space-between;

      ${StyledCheckbox}, ${StyledRadio} {
        margin-right: 0;
        margin-left: 8px;
      }
    `}

  ${props =>
    props.disabled
      ? css`
          color: #e6e6e6;
          cursor: not-allowed !important;
          > div:first-of-type {
            border: 2px solid #ebecf0;
          }
        `
      : css`
          &:hover {
            cursor: pointer;
            > div:first-of-type {
              border: 2px solid #000000;
            }
          }
        `}
`

export const DescriptionSpan = styled.span`
  padding-top: 8px;
  display: block;
  font-size: 14px;
  font-weight: 400;
`
