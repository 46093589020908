import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from '@src/components/Carousel'
import { TextClamp } from '@src/components/Text/Clamp'
import { BusinessSegment } from '@src/graphql-types'
import { convertToSlug } from '@src/utils/convertToSlug'
import { parseOutletDates } from '@src/utils/fulfilmentTimes/parsers'

import { getBusinessSegmentOutlets_businessSegmentOutlets } from './queries/__generated__/getBusinessSegmentOutlets'

import { IconButton } from '../Button/IconButton'
import {
  FeaturedOutletsContainer,
  HeroContainer,
  OutletContainer,
  SliderArrowsContainer,
  HeaderLink,
  Description,
} from '../FeaturedOutlets/FeaturedOutlets.styles'
import { OutletSlide } from '../OutletSlide'

type Props = {
  businessSegment: Pick<
    BusinessSegment,
    | 'id'
    | 'name'
    | 'showName'
    | 'outletLimit'
    | 'description'
    | 'showDiscountLabels'
    | 'showFeaturedFlags'
  > & {
    outlets: Array<
      Pick<
        getBusinessSegmentOutlets_businessSegmentOutlets,
        | 'id'
        | 'displayName'
        | 'prepTime'
        | 'restaurant'
        | 'availableFulfilmentInputMethods'
        | 'deliveryMinimumOrderValue'
        | 'collectionMinimumOrderValue'
        | 'distanceFromUserKM'
        | 'coverImage'
        | 'statusText'
        | 'isOpen'
        | 'isOnline'
        | 'nextOpenDate'
        | 'openingTimesArray'
        | 'asapAllowed'
        | 'allowPreorders'
        | 'collectionPreorderTimes'
        | 'ASAPDeliveryDuration'
        | 'fulfilmentRange'
        | 'outletLogoOverride'
        | 'outletAllDiscounts'
        | 'specialOfferFlags'
        | 'outletCuisines'
      >
    >
  }
}

export const BusinessSegmentOutlets: React.FC<Props> = ({
  businessSegment,
}) => {
  const segmentOutlets = useMemo(
    () => businessSegment.outlets.map(parseOutletDates),
    [businessSegment.outlets]
  )

  const { t } = useTranslation('navigation')

  const circleButtonLeftClassName = `${businessSegment.id}-segment-left`
  const circleButtonRightClassName = `${businessSegment.id}-segment-right`

  return (
    <>
      {segmentOutlets.length > 0 && (
        <OutletContainer>
          <HeroContainer>
            {businessSegment.showName && (
              <HeaderLink
                to={`/segments/${businessSegment.id}/${convertToSlug(
                  businessSegment.name
                )}`}
              >
                <h2>{businessSegment.name}</h2>
              </HeaderLink>
            )}
            <SliderArrowsContainer>
              <IconButton
                className={circleButtonLeftClassName}
                size={32}
                icon={'caretLeft'}
              />
              <IconButton
                className={circleButtonRightClassName}
                size={32}
                icon={'caretRight'}
                alt={t('right')}
              />
            </SliderArrowsContainer>
          </HeroContainer>
          {!!businessSegment.description?.length && (
            <Description>
              <TextClamp clamp={5}>{businessSegment.description}</TextClamp>
            </Description>
          )}
          <FeaturedOutletsContainer>
            <Carousel
              buttonLeftClassName={circleButtonLeftClassName}
              buttonRightClassName={circleButtonRightClassName}
              slides={segmentOutlets.map(outlet => (
                <OutletSlide
                  key={`segmentOutlet-${businessSegment.id}-${outlet.id}`}
                  outlet={outlet}
                  showDiscountLabels={businessSegment.showDiscountLabels}
                  showFeaturedFlags={businessSegment.showFeaturedFlags}
                />
              ))}
            />
          </FeaturedOutletsContainer>
        </OutletContainer>
      )}
    </>
  )
}
