import styled, { css } from 'styled-components'

import { OutletCard } from '@src/components/SingleOutlet/OutletCard'
import { breakpoints } from '@src/constants/breakpoints'
import { between } from '@src/utils/breakpoints'

export const OutletWrapper = styled.div`
  padding-top: 124px;

  @media (min-width: ${breakpoints.largeTablet}px) {
    padding-top: 144px;
  }
`

export const OutletListContainer = styled.div<{ isSearchResults?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0px;
  padding: 0 16px;
  margin-bottom: 0px;
  width: ${({ isSearchResults }) => isSearchResults && '100%'};
  background-color: ${({ theme }) => theme.colors.brandForeground};

  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  margin-top: 8px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 16px 16px 0px 16px;
    background-color: transparent;
    border: none;
    margin-bottom: 24px;
  }
`

export const StyledOutletCard = styled(OutletCard)`
  width: 100%;

  @media (max-width: ${breakpoints.wideDesktop}px) {
    ${between({
      start: 'tablet',
      end: 'desktop',
      styles: css`
        width: calc(50% - (32px / 2));
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      `,
    })}
  }

  @media (min-width: ${breakpoints.desktop}px) {
    width: calc((100% / 3) - 32px);
  }

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    width: calc((100% / 4) - 32px);
  }
`

export const HeaderContainer = styled.h2`
  font-size: 17px;
  font-weight: 700;
  margin: 16px 24px 0px 16px;
  display: flex;
  align-items: flex-end;
  padding-top: 16px;

  @media (min-width: ${breakpoints.tablet}px) {
    margin: 0px 0px 6px 32px;
    padding-top: 32px;
    font-size: 24px;
  }
`
export const OutletsOuterContainer = styled.div`
  width: 100%;
  padding-top: 124px;

  @media (min-width: ${breakpoints.largeTablet}px) {
    padding-top: 144px;
  }
`

export const OutletSkeletonWrapper = styled.div`
  margin-top: 140px;
`

export const NoneFound = styled.div`
  margin: 0 auto;
  margin-top: 24px;
  text-align: center;
`
