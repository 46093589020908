import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  DoneButton,
  MobileNavigationPopoverFooter,
  MobileNavigationPopoverFooterContent,
  MobileNavigationResetLink,
} from '@src/components/ServicePopover/ServicePopover.styles'

export const PopoverFooter: React.FC<{
  setIsSecondaryPopoverOpen?: (val: boolean) => void
  setPopoverOpen: (val: boolean) => void
}> = ({ setIsSecondaryPopoverOpen, setPopoverOpen }) => {
  const { t } = useTranslation('serviceNavigation')
  return (
    <MobileNavigationPopoverFooter>
      <MobileNavigationPopoverFooterContent>
        <MobileNavigationResetLink
          onClick={() => {
            setPopoverOpen(false)
            setIsSecondaryPopoverOpen?.(false)
          }}
        >
          {t('cancel')}
        </MobileNavigationResetLink>

        <DoneButton
          content={t('done')}
          width="180px"
          onClick={() => {
            setPopoverOpen(false)
            setIsSecondaryPopoverOpen?.(false)
          }}
          type={'button'}
          dataTestId="done-button"
        />
      </MobileNavigationPopoverFooterContent>
    </MobileNavigationPopoverFooter>
  )
}
