import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { AlertModal } from '@src/components/Modal/AlertModal'
import { SharedAddress } from '@src/hooks/sharedQueries/useCustomerDetailsAndAddressesQuery/useCustomerDetailsAndAddressesQuery'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'

export const ConfirmAddressModal: React.FC<{
  isOpen: boolean
  address?: SharedAddress
  onClose: () => void
  addressConfirmed: () => void
}> = ({ isOpen, onClose, address, addressConfirmed }) => {
  const { t } = useTranslation('checkout')

  const checkoutRouter = useCheckoutRouter()

  if (!address) {
    return null
  }

  const addressParts = [
    address.firstLine,
    address.secondLine,
    address.city,
    address.postcode,
  ].filter(part => part !== undefined)

  return (
    <AlertModal
      isOpen={isOpen}
      title={t('confirm_address_title')}
      action={{
        text: t(`confirm_address`),
        onClick: () => {
          addressConfirmed()
        },
      }}
      subTitle={
        <Fragment>
          {addressParts.map((part, index) => (
            <Fragment key={index}>
              {part}
              {index < addressParts.length - 1 && (
                <Fragment>
                  {','} <br />
                </Fragment>
              )}
            </Fragment>
          ))}
        </Fragment>
      }
      cancel={{
        text: t(`edit`),
        onClick: () => {
          checkoutRouter.override(CheckoutRoute.FULFILMENT)
          onClose()
        },
      }}
      dataTestId="confirm-delivery-address-button"
    />
  )
}
