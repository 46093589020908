import React from 'react'
import { useTranslation } from 'react-i18next'

export const CouponSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      height="18"
      width="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('coupon_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <path d="M3.25,2.25h4.922c.53,0,1.039,.211,1.414,.586l5.75,5.75c.781,.781,.781,2.047,0,2.828l-3.922,3.922c-.781,.781-2.047,.781-2.828,0L2.836,9.586c-.375-.375-.586-.884-.586-1.414V3.25c0-.552,.448-1,1-1Z"></path>
        <circle
          cx="6.25"
          cy="6.25"
          r="1.25"
          fill="currentColor"
          data-color="color-2"
          data-stroke="none"
          stroke="none"
        ></circle>
      </g>
    </svg>
  )
}
