import { FieldMetaProps } from 'formik'
import React from 'react'

import { InputContainer, InputError } from './InputWrapper.styles'

export const InputWrapper: React.FC<{
  className?: string
  meta: Pick<FieldMetaProps<any>, 'touched' | 'error'>
  children: React.ReactNode
  disabled?: boolean
  last?: boolean
  showMetaError?: boolean
  fullWidth?: boolean
  dataTestId?: string
}> = ({
  className,
  meta,
  children,
  disabled,
  last = false,
  showMetaError = true,
  fullWidth = false,
  dataTestId,
}) => {
  return (
    <InputContainer
      className={className}
      disabled={disabled}
      last={last}
      fullWidth={fullWidth}
      data-test-id={dataTestId}
    >
      {children}

      {showMetaError && meta.touched && meta.error && (
        <InputError>{meta.error}</InputError>
      )}
    </InputContainer>
  )
}
