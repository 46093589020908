import React from 'react'
import { useTranslation } from 'react-i18next'

export const TrashSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  dataTestId?: string
}> = ({ id, className, title, desc, dataTestId }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id={dataTestId}
    >
      <title id={`${id}Title`}>{title || t('trash_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <g fill="currentColor">
        <path
          d="M20,9V21a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V9"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></path>
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M1 5L23 5"
        ></path>
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M8 5L8 1 16 1 16 5"
        ></path>
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          data-color="color-2"
          d="M15 11.985L9 17.985"
        ></path>
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          data-color="color-2"
          d="M15 17.985L9 11.985"
        ></path>
      </g>
    </svg>
  )
}
