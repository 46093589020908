import React from 'react'

export const StarSVG: React.VFC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <g fill="currentColor">
        <path
          d="M9 1.75L11.24 6.289L16.25 7.017L12.625 10.551L13.481 15.54L9 13.185L4.519 15.54L5.375 10.551L1.75 7.017L6.76 6.289L9 1.75Z"
          fill="currentColor"
          fillOpacity="0.3"
          data-color="color-2"
          data-stroke="none"
          stroke="none"
        ></path>{' '}
        <path
          d="M9 1.75L11.24 6.289L16.25 7.017L12.625 10.551L13.481 15.54L9 13.185L4.519 15.54L5.375 10.551L1.75 7.017L6.76 6.289L9 1.75Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        ></path>
      </g>
    </svg>
  )
}
