import styled from 'styled-components'
import { Swiper } from 'swiper/react'

import { breakpoints } from '@src/constants/breakpoints'

export const YearsContainer = styled.div`
  width: 100%;
`

export const YearsStyledSwiper = styled(Swiper)`
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex: auto;
  .swiper-slide {
    width: 80px;
    margin-right: 8px;
    cursor: pointer;
  }
  .recent-slide {
    width: 104px;
    margin-right: 8px;
    cursor: pointer;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    z-index: 1000 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .swiper-button-next {
    left: auto;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 40%
    );
    justify-content: right;
  }

  .swiper-button-prev {
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 40%
    );
    justify-content: left;
  }

  .swiper-button-disabled {
    display: none;
  }
`

export const Year = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 80px;
  border: 2px solid #ebecf0;
  font-size: 16px;
  line-height: 20px;
  border-radius: 40px;
  font-weight: 700;
  border-color: ${props =>
    props.selected ? props.theme.colors.brandLight : '#ebecf0'};
  color: ${props =>
    props.selected
      ? props.theme.colors.brandDark
      : props.theme.colors.darkText};
  background: ${props =>
    props.selected ? props.theme.colors.brandLight : '#fff'};
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brandLight};
    transition: color 0.3s ease, border-color 0.3s ease;
  }
`

export const RecentButton = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: 2px solid #ebecf0;
  font-size: 16px;
  border-radius: 40px;
  font-weight: 700;
  border-color: ${props =>
    props.selected ? props.theme.colors.brandLight : '#ebecf0'};
  color: ${props =>
    props.selected
      ? props.theme.colors.brandDark
      : props.theme.colors.darkText};
  background: ${props =>
    props.selected ? props.theme.colors.brandLight : '#fff'};
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brandLight};
    transition: color 0.3s ease, border-color 0.3s ease;
  }
`

export const YearNumber = styled.div<{ selected: boolean }>`
  width: 40px;
  height: 20px;
  text-align: center;
  @media (min-width: ${breakpoints.desktop}px) {
    color: ${props => (props.selected ? '#ebecf0' : '#000')};
  }
`
