import React from 'react'

export const SpicyChiliSVG: React.FC<{
  width: number
  height: number
  className?: string
}> = ({ width, height, className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 96.000000 96.000000"
      preserveAspectRatio="xMidYMid meet"
      className={className}
    >
      <g
        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M742 948 c-5 -7 -26 -45 -46 -85 -35 -71 -36 -72 -69 -66 -51 8 -143
   -15 -177 -44 -41 -34 -48 -49 -81 -177 -46 -174 -85 -243 -206 -364 -112 -112
   -122 -134 -79 -177 13 -13 41 -27 62 -31 89 -17 283 50 434 151 141 93 263
   254 305 399 30 104 0 168 -94 202 -25 10 -50 21 -54 24 -5 4 8 40 28 80 29 57
   34 75 24 86 -14 17 -33 18 -47 2z m-165 -236 c-49 -51 -24 -92 32 -53 l30 22
   -2 -43 c-2 -38 1 -43 21 -46 18 -3 25 3 33 28 l11 31 19 -30 c20 -33 40 -39
   57 -18 9 10 5 25 -14 60 -13 26 -22 47 -19 47 21 0 75 -32 85 -50 24 -44 -31
   -192 -111 -298 -107 -141 -354 -285 -512 -298 -103 -8 -104 3 -7 98 133 130
   189 228 230 404 17 71 43 144 53 144 1 0 20 7 42 15 22 8 49 14 59 15 18 0 17
   -3 -7 -28z"
        />
      </g>
    </svg>
  )
}
