import React, { useState } from 'react'
import { useTranslation, TFunction } from 'react-i18next'

import { AddressForm } from '@src/components/AddressForm'
import { SuccessScreen } from '@src/components/BurgerMenu/SuccessScreen'
import { ButtonType } from '@src/components/Button'
import {
  BurgerSection,
  DrawerHeaderActions,
} from '@src/components/Drawer/DrawerHeader'
import { Helmet } from '@src/components/Helmet'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { useCreateAddressMutation } from '@src/hooks/sharedQueries/useCreateAddressMutation/useCreateAddressMutation'
import { MainRouteName } from '@src/hooks/useAccountRouter'

const Content: React.FC<{
  t: TFunction<'addressBookAddAddress'[], undefined>
  mutation: ReturnType<typeof useCreateAddressMutation>
}> = ({ t, mutation: [createAddress, { data, loading }] }) => {
  if (data) {
    return (
      <SuccessScreen
        successText={t('success')}
        returnToRoute={{ mainRouteName: MainRouteName.ADDRESSES }}
      />
    )
  }

  return (
    <AddressForm
      onSubmit={address => {
        void createAddress({
          variables: {
            input: address,
          },
        })
      }}
      submitButtonText={t('add_address_action')}
      loading={loading}
      dataTestId={'add-address-submit-button'}
    />
  )
}

export const AddAddress: React.FC = () => {
  const { t } = useTranslation(['addressBookAddAddress'])
  const [addressCreationError, setAddressCreationError] = useState<
    string | null
  >(null)
  const mutation = useCreateAddressMutation({
    onError: error => {
      setAddressCreationError(error.message)
    },
  })

  return (
    <>
      <Helmet title={t('title')} />
      <BurgerSection
        action={DrawerHeaderActions.BACK}
        header={{ title: t('title') }}
      >
        <Content t={t} mutation={mutation} />
      </BurgerSection>
      <AlertModal
        isOpen={!!addressCreationError}
        title={t('add_address_error')}
        subTitle={
          addressCreationError ?? t('There was an error creating your address')
        }
        action={{
          text: t('ok'),
          intent: ButtonType.DANGER,
          onClick: () => setAddressCreationError(null),
        }}
      />
    </>
  )
}
