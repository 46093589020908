import React from 'react'

export const PrepSVG: React.VFC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <polyline
          points="5.75 9.25 8 11.75 12.25 6.25"
          data-color="color-2"
        ></polyline>
        <path d="M4.987,2.961c.778-.518,1.662-.89,2.612-1.075"></path>
        <path d="M1.879,7.631c.185-.968,.562-1.867,1.091-2.657"></path>
        <path d="M4.987,15.039c.778,.518,1.662,.89,2.612,1.075"></path>
        <path d="M1.879,10.369c.185,.968,.562,1.867,1.091,2.657"></path>
        <path d="M10.435,1.892c3.317,.666,5.815,3.595,5.815,7.108s-2.499,6.443-5.817,7.108"></path>
      </g>
    </svg>
  )
}
