export const svgs = {
  heart_alt: 'Favourite icon',
  basket_alt: 'Basket icon',
  filter_alt: 'Filter icon',
  user_alt: 'User icon',
  trash_alt: 'Trash icon',
  circle_down_arrow_alt: 'Down arrow',
  caret_up_alt: 'Caret up',
  caret_down_alt: 'Caret down',
  search: 'Search',
  cancel: 'Cancel',
  clock_alt: 'Clock',
  arrow_back_alt: 'Back Icon',
  show_password_alt: 'Show Password',
  navigate_svg: 'Navigate',
  coupon_alt: 'Discount icon',
  back_alt: 'Go To Previous Page',
  double_arrow: 'Double arrow',
  location_alt: 'Current Location',
  menu_alt: 'Menu',
  external_link: 'External Link',
} as const
