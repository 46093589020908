import React from 'react'
import { useTranslation } from 'react-i18next'

export const CartSmallSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="12"
      width="12"
      viewBox="0 0 12 12"
    >
      <title id={`${id}Title`}>{title || t('featured')}</title>
      {desc && <desc id={id}>{desc}</desc>}
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <line x1="3" y1="4.25" x2="4.25" y2=".75" data-color="color-2"></line>
        <line x1="9" y1="4.25" x2="7.75" y2=".75" data-color="color-2"></line>
        <line x1="11.25" y1="4.25" x2=".75" y2="4.25"></line>
        <path d="m10.5,4.25l-.739,4.804c-.15.976-.99,1.696-1.977,1.696h-3.568c-.987,0-1.827-.72-1.977-1.696l-.739-4.804"></path>
      </g>
    </svg>
  )
}
