import { FormikProps } from 'formik'
import { toUpper } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { OrderSummary } from '@src/components/CheckoutModal/Checkout/OrderSummary/OrderSummary'
import { CheckoutButtonWrapperMobile } from '@src/components/CheckoutModal/CheckoutButtonWrapperMobile'
import {
  FlexGrowForm,
  FlexGrowScrollContainer,
  CheckoutButton,
  FulfilmentNoteContainer,
  ContentWrapper,
} from '@src/components/CheckoutModal/FormElements.styles'
import { UncontrolledTextInput } from '@src/components/Inputs/index'
import { Totals } from '@src/components/Totals/Totals'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSafeArea } from '@src/hooks/useSafeArea'

import { CollectionAddress } from './CollectionAddress'
import { CollectionFulfilmentFormSchema } from './useCollectionFulfilmentFormikProps/useCollectionFulfilmentFormikProps'

export const CollectionFulfilmentForm: React.FC<
  FormikProps<CollectionFulfilmentFormSchema>
> = ({ values, isSubmitting, handleChange }) => {
  const { isMobile } = useBreakpoint()
  const { safeAreaInsetBottom } = useSafeArea()
  const marketplace = useMarketplace()
  const { t } = useTranslation('checkout')

  return (
    <FlexGrowForm
      $windowHeight={window.innerHeight}
      $hasSafeArea={safeAreaInsetBottom > 0}
    >
      <FlexGrowScrollContainer>
        <CollectionAddress />
        <ContentWrapper>
          {/* Collection note */}
          <FulfilmentNoteContainer>
            <UncontrolledTextInput
              name="collectionNotes"
              touched
              label={t('collection_note')}
              value={values.collectionNotes}
              placeholder={
                marketplace.collectionNoteText || t('collection_note_example')
              }
              required={false}
              onChange={handleChange}
            />
          </FulfilmentNoteContainer>
          <OrderSummary />
          {(isMobile || safeAreaInsetBottom > 0) && <Totals />}
        </ContentWrapper>
      </FlexGrowScrollContainer>
      <CheckoutButtonWrapperMobile>
        {!isMobile && safeAreaInsetBottom === 0 && <Totals />}
        <CheckoutButton
          loading={isSubmitting}
          type="submit"
          content={toUpper(t('continue_to_payment'))}
          dataTestId="continue-to-payment-button"
        />
      </CheckoutButtonWrapperMobile>
    </FlexGrowForm>
  )
}
