export const addOnItems = {
  offers_for_you: 'Your Offers',
  offers_desc:
    '{{restaurantName}} would like to offer you a discount on any of these additional items:',
  continue: 'CONTINUE',
  no_thanks: 'NO THANKS, CONTINUE',
  add_offer: 'Add Offer',
  maximum_addon_purchase_quantity:
    'This is the maximum amount of this offer you can order.',
}
