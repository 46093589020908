import { useFormikContext } from 'formik'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { OrderSummary } from '@src/components/CheckoutModal/Checkout/OrderSummary/OrderSummary'
import { CheckoutPaymentCard } from '@src/components/CheckoutModal/Checkout/PaymentStep/PaymentMethods/CheckoutPaymentCard'
import { RestrictedItems } from '@src/components/CheckoutModal/Checkout/RestrictedItems/RestrictedItems'
import {
  ErrorContainer,
  ErrorMessage,
} from '@src/components/Errors/error.styles'
import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs/CheckboxInput/CheckboxInput'
import { LoadingSpinner } from '@src/components/LoadingSpinner'
import { PaymentElement } from '@src/components/Stripe/PaymentElement'
import { WarningTriangleSVG } from '@src/components/SVGS/WarningTriangleSVG'
import { Totals } from '@src/components/Totals'
import { DetailedPaymentMethod } from '@src/graphql-types'
import { Card } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useCheckoutRouter } from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useSafeArea } from '@src/hooks/useSafeArea'

import {
  AddCardContainer,
  CheckoutText,
  CheckoutTextContainer,
  Container,
  Description,
  IconContainer,
  InnerContainer,
  PaymentMethodContainer,
  RadioInfoLabel,
  RadioWrapper,
} from './PaymentMethods.styles'

import { PaymentMethodsProps, PaymentStepFormValues } from '../paymentTypes'

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  loading,
  paymentInfo,
  verificationInfo,
  historicalData,
  paymentPlatforms,
  paymentCardsInfo,
}) => {
  const marketplace = useMarketplace()
  const { setFieldValue } = useFormikContext()
  const { t } = useTranslation('checkout')
  const checkoutRouter = useCheckoutRouter()
  const { isMobile } = useBreakpoint()
  const { safeAreaInsetBottom } = useSafeArea()

  const canPayByCash = paymentInfo.availablePaymentMethods.includes(
    DetailedPaymentMethod.CASH
  )
  const isPaymentElementSelected = [
    DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
  ].includes(paymentInfo.paymentValue.paymentMethod)

  const showCheckoutText = Boolean(marketplace.checkoutText)

  const isCardSelected = (
    cardId: string,
    paymentValue: PaymentStepFormValues['payment']
  ): boolean => {
    return (
      paymentValue.paymentMethod === DetailedPaymentMethod.CARD &&
      paymentValue.cardPaymentToken === cardId
    )
  }

  return (
    <>
      <Container>
        <Description>{t('select_payment_method')}</Description>

        {checkoutRouter && checkoutRouter.errorMessage && (
          <ErrorContainer>
            <WarningTriangleSVG
              width={60}
              height={60}
              id={'accept-discount-warning'}
            />
            <ErrorMessage>{checkoutRouter.errorMessage}</ErrorMessage>
          </ErrorContainer>
        )}

        <InnerContainer
          selected={[
            DetailedPaymentMethod.CARD,
            DetailedPaymentMethod.CASH,
            DetailedPaymentMethod.WALLET,
          ].includes(paymentInfo.paymentValue.paymentMethod)}
        >
          <PaymentMethodContainer>
            {canPayByCash && (
              <RadioWrapper>
                <UncontrolledRadioInput
                  disabled={loading}
                  value="cash"
                  label={
                    <RadioInfoLabel>
                      {t('cash')}
                      <p>{t('cash_info')}</p>
                    </RadioInfoLabel>
                  }
                  checked={
                    paymentInfo.paymentValue.paymentMethod ===
                    DetailedPaymentMethod.CASH
                  }
                  style={CheckboxInputStyle.TICK}
                  onChange={() => {
                    checkoutRouter.resetError()
                    setFieldValue('payment', {
                      paymentMethod: DetailedPaymentMethod.CASH,
                    })
                  }}
                  dataTestId={`payment-method-radio-cash`}
                />
              </RadioWrapper>
            )}
            {paymentInfo.availablePaymentMethods.includes(
              DetailedPaymentMethod.CARD
            ) &&
              paymentCardsInfo.paymentCards.length > 0 && (
                <Fragment>
                  {paymentCardsInfo.loadingPaymentCards && <LoadingSpinner />}

                  {paymentCardsInfo.paymentCards.map(card => {
                    const isExpired = paymentCardsInfo.isExpired(card)
                    const isSelected =
                      isCardSelected(card.id, paymentInfo.paymentValue) &&
                      !isExpired
                    return (
                      <CheckoutPaymentCard
                        key={card.id}
                        card={card}
                        onSelect={(card: Card) => {
                          checkoutRouter.resetError()
                          setFieldValue('payment', {
                            paymentMethod: DetailedPaymentMethod.CARD,
                            cardPaymentToken: card.id,
                          })
                        }}
                        isExpired={isExpired}
                        isSelected={isSelected}
                        refetch={paymentCardsInfo.refetchPaymentCardData}
                        isLoading={loading}
                      />
                    )
                  })}
                </Fragment>
              )}
            {paymentInfo.availablePaymentMethods.includes(
              DetailedPaymentMethod.WALLET
            ) &&
              (paymentPlatforms.isGooglePayAvailable ||
                paymentPlatforms.isApplePayAvailable) && (
                <RadioWrapper>
                  <UncontrolledRadioInput
                    disabled={loading}
                    value="apple-payment"
                    label={
                      <>
                        {paymentPlatforms.isGooglePayAvailable && (
                          <RadioInfoLabel>{t('google_pay')}</RadioInfoLabel>
                        )}
                        {paymentPlatforms.isApplePayAvailable && (
                          <RadioInfoLabel>{t('apple_pay')}</RadioInfoLabel>
                        )}
                      </>
                    }
                    style={CheckboxInputStyle.TICK}
                    checked={
                      paymentInfo.paymentValue.paymentMethod ===
                      DetailedPaymentMethod.WALLET
                    }
                    onChange={() => {
                      checkoutRouter.resetError()
                      setFieldValue('payment', {
                        paymentMethod: DetailedPaymentMethod.WALLET,
                      })
                    }}
                  />
                </RadioWrapper>
              )}
          </PaymentMethodContainer>
        </InnerContainer>

        {paymentInfo.availablePaymentMethods.includes(
          DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD
        ) && (
          <InnerContainer selected={isPaymentElementSelected}>
            <AddCardContainer>
              <RadioWrapper>
                <UncontrolledRadioInput
                  disabled={loading}
                  value="add-payment"
                  label={t('add_card')}
                  style={CheckboxInputStyle.ADD}
                  checked={isPaymentElementSelected}
                  onChange={() => {
                    checkoutRouter.resetError()
                    setFieldValue('payment', {
                      paymentMethod:
                        DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
                    })
                  }}
                />
              </RadioWrapper>
            </AddCardContainer>
            {isPaymentElementSelected && (
              <PaymentMethodContainer disabled={loading}>
                {paymentInfo.availablePaymentMethods.includes(
                  DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD
                ) && (
                  <Fragment>
                    <PaymentElement
                      businessName={marketplace.name}
                      onChange={() => {
                        checkoutRouter.resetError()
                        setFieldValue('payment', {
                          paymentMethod:
                            DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD,
                          cardPaymentToken: undefined,
                        })
                      }}
                    />
                  </Fragment>
                )}
              </PaymentMethodContainer>
            )}
          </InnerContainer>
        )}
        {showCheckoutText && marketplace.checkoutText && (
          <CheckoutTextContainer>
            <IconContainer aria-label={t('information_icon')}>
              {'i'}
            </IconContainer>
            <CheckoutText>{marketplace.checkoutText}</CheckoutText>
          </CheckoutTextContainer>
        )}
        {verificationInfo.isAgeVerificationRequired && (
          <RestrictedItems disabled={loading} />
        )}
        <OrderSummary
          addressId={historicalData.addressId}
          tableId={historicalData.tableId}
        />
        {(isMobile || safeAreaInsetBottom > 0) && (
          <Totals isCheckoutTextVisible={showCheckoutText} />
        )}
      </Container>
    </>
  )
}
