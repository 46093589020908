import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

import { LeftArrowSVG } from '../SVGS/LeftArrowSVG'

export const BackButtonContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 4px 0px 0px;
`

export const StyledBackButton = styled.button<{ compactStyle?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
`

// secondary button theme
export const StyledLeftArrowSVG = styled(LeftArrowSVG)`
  margin-right: 0px;
  color: ${({ theme }) => theme.colors.mainText};
  &:active,
  &:focus {
    color: ${({ theme }) => theme.colors.brandForeground};
    background-color: ${({ theme }) => theme.colors.brand};
  }
  padding: 11px 8px;
  border-radius: 100%;

  @media (hover: hover) and (pointer: fine) {
    ${StyledBackButton}:hover & {
      color: ${({ theme }) => theme.colors.brandForeground};
      background-color: ${({ theme }) => theme.colors.brand};
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: 8px;
    transition: 0.3s;
    transition-property: color, background-color;
    background-color: #fff;

    color: ${({ theme }) => theme.colors.brand};
    background-color: ${({ theme }) => theme.colors.grey};
  }

  @media (min-width: ${breakpoints.tablet}px) {
    margin-right: 8px;
  }
`
