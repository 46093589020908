import { Field, FieldProps, FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  CheckboxInputStyle,
  UncontrolledCheckboxInput,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import { Option, OptionItem } from '@src/graphql-types'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import requiredTickIcon from '@src/images/tick.svg'

import {
  Container,
  Legend,
  RequiredContainer,
  OptionPrice,
  OptionRow,
  RequiredIcon,
  RequiredText,
  MinMaxLegendContainer,
  ScrollToContainer,
  SoldOut,
} from './MenuItemOptionField.styles'

import type { FormValues } from '../types'

type OptionFields = Pick<Option, 'id' | 'name' | 'minOptions' | 'maxOptions'>
type OptionItemFields = Pick<OptionItem, 'id' | 'name' | 'price' | 'soldOut'>
type OptionWithOptionItem = OptionFields & { optionItems: OptionItemFields[] }

const handleChecked = ({
  isRadio,
  form,
  optionItem,
  option: { optionItems },
}: {
  isRadio: boolean
  form: FormikProps<FormValues>
  optionItem: OptionItemFields
  option: OptionWithOptionItem
}) => {
  const isChecked = form.values.optionItemIds.includes(optionItem.id)
  const optionItemIds = optionItems.map(({ id }) => id)
  if (isChecked) {
    form.setFieldValue('optionItemIds', [
      ...form.values.optionItemIds.filter(
        checkedOptionItemId => checkedOptionItemId !== optionItem.id
      ),
    ])
  } else {
    if (isRadio) {
      form.setFieldValue('optionItemIds', [
        ...form.values.optionItemIds.filter(
          selectedOptionItem => !optionItemIds.includes(selectedOptionItem)
        ),
        optionItem.id,
      ])
    } else {
      form.setFieldValue('optionItemIds', [
        ...form.values.optionItemIds,
        optionItem.id,
      ])
    }
  }
}

export const MenuItemOptionField: React.FC<{
  option: OptionWithOptionItem
  itemCountState: number
  setSelectedRef: (selectedRef: string | null) => void
  clickCount: number
  disabled?: boolean
}> = ({
  option,
  itemCountState,
  clickCount,
  disabled = false,
  setSelectedRef,
}) => {
  const { name, maxOptions, minOptions, optionItems } = option
  const { t } = useTranslation('menuItem')
  const formatCurrency = useFormatCurrency()

  const isDisabledByItemCount = itemCountState === 0 || disabled
  const isRadio = minOptions === 1 && maxOptions === 1
  const isRequired = !!minOptions
  const selectedOptionItemsCount = (form: FormikProps<FormValues>) => {
    const count = optionItems.filter(({ id }) =>
      form.values.optionItemIds.includes(id)
    ).length
    return count
  }

  const minMaxLegend = () => {
    if (minOptions && maxOptions) {
      if (minOptions === maxOptions) {
        if (minOptions === 1) {
          return t('exact_amount_1_text')
        } else {
          return t('exact_amount_text', { amount: minOptions })
        }
      } else {
        return t('min_max_text', { min: minOptions, max: maxOptions })
      }
    } else if (minOptions) {
      if (minOptions === 1) {
        return t('min_1_text')
      } else {
        return t('min_text', { min: minOptions })
      }
    } else if (maxOptions) {
      if (maxOptions === 1) {
        return t('max_1_text')
      } else {
        return t('max_text', { max: maxOptions })
      }
    }
  }

  return (
    <>
      <ScrollToContainer aria-hidden id={option.id} />
      <Field name={option.id}>
        {({ form, meta }: FieldProps<any, FormValues>) => (
          <Container id={option.id}>
            <Legend>{name}</Legend>
            {isRequired ? (
              <RequiredContainer>
                <RequiredIcon src={requiredTickIcon} alt="required icon" />
                <RequiredText error={!!meta.error}>
                  {t('required')}
                </RequiredText>
              </RequiredContainer>
            ) : null}
            <MinMaxLegendContainer>
              <RequiredText error={!!meta.error}>{minMaxLegend()}</RequiredText>
            </MinMaxLegendContainer>

            {optionItems.map((optionItem, index) => {
              const label = (
                <>
                  {optionItem.name}
                  {!disabled && optionItem.soldOut ? (
                    <SoldOut>{t('sold_out')}</SoldOut>
                  ) : (
                    <OptionPrice>
                      {optionItem.price > 0 ? (
                        <>{formatCurrency(optionItem.price, '+')}</>
                      ) : undefined}
                    </OptionPrice>
                  )}
                </>
              )

              return (
                <OptionRow
                  key={optionItem.id}
                  disabled={
                    isDisabledByItemCount || Boolean(optionItem.soldOut)
                  }
                  error={!!meta.error && clickCount > 0}
                >
                  {isRadio ? (
                    <UncontrolledRadioInput
                      style={CheckboxInputStyle.TICK}
                      label={label}
                      value={optionItem.id}
                      checked={form.values.optionItemIds.includes(
                        optionItem.id
                      )}
                      disabled={
                        isDisabledByItemCount || Boolean(optionItem.soldOut)
                      }
                      onChange={() => {
                        form.setFieldTouched(option.id)
                        if (!isDisabledByItemCount) {
                          handleChecked({ isRadio, form, optionItem, option })
                        }
                      }}
                      onClick={() => {
                        setSelectedRef(option.id)
                      }}
                      dataTestId={`menu-item-option-${index}`}
                    />
                  ) : (
                    <UncontrolledCheckboxInput
                      label={label}
                      style={CheckboxInputStyle.TICK}
                      name={optionItem.id}
                      checked={form.values.optionItemIds.includes(
                        optionItem.id
                      )}
                      disabled={
                        Boolean(optionItem.soldOut) ||
                        isDisabledByItemCount ||
                        (selectedOptionItemsCount(form) >= maxOptions &&
                          !form.values.optionItemIds.includes(optionItem.id))
                      }
                      onChange={() => {
                        form.setFieldTouched(option.id)
                        if (!isDisabledByItemCount) {
                          handleChecked({ isRadio, form, optionItem, option })
                        }
                        if (selectedOptionItemsCount(form) + 1 === maxOptions) {
                          setSelectedRef(option.id)
                        }
                      }}
                      dataTestId={`menu-item-option-${index}`}
                    />
                  )}
                </OptionRow>
              )
            })}
          </Container>
        )}
      </Field>
    </>
  )
}
