import React from 'react'

import { useMarketplace } from '@src/hooks/useMarketplace'
import { convertToSlug } from '@src/utils/convertToSlug'

import { StyledLink } from './LinkToOutlet.styles'

export const LinkToOutlet: React.FC<{
  outletId: string
  name: string
  className?: string
  dataTestId?: string
}> = ({ outletId, name, className, children, dataTestId }) => {
  const { urlPath } = useMarketplace()
  return (
    <StyledLink
      to={`/${urlPath}/${outletId}/${convertToSlug(name)}`}
      draggable={false}
      className={className}
      dataTestId={dataTestId}
    >
      {children}
    </StyledLink>
  )
}
