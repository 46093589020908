import { useReactiveVar } from '@apollo/client'
import { format } from 'date-fns'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  CheckboxInputStyle,
  UncontrolledRadioInput,
} from '@src/components/Inputs'
import { SelectWithArrow } from '@src/components/ServicePopover/SelectWithArrow'
import {
  DoneButton,
  MobileNavigationPopoverContent,
  MobileNavigationPopoverContentWrapper,
  MobileNavigationPopoverFooter,
  MobileNavigationPopoverFooterContent,
  MobileNavigationResetLink,
  OptionContentLabels,
  OptionLabel,
  PopoverOptionContainer,
} from '@src/components/ServicePopover/ServicePopover.styles'
import { breakpoints } from '@src/constants/breakpoints'
import { DefaultDatepickerOption } from '@src/graphql-types'
import {
  FulfilmentFilter,
  FulfilmentFilterWhenType,
} from '@src/hooks/useFulfilmentFilter/validation'
import { screenResolutionVar } from '@src/models/screenResolution'

import { ServicePopover } from './ServicePopover'
import { TimePopoverContent } from './TimePopoverContent'

export const TimeSection: React.FC<{
  availableWhenTypes: FulfilmentFilterWhenType[]
  selectedWhen: FulfilmentFilter['when']
  onDone: (when: FulfilmentFilter['when']) => void
  showSelection?: boolean
  onSecondaryServicePopoverToggle?: (isOpen: boolean) => void
}> = ({
  availableWhenTypes,
  selectedWhen,
  onDone,
  showSelection = true,
  onSecondaryServicePopoverToggle,
}) => {
  const { t } = useTranslation('serviceNavigation')
  const screenResolution = useReactiveVar(screenResolutionVar)
  const [preorderDatePopoverOpen, setPreorderDatePopoverOpen] =
    React.useState(false)
  const existingSelectedPreorderDate =
    selectedWhen.type === FulfilmentFilterWhenType.PREORDER
      ? selectedWhen.preorderDate
      : null
  const [selectedPreorderDate, setSelectedPreorderDate] =
    React.useState<Date | null>(existingSelectedPreorderDate)

  // if popover is opened, notify parent so that it can minimise other popovers
  useEffect(() => {
    onSecondaryServicePopoverToggle?.(preorderDatePopoverOpen)
  }, [preorderDatePopoverOpen, onSecondaryServicePopoverToggle])

  // if parent state changes reset internal state
  useEffect(() => {
    setSelectedPreorderDate(existingSelectedPreorderDate)
  }, [existingSelectedPreorderDate])

  const getOptionDataForWhenType = useCallback(
    (whenType: FulfilmentFilterWhenType) => {
      switch (whenType) {
        case FulfilmentFilterWhenType.ANYTIME:
          return {
            label: t('anytime'),
            value: DefaultDatepickerOption.ANYTIME,
            onSelect: () => {
              onDone({
                type: FulfilmentFilterWhenType.ANYTIME,
              })
            },
          }
        case FulfilmentFilterWhenType.ASAP:
          return {
            label: t('now'),
            value: DefaultDatepickerOption.ASAP,
            onSelect: () => {
              onDone({
                type: FulfilmentFilterWhenType.ASAP,
              })
            },
          }
        case FulfilmentFilterWhenType.PREORDER: {
          const label =
            showSelection &&
            selectedWhen.type === FulfilmentFilterWhenType.PREORDER
              ? format(selectedWhen.preorderDate, 'd MMMM')
              : t('schedule')
          return {
            label,
            value: DefaultDatepickerOption.PREORDER,
            onSelect: () => {
              setPreorderDatePopoverOpen(true)
            },
          }
        }
      }
    },
    [selectedWhen, showSelection, onDone, t]
  )

  return (
    <>
      {availableWhenTypes.map(whenType => {
        const optionData = getOptionDataForWhenType(whenType)
        return (
          <PopoverOptionContainer key={optionData.value}>
            <UncontrolledRadioInput
              label={
                <OptionLabel>
                  <OptionContentLabels>
                    <span data-test-id={`time-option-${optionData.label}`}>
                      {optionData.label}
                    </span>
                  </OptionContentLabels>
                  {optionData.value === DefaultDatepickerOption.PREORDER && (
                    <SelectWithArrow
                      id={optionData.label}
                      dataPresent={true}
                      justArrow={
                        screenResolution.width >= breakpoints.largeTablet
                      }
                    />
                  )}
                </OptionLabel>
              }
              style={CheckboxInputStyle.TICK}
              checked={selectedWhen.type === whenType}
              onClick={optionData.onSelect}
            />
          </PopoverOptionContainer>
        )
      })}

      {/* Day popover */}
      <ServicePopover
        open={preorderDatePopoverOpen}
        onClose={() => {
          setPreorderDatePopoverOpen(false)
          setSelectedPreorderDate(existingSelectedPreorderDate)
        }}
        headerColor="#fff"
        backgroundColor={'#fff'}
        maxWidth={584}
      >
        <MobileNavigationPopoverContentWrapper>
          <MobileNavigationPopoverContent>
            <TimePopoverContent
              selectedDate={selectedPreorderDate}
              setSelectedDate={setSelectedPreorderDate}
            />
          </MobileNavigationPopoverContent>
        </MobileNavigationPopoverContentWrapper>
        <MobileNavigationPopoverFooter>
          <MobileNavigationPopoverFooterContent>
            <MobileNavigationResetLink
              onClick={() => {
                setPreorderDatePopoverOpen(false)
                setSelectedPreorderDate(existingSelectedPreorderDate)
              }}
            >
              {t('cancel')}
            </MobileNavigationResetLink>

            <DoneButton
              content={t('done')}
              width="180px"
              onClick={() => {
                setPreorderDatePopoverOpen(false)
                if (selectedPreorderDate) {
                  onDone({
                    type: FulfilmentFilterWhenType.PREORDER,
                    preorderDate: selectedPreorderDate,
                  })
                }
              }}
              type={'button'}
              disabled={!selectedPreorderDate}
              dataTestId="schedule-done-button"
            />
          </MobileNavigationPopoverFooterContent>
        </MobileNavigationPopoverFooter>
      </ServicePopover>
      {/* End day popover */}
    </>
  )
}
