import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const StatusBoxContainer = styled.div<{ isAccountPage: boolean }>`
  margin: 0;
  padding: 8px 16px 16px 16px;
  min-height: 116px;

  @media (min-width: ${breakpoints.desktop}px) {
    width: 100%;
    max-width: 350px;
    min-height: 132px;
  }
`

export const StatusInfoContainer = styled.div`
  flex: 1;
`

export const StatusBoxText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 2px 0px 0px 0px;
  @media (min-width: ${breakpoints.largeTablet}px) {
    font-size: 12px;
  }
`

export const StatusBoxTextHeader = styled.h4`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  line-height: 16px;
  margin: 4px 0px 8px 0px;
  @media (min-width: ${breakpoints.largeTablet}px) {
    font-size: 16px;
  }
`

export const FailedHeader = styled(StatusBoxTextHeader)<{
  isAccountPage: boolean
}>`
  font-size: 18px;
  margin: -2px 0 8px 0;

  @media (min-width: ${breakpoints.desktop}px) {
    margin: ${props =>
      props.isAccountPage ? '-2px 0 18px 0' : '-2px 0 16px 0'};
  }
`

export const StatusBoxPlaced = styled.span`
  font-weight: 400;
  font-size: 11px;
  display: inline-block;
  width: 30%;
  margin-bottom: 12px;
`

export const StatusBoxUpdate = styled.span`
  margin: 0;
  text-align: right;
  display: inline-block;
  width: 70%;
  font-weight: 400;
  font-size: 11px;
  margin-bottom: 12px;
`

export const RestaurantLogo = styled.div<{
  imageUrl: string
}>`
  width: 64px;
  height: 64px;
  border-radius: 4px;
  background-color: #f6f6f9;
  background-size: contain;
  margin-right: 16px;

  ${props =>
    props.imageUrl &&
    css`
      background-image: url(${props.imageUrl});
    `};
`

export const StatusPhotoText = styled.div`
  display: flex;
`
