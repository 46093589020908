import { FetchResult, useApolloClient, useMutation } from '@apollo/client'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import gql from 'graphql-tag'
import { useState } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  DetailedPaymentMethod,
  OptionItem,
  OutletMenuItem,
} from '@src/graphql-types'
import {
  useOutletFulfilment,
  OutletFulfilmentStateType,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { usePaymentCardsQuery } from '@src/hooks/sharedQueries/usePaymentCards/usePaymentCards'
import { UnavailableItems } from '@src/hooks/useBasketItems/hookMethods/patchItemAvailability'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { alertOkModalVar } from '@src/models/basket/alertOkModal'
import { apolloErrorParser } from '@src/utils/apolloErrorParser/apolloErrorParser'

import {
  CheckoutCreateOrderDocument,
  CheckoutCreateOrderMutation,
  CheckoutCreateOrderMutationVariables,
} from '../__generated__/CheckoutCreateOrder.graphql-interface'

export const useCreateOrderMutation = () => {
  const { t } = useTranslation('dirtyBasket')
  const client = useApolloClient()
  const basketItems = useBasketItems()
  const paymentCardsQuery = usePaymentCardsQuery()
  const checkoutRouter = useCheckoutRouter()
  const history = useHistory()
  const stripe = useStripe()
  const elements = useElements()

  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const marketplace = useMarketplace()
  const [outletUnavailableMessage, setOutletUnavailableMessage] =
    useState<string>()
  // use a custom loading state so we can block changes during 3ds check
  const [loading, setLoading] = useState(false)

  const [callCreateOrderMutation, { called }] = useMutation(
    CheckoutCreateOrderDocument,
    {
      onError: err => {
        const parsedErrors = apolloErrorParser(err)
        const { customClientErrors, genericClientErrors } = parsedErrors
        const { cache } = client
        const unavailableItemReasonsByBasketItemId: UnavailableItems = {}

        for (const customError of customClientErrors) {
          if (customError.data.errorResolutionType === 'ORDER_LIMIT_REACHED') {
            alertOkModalVar({
              alertTitle: 'Order limit reached',
              alertMessage: customError.message,
            })
          }
          if (customError.data.errorResolutionType === 'OUTLET_CLOSED') {
            setOutletUnavailableMessage(customError.message)
          }

          if (
            customError.data.errorResolutionType === 'OPTION_ITEMS_UNAVAILABLE'
          ) {
            const unavailableOptionItemIds =
              customError.data.unavailableOptionItemIds
            for (const unavailableOptionItemId of unavailableOptionItemIds) {
              const optionItemInCacheId = cache.identify({
                __typename: 'OptionItem',
                id: unavailableOptionItemId,
              })
              if (optionItemInCacheId) {
                const itemInCache = cache.readFragment<OptionItem>({
                  id: optionItemInCacheId,
                  fragment: gql`
                    fragment OptionItemFragment on OptionItem {
                      id
                      soldOut
                    }
                  `,
                })
                if (itemInCache) {
                  const updatedOptionItem = {
                    ...itemInCache,
                    soldOut: true,
                  }
                  cache.writeFragment({
                    id: optionItemInCacheId,
                    fragment: gql`
                      fragment OptionItemFragment on OptionItem {
                        id
                        soldOut
                      }
                    `,
                    data: updatedOptionItem,
                  })
                }
              }
            }

            // update unavailableItems
            for (const basketItem of basketItems.items) {
              const unavailableBasketOptionItemIds =
                basketItem.optionItemIds.filter(optionItemId =>
                  unavailableOptionItemIds.includes(optionItemId)
                )
              if (unavailableBasketOptionItemIds) {
                const existingReasons =
                  unavailableItemReasonsByBasketItemId[basketItem.id]
                unavailableItemReasonsByBasketItemId[basketItem.id] = {
                  ...existingReasons,
                  optionItem: {
                    isOptionItemUnavailable: true,
                    unavailableOptionItemIds: unavailableBasketOptionItemIds,
                  },
                }
              }
            }
          }

          if (customError.data.errorResolutionType === 'ITEMS_UNAVAILABLE') {
            const soldOutOutletMenuItemIds =
              customError.data.soldOutOrderItems.map(
                ({ outletMenuItemId }) => outletMenuItemId
              )

            if (soldOutOutletMenuItemIds.length) {
              // set sold out flag on outlet menu items in cache
              soldOutOutletMenuItemIds.forEach(soldOutOutletMenuItemId => {
                const menuItemCacheId = cache.identify({
                  __typename: 'OutletMenuItem',
                  id: soldOutOutletMenuItemId,
                })
                if (menuItemCacheId) {
                  const menuItemInCache = cache.readFragment<OutletMenuItem>({
                    id: menuItemCacheId,
                    fragment: gql`
                      fragment OutletMenuItemFragment on OutletMenuItem {
                        id
                        soldOut
                      }
                    `,
                  })
                  if (menuItemInCache) {
                    const updatedMenuItem = {
                      ...menuItemInCache,
                      soldOut: true,
                    }
                    cache.writeFragment({
                      id: menuItemCacheId,
                      fragment: gql`
                        fragment OutletMenuItemFragment on OutletMenuItem {
                          id
                          soldOut
                        }
                      `,
                      data: updatedMenuItem,
                    })
                  }
                }
              })
            }

            if (
              customError.data.hiddenMenuItemIds.length ||
              soldOutOutletMenuItemIds.length
            ) {
              // remove hidden menu items from cache
              customError.data.hiddenMenuItemIds.forEach(hiddenMenuItemId => {
                const menuItemCacheId = cache.identify({
                  __typename: 'OutletMenuItem',
                  id: `${outletFulfilment.outlet.id}:${hiddenMenuItemId}`,
                })
                if (menuItemCacheId) {
                  cache.evict({ id: menuItemCacheId })
                  cache.gc()
                }
              })

              // update unavailableItems
              for (const basketItem of basketItems.items) {
                if (
                  soldOutOutletMenuItemIds.includes(
                    basketItem.outletMenuItemId
                  ) ||
                  customError.data.hiddenMenuItemIds.includes(
                    basketItem.menuItemId
                  )
                ) {
                  const existingReasons =
                    unavailableItemReasonsByBasketItemId[basketItem.id]
                  unavailableItemReasonsByBasketItemId[basketItem.id] = {
                    ...existingReasons,
                    menuItem: {
                      isMenuItemUnavailable: true,
                    },
                  }
                }
              }
            }
          }

          if (
            customError.data.errorResolutionType ===
            'ITEMS_UNAVAILABLE_FOR_FULFILLMENT'
          ) {
            const unavailableMenuItemIds =
              customError.data.unavailableMenuItemIds

            // update unavailableItems
            for (const basketItem of basketItems.items) {
              if (unavailableMenuItemIds.includes(basketItem.menuItemId)) {
                const existingReasons =
                  unavailableItemReasonsByBasketItemId[basketItem.id]
                unavailableItemReasonsByBasketItemId[basketItem.id] = {
                  ...existingReasons,
                  menuItem: {
                    isMenuItemUnavailable: true,
                  },
                  fulfilmentMethod: {
                    isFulfilmentUnavailable: true,
                    unavailableFulfilmentMethods: [
                      customError.data.fulfillmentMethod,
                    ],
                  },
                }
              }
            }
          }

          if (
            customError.data.errorResolutionType ===
            'ITEMS_UNAVAILABLE_FOR_TIME'
          ) {
            const unavailableMenuItemIds =
              customError.data.unavailableMenuItemIds

            // update unavailableItems
            for (const basketItem of basketItems.items) {
              if (unavailableMenuItemIds.includes(basketItem.menuItemId)) {
                const existingReasons =
                  unavailableItemReasonsByBasketItemId[basketItem.id]
                unavailableItemReasonsByBasketItemId[basketItem.id] = {
                  ...existingReasons,
                  isUnavailableForTime: true,
                }
              }
            }
          }

          basketItems.patchItemAvailability({
            unavailableItems: unavailableItemReasonsByBasketItemId,
          })

          setLoading(false)
          return
        }

        for (const genericError of genericClientErrors) {
          checkoutRouter.setError(t(genericError.message))
          setLoading(false)
          return
        }
      },
      async onCompleted(data) {
        const newOrder = data.createOrder
        if (newOrder) {
          let stripeConfirmPaymentResult
          const [order] = newOrder.orders

          if (!order) {
            checkoutRouter.setError(t('card_payment_failed'))
            setLoading(false)
            return
          }
          // these are the scenarios where the customer will not be prompted for 3ds or any other next_actions
          if (
            ['CashOrderResponse', 'CardInPersonOrderResponse'].includes(
              newOrder.__typename
            ) ||
            (newOrder.__typename === 'CardOrderResponse' &&
              !newOrder.paymentIntentClientSecret)
          ) {
            void checkoutRouter.complete(order.id)
            ReactPixel.track('Purchase', {
              value: order.grossTotal,
              currency: marketplace.country.currency.iso4217,
              content_ids: [order.id],
              contents: basketItems.items.map(
                ({ menuItemId, quantity, name, optionItemIds }) => ({
                  menuItemId,
                  quantity,
                  name,
                  optionItemIds,
                })
              ),
              content_name: order.outlet.restaurant.name,
            })
            basketItems.clear({
              shouldResetCheckoutRouter: false,
            })
            outletFulfilment.reinitialise()
            setLoading(false)
            return
          }
          if (!stripe || !elements || !order) {
            checkoutRouter.setError(t('card_payment_unavailable'))
            setLoading(false)
            return
          }

          const returnUrl = `${origin}${history.location.pathname}?checkoutRoute=${CheckoutRoute.PAYMENT_REDIRECT_VERIFICATION}&redirectOrderId=${order.id}`
          if (newOrder.__typename === 'ManualConfirmationCardOrderResponse') {
            const clientSecret = newOrder.manualConfirmationPIClientSecret
            stripeConfirmPaymentResult = await stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: returnUrl,
                payment_method_data: {
                  billing_details: {
                    email: order?.customer?.email as string,
                    phone: order?.customer?.phoneNumber as string,
                    name: `${order?.customer?.firstName} ${order?.customer?.lastName}`,
                  },
                },
              },
              clientSecret,
              redirect: 'if_required',
            })
          }
          if (
            newOrder.__typename === 'CardOrderResponse' &&
            newOrder.paymentIntentClientSecret
          ) {
            const clientSecret = newOrder.paymentIntentClientSecret
            stripeConfirmPaymentResult = await stripe.confirmCardPayment(
              clientSecret,
              {
                return_url: returnUrl,
              }
            )
          }
          if (!stripeConfirmPaymentResult || stripeConfirmPaymentResult.error) {
            checkoutRouter.setError(
              stripeConfirmPaymentResult?.error.type === 'card_error' &&
                stripeConfirmPaymentResult?.error.message
                ? stripeConfirmPaymentResult.error.message
                : t('card_payment_failed')
            )
            setLoading(false)
            return
          }
          if (
            ['succeeded', 'requires_capture'].includes(
              stripeConfirmPaymentResult.paymentIntent?.status
            )
          ) {
            setLoading(false)
            void checkoutRouter.complete(order.id)
            ReactPixel.track('Purchase', {
              value: order.grossTotal,
              currency: marketplace.country.currency.iso4217,
              content_ids: [order.id],
              content_name: order.outlet.restaurant.name,
              contents: basketItems.items.map(
                ({ menuItemId, quantity, name, optionItemIds }) => ({
                  menuItemId,
                  quantity,
                  name,
                  optionItemIds,
                })
              ),
            })
            basketItems.clear({
              shouldResetCheckoutRouter: false,
            })
            outletFulfilment.reinitialise()
            void paymentCardsQuery.refetch()
            return
          } else {
            // TODO: Translation
            setLoading(false)
            checkoutRouter.setError('Unexpected error')
            return
          }
        }
      },
    }
  )
  // wrap createOrder in a function which verifies that stripe and elements are available
  // and submits the card details to stripe
  const createOrder: CreateOrder = async ({ orderData }) => {
    if (orderData.payment.paymentMethod !== DetailedPaymentMethod.WALLET) {
      elements?.update({
        setup_future_usage: 'on_session',
      })
    }

    setLoading(true)
    if (
      [DetailedPaymentMethod.MANUAL_CONFIRMATION_CARD].includes(
        orderData.payment.paymentMethod
      )
    ) {
      if (!stripe || !elements) {
        checkoutRouter.setError(t('card_payment_unavailable'))
        return
      }
      const { error: submitError } = await elements.submit()
      if (submitError) {
        // TODO: Handle Error
        console.error('Error submitting card details to stripe', {
          errorMessage: submitError.message,
        })
        setLoading(false)
        return
      }
    }
    return callCreateOrderMutation({
      variables: {
        orderData,
      },
    })
  }

  return {
    createOrder,
    validationErrors: {
      isOutletUnavailable: false,
      outletUnavailableMessage: outletUnavailableMessage,
    },
    loading,
    called,
  }
}

export type CreateOrder = (args: {
  orderData: CheckoutCreateOrderMutationVariables['orderData']
}) => Promise<
  | FetchResult<
      CheckoutCreateOrderMutation,
      Record<string, any>,
      Record<string, any>
    >
  | undefined
>
