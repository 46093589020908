import * as Types from '../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type MarketplaceCnameLookupFragmentFragment = {
  __typename: 'Marketplace'
  id: string
  key: string
  appText: string | null
  name: string
  title: string | null
  metaKeywords: string | null
  metaDescription: string | null
  faviconImage: string | null
  merchantType: Types.MerchantType
  orderMode: Types.OrderMode
  urlPath: string
  brandColor: string | null
  brandSecondaryColor: string | null
  allOutletsText: string | null
  stripePublicId: string | null
  stripeAccountId: string | null
  onBehalfOfStripeId: string | null
  logoImage: string | null
  confirmAddressAtPayment: boolean
  defaultFulfilmentOption: Types.DefaultFulfilmentOption
  defaultDatepickerOption: Types.DefaultDatepickerOption
  defaultLocationOption: Types.DefaultLocationOption
  categoryEmojiOrImage: Types.CategoryEmojiOrImage
  enableRedirectFromLandingPage: boolean | null
  approvedPaymentProviderMethods: Array<string> | null
  featureLogin: boolean
  featureRegister: boolean
  featureOrdering: boolean
  partnerChargeDescription: string | null
  partnerTableChargeDescription: string | null
  checkoutText: string | null
  specialInstructionsText: string | null
  supportEmail: string | null
  supportPhone: string | null
  socialURLTikTok: string | null
  socialURLInstagram: string | null
  socialURLFacebook: string | null
  socialURLTwitter: string | null
  storeURLApple: string | null
  storeURLGooglePlay: string | null
  vatNumber: string | null
  companyNumber: string | null
  companyLegalName: string | null
  legalAllergy: string | null
  legalPolicy: string | null
  legalTerms: string | null
  legalPrivacy: string | null
  legalCookies: string | null
  allowOrderToTable: boolean
  isVATregistered: boolean
  orderNoteText: string | null
  tableNoteText: string | null
  allowSingleItemOrderNotes: boolean | null
  deliveryNoteText: string | null
  collectionNoteText: string | null
  ageRestrictionText: string | null
  allowOnboarding: boolean
  facebookPixelId: string
  ga4Id: string
  country: {
    __typename: 'Country'
    id: string
    ISO3166Alpha2: string
    ISO3166Alpha3: string
    name: string
    postCodeRegex: string | null
    distanceUnit: string
    currency: {
      __typename: 'Currency'
      prefix: string | null
      suffix: string | null
      decimalMark: string | null
      decimalPlaces: number | null
      iso4217: string
    }
  }
  categories: Array<{ __typename: 'Cuisine'; id: string; name: string }>
  contactAddress: {
    __typename: 'Address'
    id: string
    firstLine: string
    secondLine: string | null
    thirdLine: string | null
    city: string
    postcode: string
    country: { __typename: 'Country'; name: string } | null
  }
  footerLinks: Array<{
    __typename: 'CustomLinkFooter'
    id: string
    title: string
    link: string
  }>
  headerLinks: Array<{
    __typename: 'CustomLinkHeader'
    id: string
    title: string
    link: string
  }>
}

export type marketplaceCnameLookupQueryVariables = Types.Exact<{
  cname: Types.Scalars['String']
}>

export type marketplaceCnameLookupQuery = {
  cnameLookup:
    | {
        __typename: 'Marketplace'
        id: string
        key: string
        appText: string | null
        name: string
        title: string | null
        metaKeywords: string | null
        metaDescription: string | null
        faviconImage: string | null
        merchantType: Types.MerchantType
        orderMode: Types.OrderMode
        urlPath: string
        brandColor: string | null
        brandSecondaryColor: string | null
        allOutletsText: string | null
        stripePublicId: string | null
        stripeAccountId: string | null
        onBehalfOfStripeId: string | null
        logoImage: string | null
        confirmAddressAtPayment: boolean
        defaultFulfilmentOption: Types.DefaultFulfilmentOption
        defaultDatepickerOption: Types.DefaultDatepickerOption
        defaultLocationOption: Types.DefaultLocationOption
        categoryEmojiOrImage: Types.CategoryEmojiOrImage
        enableRedirectFromLandingPage: boolean | null
        approvedPaymentProviderMethods: Array<string> | null
        featureLogin: boolean
        featureRegister: boolean
        featureOrdering: boolean
        partnerChargeDescription: string | null
        partnerTableChargeDescription: string | null
        checkoutText: string | null
        specialInstructionsText: string | null
        supportEmail: string | null
        supportPhone: string | null
        socialURLTikTok: string | null
        socialURLInstagram: string | null
        socialURLFacebook: string | null
        socialURLTwitter: string | null
        storeURLApple: string | null
        storeURLGooglePlay: string | null
        vatNumber: string | null
        companyNumber: string | null
        companyLegalName: string | null
        legalAllergy: string | null
        legalPolicy: string | null
        legalTerms: string | null
        legalPrivacy: string | null
        legalCookies: string | null
        allowOrderToTable: boolean
        isVATregistered: boolean
        orderNoteText: string | null
        tableNoteText: string | null
        allowSingleItemOrderNotes: boolean | null
        deliveryNoteText: string | null
        collectionNoteText: string | null
        ageRestrictionText: string | null
        allowOnboarding: boolean
        facebookPixelId: string
        ga4Id: string
        country: {
          __typename: 'Country'
          id: string
          ISO3166Alpha2: string
          ISO3166Alpha3: string
          name: string
          postCodeRegex: string | null
          distanceUnit: string
          currency: {
            __typename: 'Currency'
            prefix: string | null
            suffix: string | null
            decimalMark: string | null
            decimalPlaces: number | null
            iso4217: string
          }
        }
        categories: Array<{ __typename: 'Cuisine'; id: string; name: string }>
        contactAddress: {
          __typename: 'Address'
          id: string
          firstLine: string
          secondLine: string | null
          thirdLine: string | null
          city: string
          postcode: string
          country: { __typename: 'Country'; name: string } | null
        }
        footerLinks: Array<{
          __typename: 'CustomLinkFooter'
          id: string
          title: string
          link: string
        }>
        headerLinks: Array<{
          __typename: 'CustomLinkHeader'
          id: string
          title: string
          link: string
        }>
      }
    | {
        __typename: 'Restaurant'
        id: string
        outlets: Array<{
          __typename: 'Outlet'
          id: string
          marketplace: {
            __typename: 'Marketplace'
            id: string
            key: string
            appText: string | null
            name: string
            title: string | null
            metaKeywords: string | null
            metaDescription: string | null
            faviconImage: string | null
            merchantType: Types.MerchantType
            orderMode: Types.OrderMode
            urlPath: string
            brandColor: string | null
            brandSecondaryColor: string | null
            allOutletsText: string | null
            stripePublicId: string | null
            stripeAccountId: string | null
            onBehalfOfStripeId: string | null
            logoImage: string | null
            confirmAddressAtPayment: boolean
            defaultFulfilmentOption: Types.DefaultFulfilmentOption
            defaultDatepickerOption: Types.DefaultDatepickerOption
            defaultLocationOption: Types.DefaultLocationOption
            categoryEmojiOrImage: Types.CategoryEmojiOrImage
            enableRedirectFromLandingPage: boolean | null
            approvedPaymentProviderMethods: Array<string> | null
            featureLogin: boolean
            featureRegister: boolean
            featureOrdering: boolean
            partnerChargeDescription: string | null
            partnerTableChargeDescription: string | null
            checkoutText: string | null
            specialInstructionsText: string | null
            supportEmail: string | null
            supportPhone: string | null
            socialURLTikTok: string | null
            socialURLInstagram: string | null
            socialURLFacebook: string | null
            socialURLTwitter: string | null
            storeURLApple: string | null
            storeURLGooglePlay: string | null
            vatNumber: string | null
            companyNumber: string | null
            companyLegalName: string | null
            legalAllergy: string | null
            legalPolicy: string | null
            legalTerms: string | null
            legalPrivacy: string | null
            legalCookies: string | null
            allowOrderToTable: boolean
            isVATregistered: boolean
            orderNoteText: string | null
            tableNoteText: string | null
            allowSingleItemOrderNotes: boolean | null
            deliveryNoteText: string | null
            collectionNoteText: string | null
            ageRestrictionText: string | null
            allowOnboarding: boolean
            facebookPixelId: string
            ga4Id: string
            country: {
              __typename: 'Country'
              id: string
              ISO3166Alpha2: string
              ISO3166Alpha3: string
              name: string
              postCodeRegex: string | null
              distanceUnit: string
              currency: {
                __typename: 'Currency'
                prefix: string | null
                suffix: string | null
                decimalMark: string | null
                decimalPlaces: number | null
                iso4217: string
              }
            }
            categories: Array<{
              __typename: 'Cuisine'
              id: string
              name: string
            }>
            contactAddress: {
              __typename: 'Address'
              id: string
              firstLine: string
              secondLine: string | null
              thirdLine: string | null
              city: string
              postcode: string
              country: { __typename: 'Country'; name: string } | null
            }
            footerLinks: Array<{
              __typename: 'CustomLinkFooter'
              id: string
              title: string
              link: string
            }>
            headerLinks: Array<{
              __typename: 'CustomLinkHeader'
              id: string
              title: string
              link: string
            }>
          }
        }>
      }
}

export const MarketplaceCnameLookupFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MarketplaceCnameLookupFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Marketplace' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'key' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaKeywords' } },
          { kind: 'Field', name: { kind: 'Name', value: 'metaDescription' } },
          { kind: 'Field', name: { kind: 'Name', value: 'faviconImage' } },
          { kind: 'Field', name: { kind: 'Name', value: 'merchantType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderMode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urlPath' } },
          { kind: 'Field', name: { kind: 'Name', value: 'brandColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brandSecondaryColor' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allOutletsText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stripePublicId' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'useRedboxKey' },
                value: { kind: 'BooleanValue', value: true },
              },
            ],
          },
          { kind: 'Field', name: { kind: 'Name', value: 'stripeAccountId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onBehalfOfStripeId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'logoImage' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'confirmAddressAtPayment' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultFulfilmentOption' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultDatepickerOption' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'defaultLocationOption' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categoryEmojiOrImage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableRedirectFromLandingPage' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'approvedPaymentProviderMethods' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'featureLogin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureRegister' } },
          { kind: 'Field', name: { kind: 'Name', value: 'featureOrdering' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerChargeDescription' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'partnerTableChargeDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'checkoutText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'specialInstructionsText' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'supportEmail' } },
          { kind: 'Field', name: { kind: 'Name', value: 'supportPhone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialURLTikTok' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'socialURLInstagram' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'socialURLFacebook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'socialURLTwitter' } },
          { kind: 'Field', name: { kind: 'Name', value: 'storeURLApple' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storeURLGooglePlay' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'vatNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'companyLegalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalAllergy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalPolicy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalTerms' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalPrivacy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'legalCookies' } },
          { kind: 'Field', name: { kind: 'Name', value: 'allowOrderToTable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVATregistered' } },
          { kind: 'Field', name: { kind: 'Name', value: 'orderNoteText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tableNoteText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allowSingleItemOrderNotes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'deliveryNoteText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collectionNoteText' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'ageRestrictionText' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'allowOnboarding' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'suffix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalMark' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'decimalPlaces' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iso4217' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ISO3166Alpha2' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ISO3166Alpha3' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postCodeRegex' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'distanceUnit' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contactAddress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secondLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'thirdLine' } },
                { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postcode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'footerLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'headerLinks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'link' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'facebookPixelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ga4Id' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketplaceCnameLookupFragmentFragment, unknown>
export const marketplaceCnameLookupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketplaceCnameLookup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cname' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cnameLookup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cname' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cname' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Marketplace' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'MarketplaceCnameLookupFragment',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Restaurant' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'outlets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'marketplace' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'MarketplaceCnameLookupFragment',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...MarketplaceCnameLookupFragmentFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  marketplaceCnameLookupQuery,
  marketplaceCnameLookupQueryVariables
>
