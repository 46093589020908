import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button, ButtonType } from '@src/components/Button'
import { AlertModal } from '@src/components/Modal/AlertModal'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { CurrentFulfilmentType } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useBasketOutletId } from '@src/hooks/useBasketOutletId'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '@src/hooks/useCheckoutRouter/useCheckoutRouter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { convertToSlug } from '@src/utils/convertToSlug'

import { singleOrderCustomType } from './queries/getSingleOrderCustomType'

export const OrderAgainButton: React.FC<{ myOrder: singleOrderCustomType }> = ({
  myOrder,
}) => {
  const history = useHistory()
  const { t } = useTranslation('singleOrder')
  const basketItems = useBasketItems()
  const { urlPath } = useMarketplace()

  const confirmationRequired = !!basketItems.items.length
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const { updateBasketOutletId } = useBasketOutletId()
  const { setCurrentFulfilment } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })
  const { outlet, groupedItems } = myOrder

  const checkoutRouter = useCheckoutRouter()
  const reorder = () => {
    basketItems.clear({ shouldResetCheckoutRouter: false })

    //grouped items to basketItems and remove add-on items from reorder
    const newBasketItems = groupedItems
      .filter(item => !item.isAddOnItem)
      .map(item => ({
        name: item.name,
        menuItemId: item.menuItemId,
        outletMenuItemId: `${outlet.id}:${item.menuItemId}`,
        optionItemIds: item.optionItems.map(optionItem => optionItem.id),
        quantity: item.quantity,
        singleItemNotes: item.singleItemNotes ?? '',
      }))

    const { customerDeliveryAddress, narrowFulfilmentMethod } = myOrder

    if (narrowFulfilmentMethod === NarrowFulfilmentMethodInputType.DELIVERY) {
      if (!customerDeliveryAddress) {
        throw new Error('Delivery order without delivery address')
      }

      setCurrentFulfilment({
        deliveryNotes: null,
        orderNotes: myOrder.customerOrderNotes,
        type: CurrentFulfilmentType.DELIVERY_SAVED_ADDRESS,
        addressId: customerDeliveryAddress.id,
      })
    } else if (
      narrowFulfilmentMethod === NarrowFulfilmentMethodInputType.COLLECTION
    ) {
      setCurrentFulfilment({
        collectionNotes: null,
        orderNotes: myOrder.customerOrderNotes,

        type: CurrentFulfilmentType.COLLECTION,
      })
    } else if (
      narrowFulfilmentMethod === NarrowFulfilmentMethodInputType.TABLE
    ) {
      setCurrentFulfilment({
        tableNotes: null,
        orderNotes: myOrder.customerOrderNotes,
        type: CurrentFulfilmentType.TABLE_UNSELECTED,
      })
    }

    basketItems.addItems({ items: newBasketItems, removeExistingItems: true })
    updateBasketOutletId(outlet.id)
    history.push(
      `/${urlPath}/${outlet.id}/${convertToSlug(outlet.displayName)}/menu`
    )
    checkoutRouter.override(CheckoutRoute.BASKET)
  }

  return (
    <>
      <AlertModal
        isOpen={confirmationModalOpen}
        title={t('new_order_confirm')}
        action={{
          onClick: () => {
            setConfirmationModalOpen(false)
            reorder()
          },
          text: t('confirm'),
          intent: ButtonType.PRIMARY,
        }}
        cancel={{
          onClick: () => {
            setConfirmationModalOpen(false)
          },
          text: t('cancel'),
          intent: ButtonType.SECONDARY,
        }}
      />

      <Button
        onClick={() => {
          if (confirmationRequired) {
            setConfirmationModalOpen(true)
          } else {
            reorder()
          }
        }}
        disabled={!myOrder.outlet.active}
        content={t('order_again')}
      />
    </>
  )
}
