import React from 'react'
// disabling this eslint rule as this IS the one we want folk to use
// eslint-disable-next-line no-restricted-imports
import { Tooltip as OriginalTooltip } from 'react-tooltip'
import styled from 'styled-components'

/**
 * NOTE!
 * If you want to use this tooltip, it's a bit different from some other libraries.
 * For performance, it maintains one tooltip component and searches the DOM for data attributes to trigger it.
 *
 * Or that's the theory.
 *
 * Problematically, we have three react trees running in the app, due to drawers, modals etc.
 *
 * Find the element you want the tooltip on and set `data-tooltip-id` to
 * either:
 *
 * page-wrapper-tooltip (for stuff just generally on the page)
 * bottom-sheet-wrapper-tooltip (I used this for the AddMenuItemForm)
 * modal-tooltip (for things in modals, I used this for the BasketItem component)
 *
 * these Ids should match the three places this component is used.
 *
 * and put your content in `data-tooltip-content`
 *
 * https://react-tooltip.com/docs/getting-started
 *
 */

const StyledOriginalTooltip = styled(OriginalTooltip)`
  z-index: 10;
`

/** A thin wrapper around react-tooltip. Mainly to add in the delay, customisable in one place,
 * and to make the ID the same everywhere it's used */
export const Tooltip: React.FC<
  React.ComponentProps<typeof OriginalTooltip> & { id: string }
> = ({ ...props }) => {
  return <StyledOriginalTooltip delayShow={300} delayHide={1500} {...props} />
}
