import styled from 'styled-components'

import { CancelSVG } from '@src/components/SVGS/CancelSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  padding-right: 0px;
  padding: 8px 4px 0px 18px;
  width: auto;
  max-width: 600px;
`

export const OuterContainer = styled.div`
  border-bottom: 1px solid #ebecf0;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-content: center;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.darkText};
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  margin-bottom: 4px;
`

export const InnerTitleContainer = styled.div`
  display: flex;
  gap: 1px;
  align-items: center;
  flex-wrap: wrap;
`

export const ItemName = styled.a`
  font-weight: 700;
  margin: 0px;
  margin-right: 8px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkText};

  &:hover {
    color: unset;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 14px;
  }
`

export const OptionItemContainer = styled.div`
  margin: 2px 0 4px 0;
  border-radius: 4px;
`

export const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-self: flex-start;

  @media (min-width: ${breakpoints.tablet}px) {
    margin-bottom: 4px;
  }
`

export const ItemPrice = styled.p`
  font-weight: 700;
  font-size: 14px;
  margin: 0px;
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.brandHighlight};
`

export const SingleItemNote = styled.div`
  font-size: 14px;
  font-style: italic;
  padding: 0 0 0 4px;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;

  svg {
    cursor: pointer;
  }
  p {
    margin-left: 8px;
  }
`

export const StyledCancelSVG = styled(CancelSVG)`
  cursor: pointer;
  background-color: white;
  color: #e5e5e5;
  border-radius: 24px;
  &:hover {
    color: ${({ theme }) => theme.colors.danger};
  }
`

export const AllergenContainer = styled.span`
  display: flex;
  @media (min-width: ${breakpoints.tablet}px) {
    padding-top: 4px;
  }
`
