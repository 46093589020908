import { useReactiveVar } from '@apollo/client'

import { addItem } from './hookMethods/addItem'
import { addItems } from './hookMethods/addItems'
import { clear } from './hookMethods/clear'
import { patchItem } from './hookMethods/patchItem'
import { patchItemAvailability } from './hookMethods/patchItemAvailability'
import { removeItem as removeItemCurried } from './hookMethods/removeItem'
import { removeItems as removeItemsCurried } from './hookMethods/removeItems'
import { updateQuantity } from './hookMethods/updateQuantity'
import { basketItemsVar } from './utils/reactiveVar'
import { HookMethodArgs } from './utils/types'

import { useBasketOutletId } from '../useBasketOutletId'
import {
  CheckoutRoute,
  useCheckoutRouter,
} from '../useCheckoutRouter/useCheckoutRouter'

export const useBasketItems = () => {
  const { basketOutletId, updateBasketOutletId } = useBasketOutletId()
  const { override, route } = useCheckoutRouter()
  const items = useReactiveVar(basketItemsVar)

  const uniqueOutletMenuItemIds = [
    ...new Set(items.map(item => item.outletMenuItemId)),
  ]
  const hookMethodArgs: HookMethodArgs = {
    basketOutletId,
    updateBasketOutletId,
    existingItems: items,
    navigateToBasket: () => route && override(CheckoutRoute.BASKET),
    uniqueOutletMenuItemIds,
  }

  const removeItem = removeItemCurried(hookMethodArgs)
  const removeItems = removeItemsCurried(hookMethodArgs)
  return {
    items: items,
    uniqueOutletMenuItemIds,
    addOnItems: items.filter(item => item.price !== undefined),
    nonAddOnItems: items.filter(item => item.price === undefined),
    addItem: addItem(hookMethodArgs),
    addItems: addItems(hookMethodArgs),
    clear: clear(hookMethodArgs),
    patchItem: patchItem(hookMethodArgs),
    patchItemAvailability: patchItemAvailability(hookMethodArgs),
    removeItem,
    removeItems,
    updateQuantity: updateQuantity({
      ...hookMethodArgs,
      removeItem,
    }),
  }
}
