import { Address, Table, Outlet } from '@src/graphql-types'
import {
  ParsedCollectionPreorderTime,
  ParsedDeliveryPreorderWindow,
} from '@src/utils/fulfilmentTimes/types'

export interface Cuisine {
  id: string
  name: string
}

export interface Discount {
  id: string
  name: string
  customerEnrolled: boolean
  discountAmount: number | null
  discountPercentage: number | null
  minimumSubtotalGross: number | null
}

export interface Restaurant {
  id: string
  image: string | null
  name: string
  allDiscounts?: Discount[]
}

export type SingleOutlet = Pick<
  Outlet,
  | 'id'
  | 'name'
  | 'description'
  | 'isOpen'
  | 'isOnline'
  | 'openingTimesArray'
  | 'paymentMethods'
  | 'deliveryMinimumOrderValue'
  | 'collectionMinimumOrderValue'
  | 'asapAllowed'
  | 'ASAPDeliveryDuration'
  | 'prepTime'
  | 'coverImage'
  | 'allowPreorders'
  | 'outletLogoOverride'
  | 'distanceFromUserKM'
  | 'daysOfferedInAdvanceMin'
  | 'daysOfferedInAdvanceMax'
  | 'availableFulfillmentMethods'
  | 'statusText'
  | 'availableFulfilmentInputMethods'
  | 'noPreordersBeforeOpening'
  | 'displayName'
  | 'fulfilmentRange'
  | 'specialOfferFlags'
  | 'outletCuisines'
> & {
  restaurant: Restaurant
  deliveryPreorderWindows: ParsedDeliveryPreorderWindow[]
  collectionPreorderTimes: ParsedCollectionPreorderTime[]
  tables: Table[]
  outletAddress: Omit<Address, 'country' | 'acceptsDelivery'>
  nextOpenDate: Date | string
  closedUntil: Date | null
  outletAllDiscounts: Discount[]
}

export enum SingleOutletDirection {
  'ROW' = 'row',
  'COLUMN' = 'column',
}
