import styled, { css } from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'
import { removeScrollbar } from '@src/pages/global.styles'

export const Container = styled.div`
  background-color: white;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  padding: 0px 16px;
  overflow-y: scroll;
  ${removeScrollbar};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0px 24px;
  }
`

export const FilterOverlay = styled.div`
  position: absolute;
  height: 52px;
  top: 11px;
  width: 52px;
  background-color: white;
  z-index: 1;
  right: 0px;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 25%,
    rgba(255, 255, 255, 0)
  );
`

export const Capsule = styled.button<{ active: boolean }>`
  user-select: none;
  width: auto;
  white-space: nowrap;
  font-size: 14px;
  height: 100%;
  font-weight: 700;
  padding: ${({ active }) =>
    active ? `6px 12px 6px 12px` : `6px 2px 6px 2px`};
  border: none;
  margin: 0px 16px;
  color: ${({ active, theme }) =>
    active ? theme.colors.brandDark : theme.colors.brand};
  text-decoration: none;
  background: ${({ active, theme }) =>
    active ? theme.colors.brandLight : 'transparent'};
  display: flex;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  margin: 8px 16px 8px 0px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }

  ${({ active, theme }) =>
    !active &&
    css`
      &:hover {
        color: ${theme.colors.brandLight};
      }
    `}
`
