import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledAccountSectionNavButton } from '@src/components/BurgerMenu/AccountMain/AccountMain.styles'
import { MainRouteName } from '@src/hooks/useAccountRouter'

import { GroupContainer, GroupHeader } from './AccountNavigationLinks.styles'

export const AccountNavigationLinks: React.FC = () => {
  const { t } = useTranslation('navigationLinks')

  return (
    <>
      <GroupContainer>
        <GroupHeader>{t('details')}</GroupHeader>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.DETAILS }}
          dataTestId="my-account"
        >
          {t('my_account')}
        </StyledAccountSectionNavButton>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.PAYMENT }}
          dataTestId="saved-cards"
        >
          {t('saved_cards')}
        </StyledAccountSectionNavButton>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.MARKETING }}
        >
          {t('marketing_preferences')}
        </StyledAccountSectionNavButton>
      </GroupContainer>

      <GroupContainer>
        <GroupHeader>{t('delivery')}</GroupHeader>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.ADDRESSES }}
          dataTestId="address-book"
        >
          {t('address_book')}
        </StyledAccountSectionNavButton>
      </GroupContainer>

      <GroupContainer last>
        <GroupHeader>{t('orders')}</GroupHeader>
        <StyledAccountSectionNavButton
          route={{ mainRouteName: MainRouteName.ORDERS }}
        >
          {t('order_history')}
        </StyledAccountSectionNavButton>
      </GroupContainer>
    </>
  )
}
