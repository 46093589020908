import { lowerCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'

import { OrderAgainButton } from './OrderAgainButton'
import { singleOrderCustomType } from './queries/getSingleOrderCustomType'
import {
  TotalContainer,
  TotalSubContainer,
  ChargesIndividualLarger,
  DiscountIndividualLarger,
} from './SingleOrder.styles'

interface Discount {
  name: string
}

function isDiscount(data: any): data is Discount {
  return typeof data?.name === 'string'
}

export const OrderCost: React.FC<{ myOrder: singleOrderCustomType }> = ({
  myOrder,
}) => {
  const { t } = useTranslation('singleOrder')
  const formatCurrency = useFormatCurrency()
  const {
    outlet,
    subtotalGross,
    customerTaxes,
    fulfillmentCharge,
    partnerCharge,
    discountAmount,
    grossTotal,
    narrowFulfilmentMethod,
    discount,
  } = myOrder

  const marketplace = useMarketplace()
  const marketplaceVATRegistered = marketplace.isVATregistered

  const discountName = isDiscount(discount) ? discount.name : 'Discount'

  return (
    <TotalContainer>
      <ChargesIndividualLarger>
        <dt>{t('original_subtotal')}</dt>
        <dd>{formatCurrency(subtotalGross ?? 0)}</dd>
      </ChargesIndividualLarger>
      {!!fulfillmentCharge && (
        <ChargesIndividualLarger>
          <dt>{t(`${lowerCase(narrowFulfilmentMethod)}_charge`)}</dt>
          <dd>{formatCurrency(fulfillmentCharge)}</dd>
        </ChargesIndividualLarger>
      )}

      {!!partnerCharge && (
        <ChargesIndividualLarger>
          <dt>
            {narrowFulfilmentMethod === 'TABLE'
              ? marketplace.partnerTableChargeDescription || t('table_charge')
              : marketplace.partnerChargeDescription || t('service_fee')}
          </dt>
          <dd>{formatCurrency(partnerCharge)}</dd>
        </ChargesIndividualLarger>
      )}
      {!!discountAmount && (
        <DiscountIndividualLarger>
          <dt>{discountName}</dt>
          <dd>{formatCurrency(discountAmount ?? 0, '-')}</dd>
        </DiscountIndividualLarger>
      )}
      {(marketplaceVATRegistered || outlet.isVATregistered) && (
        <ChargesIndividualLarger>
          <dt>{t('vat')}</dt>
          <dd>({formatCurrency(customerTaxes ?? 0)})</dd>
        </ChargesIndividualLarger>
      )}
      <TotalSubContainer>
        <dt>{t('total')}</dt>
        <dd>{formatCurrency(grossTotal ?? 0)}</dd>
      </TotalSubContainer>

      <OrderAgainButton myOrder={myOrder} />
    </TotalContainer>
  )
}
