import styled, { css } from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  margin: 0px 24px 0 24px;
  align-items: center;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
`

export const Logo = styled.div<{ imageUrl: string }>`
  height: 64px;
  width: 64px;
  background-color: #f6f6f9;
  border-radius: 8px;
  background-size: cover;

  ${({ imageUrl }) =>
    imageUrl &&
    css`
      background-image: url(${imageUrl});
    `};
`

export const Header = styled.h1`
  font-size: 24px;
  font-weight: 700;
`

export const Section = styled.section`
  margin: 28px 24px;
  border-bottom: 2px solid #f6f6f9;

  &:last-child {
    border-bottom: none;
  }
`

export const ShadedSection = styled.section`
  padding: 28px 24px;
  background-color: #f6f6f9;
  margin-top: -30px;
`

export const Table = styled.table`
  width: 100%;
  border: none;
  border-collapse: collapse;
`

export const TableRow = styled.tr`
  border: none;
  :nth-child(even) {
    background-color: white;
  }
`

export const TableHeaderRow = styled.tr`
  // Hide the table headers visually but show them to screen readers
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
`

export const TableData = styled.td`
  border: none;
  padding: 4px 16px;
  font-size: 14px;
  font-weight: 400;
  height: 40px;

  :nth-child(even) {
    text-align: right;
  }
`

export const SectionHeader = styled.h2`
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
`

export const ContentContainer = styled.div`
  margin: 16px 0px;
  margin-bottom: 24px;
`

export const Content = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;
`

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0px;
`

export const StyledLink = styled.a`
  color: black;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  text-underline-offset: 3px;
`
