import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  MenuItemGroupFulfillmentMethod,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { CURRENT_FULFILMENT_TO_NARROW_FULFILMENT } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/types/mapFulfilment'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { NonBasketOutletFulfilment } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/validation'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'

import { Container, Underlay } from './DirtyBasketAlertStyles'
import { menuItemGroups_menuItemGroupsForOutlet_outletMenuItems } from './queries/__generated__/menuItemGroups'
import { menuItemGroupsDocument } from './queries/__generated__/menuItemGroups.graphql-interface'

import { ButtonType } from '../Button'
import {
  HeaderContainer,
  MainContainer,
  StyledButton,
  SubTitle,
  Title,
} from '../Modal/AlertModal.styles'

const FULFILMENT_METHODS_MAP: Record<
  NarrowFulfilmentMethodInputType | 'ALL',
  MenuItemGroupFulfillmentMethod[]
> = {
  ['ALL']: [
    MenuItemGroupFulfillmentMethod.DELIVERY,
    MenuItemGroupFulfillmentMethod.COLLECTION,
    MenuItemGroupFulfillmentMethod.TABLE,
  ],
  [NarrowFulfilmentMethodInputType.DELIVERY]: [
    MenuItemGroupFulfillmentMethod.DELIVERY,
  ],
  [NarrowFulfilmentMethodInputType.COLLECTION]: [
    MenuItemGroupFulfillmentMethod.COLLECTION,
  ],
  [NarrowFulfilmentMethodInputType.TABLE]: [
    MenuItemGroupFulfillmentMethod.TABLE,
  ],
}

const FULFILMENT_TRANSLATION_MAP = {
  ['ALL']: 'items_not_available',
  [NarrowFulfilmentMethodInputType.DELIVERY]:
    'items_not_available_for_delivery',
  [NarrowFulfilmentMethodInputType.COLLECTION]:
    'items_not_available_for_collection',
  [NarrowFulfilmentMethodInputType.TABLE]: 'items_not_available_for_table',
}

export const DirtyBasketAlert: React.FC = () => {
  const {
    outlet: { id: outletId },
    data: {
      currentFulfilment: { narrowType: fulfilmentChosen },
      historicalData: { previousFulfilmentType, ...historicalData },
    },
    setCurrentFulfilment,
  } = useOutletFulfilment({ stateType: OutletFulfilmentStateType.GLOBAL })
  const basketItems = useBasketItems()

  const { t } = useTranslation(['dirtyBasket', 'checkout'])
  const { data, loading, error } = useQuery(
    menuItemGroupsDocument,
    // do not run query if there are no items in basket
    outletId && !!basketItems.items.length
      ? {
          variables: {
            outletId: outletId,
            fulfilmentMethods: FULFILMENT_METHODS_MAP[fulfilmentChosen],
            getUnavailableBySchedule: true,
          },
        }
      : { skip: true }
  )

  if (loading || error || !data) {
    return null
  }

  const outletMenuItems = data.menuItemGroupsForOutlet.reduce<
    menuItemGroups_menuItemGroupsForOutlet_outletMenuItems[]
  >((acc, menuItemGroupForOutlet) => {
    if (
      menuItemGroupForOutlet.outletMenuItems &&
      menuItemGroupForOutlet.outletMenuItems.length
    ) {
      acc = [...acc, ...menuItemGroupForOutlet.outletMenuItems]
    }
    return acc
  }, [])

  // check items in basket are available for the current fulfillment
  const dirtyItems = basketItems.items.filter(item => {
    const itemAvailable =
      item.price ||
      outletMenuItems.find(
        menuItem => item.menuItemId === menuItem.menuItemId
      ) ||
      basketItems.addOnItems.find(
        addOnItem => item.menuItemId === addOnItem.menuItemId
      )
    return (
      !itemAvailable ||
      item.availability.isUnavailableForTime ||
      item.availability.menuItem?.isMenuItemUnavailable ||
      item.availability.optionItem?.isOptionItemUnavailable
    )
  })
  const canBeResolvedBySwitchingFulfilment = !dirtyItems.some(
    ({ availability }) =>
      availability.isUnavailableForTime ||
      availability.menuItem?.isMenuItemUnavailable ||
      availability.optionItem?.isOptionItemUnavailable
  )

  return dirtyItems.length > 0 ? (
    <>
      <Underlay />
      <Container>
        <HeaderContainer $backgroundColor="white">
          <Title>
            {t(
              FULFILMENT_TRANSLATION_MAP[
                (canBeResolvedBySwitchingFulfilment && fulfilmentChosen) ||
                  'ALL'
              ]
            )}
          </Title>
          <SubTitle>
            <ul>
              {dirtyItems.map(dirtyItem => (
                <li key={dirtyItem.id}>{dirtyItem.name}</li>
              ))}
            </ul>
          </SubTitle>
        </HeaderContainer>
        <MainContainer isTallContainer={false}>
          <StyledButton
            content={t('remove_unavailable_items')}
            buttonType={ButtonType.DANGER}
            onClick={() => {
              const idsToRemove = [
                ...dirtyItems.map(item => item.id),
                // Also remove addon items as totals will be different
                ...basketItems.addOnItems.map(item => item.id),
              ]

              basketItems.removeItems({
                basketItemIds: idsToRemove,
              })
            }}
          />
          {canBeResolvedBySwitchingFulfilment && previousFulfilmentType && (
            <StyledButton
              content={t('switch_back_to_fulfilment', {
                fulfilment: t(
                  CURRENT_FULFILMENT_TO_NARROW_FULFILMENT[
                    previousFulfilmentType
                  ].toLowerCase(),
                  { ns: 'checkout' }
                ),
              })}
              onClick={() => {
                setCurrentFulfilment({
                  type: previousFulfilmentType,
                  ...historicalData,
                } as NonBasketOutletFulfilment['currentFulfilment'])
              }}
            />
          )}
        </MainContainer>
      </Container>
    </>
  ) : null
}
