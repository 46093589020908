import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'

export const CategorySlideContent = styled.div`
  background: ${({ theme }) => theme.colors.brandForeground};
  border: ${({ theme }) => theme.colors.grey};
  border-radius: 26px;
  width: 44px;
  height: 44px;
  margin: 0 8px 4px 8px;
  overflow: hidden;
  border: 2px solid transparent;
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.brand};
    transition: border-color 0.3s ease;
  }
  @media (min-width: ${breakpoints.tablet}px) {
    border-radius: 34px;
    width: 64px;
    height: 64px;
    margin: 0 16px 8px 16px;
  }
`

export const CategorySlideImage = styled.div`
  font-size: 32px;
  text-align: center;
  img {
    width: 44px;
    width: 44px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 48px;
    img {
      width: 64px;
      width: 64px;
    }
  }
`

export const CategorySlideTitle = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.mainText};

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 14px;
  }
`

export const SegmentCategoriesContainer = styled.div`
  overflow-y: hidden;
  margin: 0 0 0 12px;

  .swiper {
    @media (min-width: ${breakpoints.tablet}px) {
      padding-right: 48px;
    }
  }
`
