import React from 'react'
import { useTranslation } from 'react-i18next'

import { useOutletFulfilment } from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { TreeOutletMenuItem } from '@src/pages/OutletPage/menuItemGroupTreeType'

export function getHasReachedMaxPurchaseQuantity(
  outletMenuItem: TreeOutletMenuItem,
  basketItems: ReturnType<typeof useBasketItems>,
  extraToAddOn = 0
) {
  if (outletMenuItem.maxPurchaseQuantity === null) return false // don't need to worry if there's no maximum
  const matchingBasketItems = basketItems.items.filter(
    x => x.outletMenuItemId === outletMenuItem.id
  )
  if (!matchingBasketItems) return false // if we don't have a matching item we have bigger problems than the max purchase quantity, and should be handled elsewhere
  const totalMatchingQuantity = matchingBasketItems.reduce(
    (totalSoFar, item) => totalSoFar + item.quantity,
    0
  )
  return (
    totalMatchingQuantity + extraToAddOn >= outletMenuItem.maxPurchaseQuantity
  )
}

interface AddToBasketButtonContentArgs {
  hasReachedMaxPurchaseQuantity: boolean
  outletFulfilment: ReturnType<typeof useOutletFulfilment>
  outletMenuItem: TreeOutletMenuItem
  totalPrice: number
}

export const AddToBasketButtonContent = ({
  hasReachedMaxPurchaseQuantity,
  outletFulfilment,
  outletMenuItem,
  totalPrice,
}: AddToBasketButtonContentArgs) => {
  const { t } = useTranslation('menuItem')
  const formatCurrency = useFormatCurrency()

  if (outletMenuItem.soldOut) {
    return <p> {t('sold_out')}</p>
  }

  if (hasReachedMaxPurchaseQuantity)
    return <p> {t('reached_max_purchase_quantity')}</p>
  else
    return (
      <>
        <p>
          {outletFulfilment.outlet.isOrderable
            ? t('add_to_basket')
            : t('unavailable')}
        </p>
        <p>{formatCurrency(totalPrice)}</p>
      </>
    )
}
