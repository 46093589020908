import React from 'react'
import { useTranslation } from 'react-i18next'

export const BasketSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <title id={`${id}Title`}>{title || t('basket_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <g strokeLinejoin="miter" fill="currentColor" strokeLinecap="butt">
        <line
          x1="8"
          y1="13"
          x2="8"
          y2="17"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          data-color="color-2"
        ></line>
        <line
          x1="12"
          y1="13"
          x2="12"
          y2="17"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          data-color="color-2"
        ></line>
        <line
          x1="16"
          y1="13"
          x2="16"
          y2="17"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
          data-color="color-2"
        ></line>
        <path
          d="m3.51,12h-.01l.802,7.221c.113,1.013.969,1.779,1.988,1.779h11.42c1.019,0,1.875-.766,1.988-1.779l.802-7.221h-.01"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></path>
        <line
          x1="1"
          y1="8"
          x2="23"
          y2="8"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></line>
        <polyline
          points="8.75 1.5 5 8 6 8"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></polyline>
        <polyline
          points="15.25 1.5 19 8 18 8"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeMiterlimit="10"
          strokeWidth="2"
        ></polyline>
      </g>
    </svg>
  )
}
