import React from 'react'
import { useTranslation } from 'react-i18next'

import { effectiveFulfillmentMethod } from '@src/components/SingleOutlet/utils/effectiveFulfilmentMethod'
import { TextClamp } from '@src/components/Text/Clamp'
import { NarrowFulfilmentMethodInputType } from '@src/graphql-types'
import { useBasketItems } from '@src/hooks/useBasketItems/useBasketItems'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import {
  MenuItemCounter,
  MenuItemCounterText,
} from '@src/pages/OutletPage/OutletMenu/MenuItem/MenuItem.styles'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  OutletDetailsContainer,
  OutletName,
  CuisinesContainer,
  CuisineTag,
  Cuisine,
  OutletOpeningContainer,
  OpenStatusContainer,
  LongSquareButton,
  RestaurantLogo,
  CuisineDot,
  RestaurantContainer,
  OrderInfoContainer,
  StyledDiscountSVG,
  DiscountText,
  DiscountTextContainer,
  OfferContainer,
  OfferName,
  LinkToOutletContainer,
  OutletNameContainer,
  StyledStarSVG,
  StyledCartSVG,
  StyledClockSVG,
  StyledDistanceSVG,
} from './OutletCard.styles'
import { StyledDiscountManySVG } from './SingleOutlet.styles'
import { SingleOutlet, SingleOutletDirection } from './types'

// We have to type this until we change to pass in just outlet id and we fetch what we need ourselves.
import { OutletStatusTranslationText } from '../OutletStatus/OutletStatusTranslationText'

export const OutletCard: React.FC<{
  outlet: Pick<
    SingleOutlet,
    | 'id'
    | 'restaurant'
    | 'availableFulfilmentInputMethods'
    | 'description'
    | 'deliveryMinimumOrderValue'
    | 'collectionMinimumOrderValue'
    | 'isOnline'
    | 'isOpen'
    | 'distanceFromUserKM'
    | 'ASAPDeliveryDuration'
    | 'prepTime'
    | 'statusText'
    | 'daysOfferedInAdvanceMin'
    | 'nextOpenDate'
    | 'outletLogoOverride'
    | 'availableFulfillmentMethods'
    | 'displayName'
    | 'fulfilmentRange'
    | 'outletAllDiscounts'
    | 'specialOfferFlags'
    | 'outletCuisines'
  >
  direction?: SingleOutletDirection
  className?: string
  isSlide?: boolean
  dataTestId?: string
}> = ({
  outlet,
  direction = SingleOutletDirection.COLUMN,
  className,
  isSlide = false,
  dataTestId,
}) => {
  const { t } = useTranslation(['outlet', 'outletCard'])
  const basketItems = useBasketItems()
  const {
    data: { priorityFulfilmentMethod },
  } = useFulfilmentFilter()

  const effectiveFulfilmentMethod = effectiveFulfillmentMethod(
    outlet.availableFulfilmentInputMethods,
    priorityFulfilmentMethod
  )

  const outletDiscounts = outlet.outletAllDiscounts ?? []
  const formatCurrency = useFormatCurrency(false)

  const outletWithBasketItems = basketItems.items.some(
    basket => basket.outletMenuItemId.split(':')[0] === outlet.id
  )

  const basketItemCount =
    outletWithBasketItems &&
    basketItems.items.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)

  const fulfilmentToDealMap: Record<
    NarrowFulfilmentMethodInputType,
    string | undefined | null
  > = {
    [NarrowFulfilmentMethodInputType.COLLECTION]:
      outlet.specialOfferFlags?.collectionDeal,
    [NarrowFulfilmentMethodInputType.DELIVERY]:
      outlet.specialOfferFlags?.deliveryDeal,
    [NarrowFulfilmentMethodInputType.TABLE]:
      outlet.specialOfferFlags?.tableDeal,
  }
  const deal = fulfilmentToDealMap[effectiveFulfilmentMethod]

  return (
    <LinkToOutletContainer
      outletId={outlet.id}
      name={outlet.displayName}
      className={className}
      status={outlet.statusText.orderButtonTranslation}
      dataTestId={dataTestId}
    >
      <RestaurantContainer
        hasOffer={isSlide || !!deal}
        direction={direction}
        isSlide={isSlide}
      >
        <RestaurantLogo
          role="img"
          aria-label={`${outlet.displayName} logo`}
          imageUrl={imageJitURL(
            outlet.outletLogoOverride || outlet.restaurant.image,
            {
              resize: {
                width: 88,
                height: 88,
                fit: 'cover',
              },
            }
          )}
          isSlide={isSlide}
        >
          {outletWithBasketItems && basketItemCount && (
            <MenuItemCounter isMobile={true} isOutletCard={true}>
              <MenuItemCounterText>{basketItemCount}</MenuItemCounterText>
            </MenuItemCounter>
          )}
        </RestaurantLogo>
        <OutletDetailsContainer isSlide={isSlide}>
          <OfferContainer visible={Boolean(deal)}>
            <StyledStarSVG />
            <OfferName>
              <TextClamp clamp={2}>{deal}</TextClamp>
            </OfferName>
          </OfferContainer>
          <OutletNameContainer>
            <TextClamp tooltipText={outlet.displayName}>
              <OutletName>{outlet.displayName}</OutletName>
            </TextClamp>
          </OutletNameContainer>
          <CuisinesContainer>
            {outlet.outletCuisines
              .slice(0, 3)
              .map((cuisine: { name: string }, index: number) => {
                return (
                  <CuisineTag key={index}>
                    {index !== 0 ? <CuisineDot /> : null}

                    <Cuisine>{cuisine.name}</Cuisine>
                  </CuisineTag>
                )
              })}
          </CuisinesContainer>
          {/* 
          This has been removed by request of the product team, but it may be useful in future.
          {outlet.description && (
            <Description>
              <TextClamp clamp={2} tooltipText={outlet.description}>
                <span>{outlet.description}</span>
              </TextClamp>
            </Description>
          )} */}

          {effectiveFulfilmentMethod !==
            NarrowFulfilmentMethodInputType.TABLE &&
            outletDiscounts.length > 0 && (
              <DiscountTextContainer>
                {outletDiscounts.length > 1 ? (
                  <DiscountText>
                    <StyledDiscountManySVG id="svg-x-discounts" />
                    {t('x_offers_available', {
                      discountCount: outletDiscounts.length,
                      ns: 'outletCard',
                    })}
                  </DiscountText>
                ) : (
                  outletDiscounts?.map(discount => (
                    <DiscountText key={discount.id}>
                      <StyledDiscountSVG id={discount.id} />
                      <TextClamp tooltipText={discount.name}>
                        {discount.name}
                      </TextClamp>
                    </DiscountText>
                  ))
                )}
              </DiscountTextContainer>
            )}
          <OutletOpeningContainer>
            <OpenStatusContainer>
              <OutletStatusTranslationText
                statusText={outlet.statusText}
                isOutletPage={false}
              />

              <OrderInfoContainer>
                {effectiveFulfilmentMethod ===
                NarrowFulfilmentMethodInputType.DELIVERY ? (
                  <>
                    {outlet.fulfilmentRange && (
                      <span>
                        <StyledClockSVG />
                        {outlet.fulfilmentRange}
                      </span>
                    )}
                    {outlet.deliveryMinimumOrderValue ? (
                      <span>
                        <StyledCartSVG id="min-order-val" />
                        {formatCurrency(outlet.deliveryMinimumOrderValue)}{' '}
                        {t('minimum_order', { ns: 'outletCard' })}
                      </span>
                    ) : undefined}
                  </>
                ) : undefined}
                {effectiveFulfilmentMethod ===
                NarrowFulfilmentMethodInputType.COLLECTION ? (
                  <>
                    {outlet.fulfilmentRange && (
                      <span>
                        <StyledDistanceSVG />
                        {outlet.fulfilmentRange}
                      </span>
                    )}
                    {outlet.collectionMinimumOrderValue ? (
                      <span>
                        <StyledCartSVG id="min-order-val" />
                        {formatCurrency(
                          outlet.collectionMinimumOrderValue
                        )}{' '}
                        {t('minimum_order', { ns: 'outletCard' })}
                      </span>
                    ) : undefined}{' '}
                  </>
                ) : undefined}
              </OrderInfoContainer>
            </OpenStatusContainer>
            <LongSquareButton
              status={outlet.statusText.orderButtonTranslation}
              content={t(outlet.statusText.orderButtonTranslation, {
                ns: 'outlet',
              })}
            />
          </OutletOpeningContainer>
        </OutletDetailsContainer>
      </RestaurantContainer>
    </LinkToOutletContainer>
  )
}
