import styled, { css } from 'styled-components'

import { BasketSVG } from '@src/components/SVGS/BasketSVG'

export const Container = styled.button<{
  buttonStyle: 'icon' | 'total'
  isClickable?: boolean
}>`
  padding: 0;
  margin: 0 0 0 8px;
  height: 48px;
  position: relative;
  border: none;
  background: none;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ theme, buttonStyle, isClickable }) => {
    // TODO lol it changes the whole thing on one prop
    if (buttonStyle === 'icon') {
      return css`
        width: 48px;
        border-radius: 100%;
        color: #fff;
        background-color: ${theme.colors.brand};
        transition: 0.3s ease;
        transition-property: color, background-color, border-color;

        ${isClickable
          ? css`
              &:hover:enabled {
                background-color: #fff;
                color: ${theme.colors.brand};
                border-color: #fff;
              }
            `
          : ''}
      `
    }

    return css`
      // TODO whats this 0px border doin?
      border: 0px solid #fff;
      border-radius: 28px;
      padding: 0px 12px 0 14px;
      font-weight: 700;
      font-size: 16px;
      color: ${theme.colors.brandForeground};
      background-color: ${theme.colors.brand};
      transition: 0.3s ease;
      transition-property: color, background-color, border-color;

      ${isClickable
        ? css`
            &:hover:enabled {
              background-color: #fff;
              color: ${theme.colors.brand};
              border-color: #fff;
            }
          `
        : ''}

      &:disabled {
        cursor: default;
      }
    `
  }}
`

Container.defaultProps = {
  isClickable: true,
}

export const TotalText = styled.p`
  margin-left: 12px;
`

export const StyledBasketSVG = styled(BasketSVG)`
  width: 24px;
  height: 24px;
`
