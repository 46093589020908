import { useField } from 'formik'
import { toUpper } from 'lodash'
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  InputContainer,
  Label,
  Required,
  Input,
  StyledEyeIcon,
  TogglePasswordContainer,
  Container,
  Error,
  TextArea,
  ClearButton,
  ClearButtonIcon,
} from './TextInput.styles'

import { InputWrapper } from '../InputWrapper'

export const TextInput: React.FC<{
  label: string
  name: string
  placeholder?: string
  borderless?: boolean
  type?: 'text' | 'password' | 'phone-number'
  required?: boolean
  autoComplete?: string
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  disabled?: boolean
  error?: string
  focusBorder?: boolean
  focusOnLoad?: boolean
  maxLength?: number
  isTextArea?: boolean
  fullWidth?: boolean
  toUpperCase?: boolean
  dataTestId?: string
}> = ({
  label,
  name,
  placeholder,
  borderless = false,
  type = 'text',
  required = false,
  disabled,
  onKeyDown,
  focusBorder = true,
  focusOnLoad,
  maxLength,
  isTextArea = false,
  fullWidth = false,
  toUpperCase = false,
  error,
  autoComplete,
  dataTestId,
}) => {
  const [field, meta, { setValue }] = useField<string>({
    type: type === 'phone-number' ? 'text' : type,
    name,
  })

  return (
    <InputWrapper meta={meta} disabled={disabled} fullWidth={fullWidth}>
      <UncontrolledTextInput
        label={label}
        placeholder={placeholder}
        value={field.value}
        disabled={disabled}
        onChange={e => {
          if (type === 'phone-number') {
            const re = /^[0-9+\s()]+$/

            if (e.target.value === '' || re.test(e.target.value)) {
              setValue(e.target.value)
            }
          } else
            setValue(toUpperCase ? toUpper(e.target.value) : e.target.value)
        }}
        borderless={borderless}
        focusBorder={focusBorder}
        focusOnLoad={focusOnLoad}
        error={error}
        onKeyDown={onKeyDown}
        name={name}
        required={required}
        type={type}
        touched={meta.touched}
        maxLength={maxLength}
        isTextArea={isTextArea}
        autoComplete={autoComplete}
        dataTestId={dataTestId}
      />
    </InputWrapper>
  )
}

export const UncontrolledTextInput: React.FC<{
  label: React.ReactNode
  value?: string
  placeholder?: string
  disabled?: boolean
  touched: boolean
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
  focusBorder?: boolean
  focusOnLoad?: boolean
  borderless?: boolean
  name: string
  required: boolean
  type?: 'text' | 'password' | 'phone-number'
  onClear?: () => void
  error?: string
  maxLength?: number
  isTextArea?: boolean
  toUpperCase?: boolean
  autoComplete?: string
  dataTestId?: string
}> = ({
  label,
  value,
  placeholder,
  disabled = false,
  touched,
  onChange,
  borderless = false,
  focusBorder = true,
  focusOnLoad,
  name,
  required = false,
  type = 'text',
  onClear,
  error,
  maxLength,
  isTextArea = false,
  toUpperCase = false,
  autoComplete,
  onKeyDown,
  dataTestId,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [showPassword, setShowPassword] = useState(false)
  const isPassword = type === 'password'
  const passwordToggle = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    setShowPassword(!showPassword)
  }

  const getInputType = () => {
    if (isPassword && !showPassword) {
      return 'password'
    } else if (isPassword && showPassword) {
      return 'text'
    } else {
      return type
    }
  }

  useEffect(() => {
    if (focusOnLoad && !disabled) {
      // Delay the focus on text input as autoFocus glitches mobile screens
      setTimeout(() => {
        if (inputRef.current) inputRef.current.focus()
      }, 500)
    }
  }, [])

  return (
    <Container>
      <InputContainer
        errorBorder={!!error && touched}
        borderless={borderless}
        focusBorder={focusBorder}
        disabled={disabled}
        isTextArea={isTextArea}
        data-test-id={dataTestId}
      >
        <Label htmlFor={name} $isPlaceHolder={!value}>
          {label}

          {required && <Required>*</Required>}
        </Label>
        {isTextArea ? (
          <TextArea
            id={name}
            name={name}
            visible={true}
            onKeyDown={onKeyDown}
            onChange={onChange}
            disabled={disabled}
            maxLength={maxLength}
            value={toUpperCase ? toUpper(value) : value}
          >
            {placeholder}
          </TextArea>
        ) : (
          <>
            <Input
              id={name}
              name={name}
              ref={inputRef}
              type={getInputType()}
              onKeyDown={onKeyDown}
              visible={!!value}
              onChange={onChange}
              disabled={disabled}
              maxLength={maxLength}
              value={toUpperCase ? toUpper(value) : value}
              placeholder={placeholder}
              isTextArea={isTextArea}
              autoComplete={autoComplete || 'off'}
            />
            {onClear && (
              <ClearButton
                onClick={onClear}
                disabled={disabled || !value || !inputRef.current}
              >
                <ClearButtonIcon id={name + 'ClearButton'} />
              </ClearButton>
            )}
            {isPassword && (
              <TogglePasswordContainer
                onClick={e => passwordToggle(e)}
                type="button"
              >
                <StyledEyeIcon isActive={showPassword} id="password" />
              </TogglePasswordContainer>
            )}
          </>
        )}
      </InputContainer>
      {touched && error && <Error>{error}</Error>}
    </Container>
  )
}
