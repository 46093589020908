import React from 'react'
import { useTranslation } from 'react-i18next'

export const CouponManySVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      height="18"
      width="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={`${id}Title`}>{title || t('coupon_alt')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <path d="M1.75,4.25H7.336c.265,0,.52,.105,.707,.293l5.793,5.793c.781,.781,.781,2.047,0,2.828l-3.172,3.172c-.781,.781-2.047,.781-2.828,0L2.043,10.543c-.188-.188-.293-.442-.293-.707V4.25Z"></path>
        <path
          d="M3.25,1.75v-.5h5.586c.265,0,.52,.105,.707,.293l5.793,5.793c.432,.432,.625,1.012,.579,1.577"
          data-color="color-2"
        ></path>
        <circle
          cx="5.25"
          cy="7.75"
          r="1.25"
          fill="currentColor"
          data-color="color-2"
          data-stroke="none"
          stroke="none"
        ></circle>
      </g>
    </svg>
  )
}
