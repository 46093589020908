import styled from 'styled-components'

import { breakpoints } from '@src/constants/breakpoints'
import { Header2 } from '@src/pages/global.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  text-align: center;

  @media (min-width: ${breakpoints.desktop}px) {
    width: 50%;
  }
`

export const Header1 = styled.h1`
  font-size: 32px;
  font-weight: 700;
  padding: 35px 0px 15px 0px;
  margin: 0px;
  color: #fff;
  line-height: 1.2em;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 48px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 64px;
  }
`

export const HeroSubText = styled(Header2)`
  color: #fff;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 32px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    font-size: 32px;
    line-height: 38px;
  }
`
