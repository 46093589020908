import { useApolloClient, useReactiveVar } from '@apollo/client'
import { useEffect } from 'react'
import {
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'

import { breakpoints } from '@src/constants/breakpoints'
import { screenResolutionVar } from '@src/models/screenResolution'
import { mapEnumToStrings } from '@src/utils/mapEnumToStrings'

import { getNextCheckoutRoute } from './getNextCheckoutRoute'
import { useVerifyPaymentRedirect } from './useVerifyPaymentRedirect'

// some checkout stages have additional query params
type CheckoutRouterQueryParams =
  | {
      route:
        | Exclude<CheckoutRoute, CheckoutRoute.PAYMENT | CheckoutRoute.COMPLETE>
        | undefined
      orderId: null
      errorMessage: null
    }
  | {
      route: CheckoutRoute.COMPLETE
      orderId: string
      errorMessage: null
    }
  | {
      route: CheckoutRoute.PAYMENT
      orderId: null
      errorMessage: string | null
    }

type CheckoutRouterReturnType = CheckoutRouterQueryParams & {
  defaultedRoute: CheckoutRoute
  displayPermanentCheckout: boolean
  next: () => Promise<void>
  prev: () => void
  complete: (orderId: string) => void
  override: (route: CheckoutRoute) => void
  setError: (errorMessage: string) => void
  resetError: () => void
  reset: () => void
}

// Hook for managing the checkout router state and exposing useful, typesafe methods for navigating between checkout stages
export const useCheckoutRouter = (): CheckoutRouterReturnType => {
  const [params, setParams] = useQueryParams({
    checkoutRoute: withDefault(checkoutRouteParam, undefined),
    orderId: StringParam,
    errorMessage: StringParam,
  })
  const apolloClient = useApolloClient()

  const { width } = useReactiveVar(screenResolutionVar)
  const displayPermanentCheckout = width >= breakpoints.wideDesktop

  // bad url params can cause the checkout to get stuck
  useEffect(() => {
    if (params.checkoutRoute === CheckoutRoute.COMPLETE && !params.orderId) {
      setParams({ checkoutRoute: undefined }, 'replaceIn')
    }
  }, [params, setParams])

  // if there is a orderId then we need to verify the payment was successful
  // and either redirect back to the payment route
  // or to the completed route
  useVerifyPaymentRedirect({
    orderId:
      (params.checkoutRoute === CheckoutRoute.PAYMENT_REDIRECT_VERIFICATION &&
        params.orderId) ||
      null,
    redirectBackToPaymentRoute: (errorMessage: string) => {
      setParams(
        {
          checkoutRoute: CheckoutRoute.PAYMENT,
          errorMessage: errorMessage && window.btoa(errorMessage),
        },
        'replaceIn'
      )
    },
    redirectToCompleteRoute: () => {
      setParams({ checkoutRoute: CheckoutRoute.COMPLETE }, 'replaceIn')
    },
  })

  return {
    route: params.checkoutRoute,
    displayPermanentCheckout: displayPermanentCheckout,
    defaultedRoute: params.checkoutRoute || CheckoutRoute.BASKET,
    orderId: null,
    errorMessage: null,
    ...(params.checkoutRoute === CheckoutRoute.COMPLETE && {
      route: CheckoutRoute.COMPLETE,
      orderId: params.orderId as string,
    }),
    ...(params.checkoutRoute === CheckoutRoute.PAYMENT && {
      route: CheckoutRoute.PAYMENT,
      errorMessage: params.errorMessage && window.atob(params.errorMessage),
    }),
    next: async () => {
      const nextCheckoutRoute = await getNextCheckoutRoute({
        currentRoute: params.checkoutRoute,
        apolloClient,
      })

      if (nextCheckoutRoute) {
        setParams({ checkoutRoute: nextCheckoutRoute })
      }
    },
    prev: () => {
      const previousCheckoutRoute = params.checkoutRoute
        ? previousCheckoutRouteMap[params.checkoutRoute]
        : undefined
      setParams({
        checkoutRoute: previousCheckoutRoute,
        errorMessage: undefined,
      })
    },
    complete: (orderId: string) => {
      setParams({ checkoutRoute: CheckoutRoute.COMPLETE, orderId })
    },
    override: (newRoute: CheckoutRoute) => {
      setParams({ checkoutRoute: newRoute })
    },
    reset: () => {
      setParams({
        checkoutRoute: undefined,
        orderId: undefined,
        errorMessage: undefined,
      })
    },
    resetError: () => {
      setParams({
        errorMessage: undefined,
      })
    },
    setError: (errorMessage: string) => {
      if (params.checkoutRoute === CheckoutRoute.PAYMENT) {
        setParams({
          errorMessage: window.btoa(errorMessage),
        })
      }
    },
  } as CheckoutRouterReturnType
  // TODO: remove this^ hack
}

export enum CheckoutRoute {
  BASKET = 'basket',
  FULFILMENT = 'fulfilment',
  OFFERS = 'offers',
  PAYMENT = 'payment',
  COMPLETE = 'complete',
  PAYMENT_REDIRECT_VERIFICATION = 'redirect-verification',
}

const previousCheckoutRouteMap: Record<
  CheckoutRoute,
  CheckoutRoute | undefined
> = {
  [CheckoutRoute.FULFILMENT]: CheckoutRoute.BASKET,
  [CheckoutRoute.OFFERS]: CheckoutRoute.FULFILMENT,
  [CheckoutRoute.PAYMENT]: CheckoutRoute.FULFILMENT,
  [CheckoutRoute.BASKET]: undefined,
  [CheckoutRoute.COMPLETE]: undefined,
  [CheckoutRoute.PAYMENT_REDIRECT_VERIFICATION]: undefined,
}

const checkoutRouteParam = createEnumParam(
  mapEnumToStrings(CheckoutRoute) as CheckoutRoute[]
)
