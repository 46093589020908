import React from 'react'
import { useTranslation } from 'react-i18next'

import { FulfilmentWhenLabel } from '@src/components/FulfilmentWhenLabel/FulfilmentWhenLabel'
import { FulfilmentWhereLabel } from '@src/components/FulfilmentWhereLabel/FulfilmentWhereLabel'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useServiceComponentModal } from '@src/hooks/useServiceComponentModal/useServiceComponentModal'
import { BagDeliverySVG } from '@src/images/BagDelivery'

import {
  FulfilmentContainer,
  FulfilmentDetailsContainer,
  PaddedDotSpan,
} from './FulfilmentTabStyles'
import { StyledDoubleArrowSVG } from './OutletHeaderMobileStyles'

export enum TabString {
  SERVICE = 'SERVICE',
  WHEN = 'WHEN',
  LOCATION = 'LOCATION',
  WHERE = 'WHERE',
}

export const HeaderTab: React.FC<{
  fulfilmentTabs: TabString[]
  permanentCheckout?: boolean
  dataTestId?: string
}> = ({ fulfilmentTabs, permanentCheckout = false, dataTestId }) => {
  const { openServiceComponentModal } = useServiceComponentModal()
  const { t } = useTranslation('outletHeader')
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const selectedNarrowFulfilment =
    outletFulfilment.data.currentFulfilment.narrowType

  const handleClick = () => {
    openServiceComponentModal()
  }

  return (
    <FulfilmentContainer onClick={handleClick} data-test-id={dataTestId}>
      <BagDeliverySVG id="service" />
      <FulfilmentDetailsContainer permanentCheckout={permanentCheckout}>
        {fulfilmentTabs?.includes(TabString.SERVICE) && (
          <div>{t(selectedNarrowFulfilment.toLowerCase())}</div>
        )}
        {fulfilmentTabs?.includes(TabString.WHEN) && (
          <>
            <PaddedDotSpan>{' • '}</PaddedDotSpan>
            <FulfilmentWhenLabel stateType={OutletFulfilmentStateType.GLOBAL} />
          </>
        )}

        {(fulfilmentTabs?.includes(TabString.LOCATION) ||
          fulfilmentTabs?.includes(TabString.WHERE)) && (
          <>
            <PaddedDotSpan>{' • '}</PaddedDotSpan>
            <FulfilmentWhereLabel
              stateType={OutletFulfilmentStateType.GLOBAL}
            />
          </>
        )}
      </FulfilmentDetailsContainer>
      <StyledDoubleArrowSVG id="fulfilmentArrow" />
    </FulfilmentContainer>
  )
}
