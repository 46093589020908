import React from 'react'

import { Tooltip } from '@src/components/Tooltip/Tooltip'

import { StyledBottomSheet } from './BottomSheetWrapperStyles'

import 'react-spring-bottom-sheet/dist/style.css'

export const BottomSheetWrapper: React.FC<{
  children: React.ReactNode
  open: boolean
  onClose: () => void
  headerColor?: string
  height?: number
  maxWidth?: number
  backgroundColor?: string
}> = ({
  children,
  open,
  onClose,
  headerColor,
  height = 0.89,
  maxWidth,
  backgroundColor,
}) => {
  return (
    <StyledBottomSheet
      open={open}
      onDismiss={onClose}
      snapPoints={({ maxHeight }) => maxHeight * height}
      $headerColor={headerColor}
      $maxWidth={maxWidth}
      $backgroundColor={backgroundColor}
      $isOpen={open}
      sibling={
        <div
          data-rsbs-backdrop="true"
          role="presentation"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            onClose()
          }}
        />
      }
      blocking={false}
    >
      <Tooltip id="bottom-sheet-wrapper-tooltip" />
      {children}
    </StyledBottomSheet>
  )
}
