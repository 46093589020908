import React from 'react'

export const TwitterXSVG: React.FC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      height="16"
      width="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="currentColor"
        data-color="color-2"
        d="M7.23 10.1l-4.29 4.9h-2.38l5.56-6.35 1.11 1.45z"
      ></path>
      <path
        fill="currentColor"
        data-color="color-2"
        d="M8.53 5.49l3.92-4.49h2.38l-5.2 5.95-1.1-1.46z"
      ></path>
      <path
        d="M15.74 15h-4.78l-10.7-14h4.9l10.58 14z m-4.12-1.42h1.32l-8.5-11.23h-1.41l8.59 11.23z"
        fill="currentColor"
      ></path>
    </svg>
  )
}
