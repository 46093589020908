import { basketItemsVar } from '../utils/reactiveVar'
import { HookMethodArgs } from '../utils/types'

export const removeItem =
  ({ updateBasketOutletId, existingItems, navigateToBasket }: HookMethodArgs) =>
  ({
    basketItemId,
    shouldNavigateToBasket = true,
  }: {
    basketItemId: string
    shouldNavigateToBasket?: boolean
  }): void => {
    if (shouldNavigateToBasket) {
      // navigate back to the basket
      navigateToBasket()
    }

    const updatedBasketItems = existingItems.filter(
      basketItem => basketItem.id !== basketItemId
    )

    basketItemsVar(updatedBasketItems)
    if (updatedBasketItems.length === 0) {
      updateBasketOutletId(null)
    }
  }
