import React from 'react'
import ReactDOMServer from 'react-dom/server'

export const ItemAddSVG: React.VFC<{
  id: string
  title?: string
  ignoreTitle?: boolean
}> = ({ title, ignoreTitle = false }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      {!ignoreTitle && <title>{title}</title>}
      <g fill="currentColor">
        <path
          d="M9,1C4.589,1,1,4.589,1,9s3.589,8,8,8,8-3.589,8-8S13.411,1,9,1Zm3.25,8.75h-2.5v2.5c0,.414-.336,.75-.75,.75s-.75-.336-.75-.75v-2.5h-2.5c-.414,0-.75-.336-.75-.75s.336-.75,.75-.75h2.5v-2.5c0-.414,.336-.75,.75-.75s.75,.336,.75,.75v2.5h2.5c.414,0,.75,.336,.75,.75s-.336,.75-.75,.75Z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  )
}
// Static version of your SVG for use in CSS
const staticSVG = ReactDOMServer.renderToString(<ItemAddSVG id="static" />)

// Template literal for use as a data URL in CSS
export const plusSVGDataURL = `url("data:image/svg+xml;utf8,${encodeURIComponent(
  staticSVG
)}")`
