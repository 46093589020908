import React from 'react'
import { useTranslation } from 'react-i18next'

export const MenuSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
  dataTestId?: string
}> = ({ id, className, title, desc, dataTestId }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      width="24"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-test-id={dataTestId}
    >
      <title id={`${id}Title`}>{title || t('menu_alt')}</title>

      {desc && <desc id={`${id}Desc`}>{desc}</desc>}

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1C24 1.55228 23.5523 2 23 2H1C0.447716 2 0 1.55228 0 1ZM0 7C0 6.44772 0.447715 6 1 6H23C23.5523 6 24 6.44772 24 7C24 7.55228 23.5523 8 23 8H1C0.447716 8 0 7.55228 0 7ZM1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447716 14 1 14H23C23.5523 14 24 13.5523 24 13C24 12.4477 23.5523 12 23 12H1Z"
        fill="currentColor"
      />
    </svg>
  )
}
