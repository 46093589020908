import styled from 'styled-components'

import { ExternalLinkSVG } from '@src/components/SVGS/ExternalLinkSVG'
import { breakpoints } from '@src/constants/breakpoints'

export const StyledAContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
  height: 48px;
  margin-right: 32px;

  @media (min-width: ${breakpoints.desktop}px) {
    height: 48px;
  }
`

export const LinkText = styled.a`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  width: fit-content;
`

export const ExternalLinkSVGStyled = styled(ExternalLinkSVG)`
  height: 14px;
  display: inline-block;
  margin-bottom: -1px;
  margin-left: 4px;
`
