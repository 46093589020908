import React from 'react'
import { useTranslation } from 'react-i18next'

export const ExternalLinkSVG: React.VFC<{
  id: string
  className?: string
  title?: string
  desc?: string
}> = ({ id, className, title, desc }) => {
  const { t } = useTranslation(['svgs'])

  return (
    <svg
      id={id}
      className={className}
      aria-labelledby={`${id}Title ${id}Desc`}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <title id={`${id}Title`}>{title || t('external_link')}</title>
      {desc && <desc id={`${id}Desc`}>{desc}</desc>}
      <g
        strokeLinecap="round"
        strokeWidth="2"
        fill="none"
        stroke="#000000"
        strokeLinejoin="round"
        className="nc-icon-wrapper"
      >
        <line
          data-cap="butt"
          x1="11"
          y1="13"
          x2="22"
          y2="2"
          stroke="#000000"
        ></line>
        <polyline points="14 2 22 2 22 10" stroke="#000000"></polyline>
        <path d="M9,4H4A2,2,0,0,0,2,6V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V15"></path>
      </g>
    </svg>
  )
}
