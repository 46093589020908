import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type GetOutletMenuQueryVariables = Types.Exact<{
  outletId: Types.Scalars['ID']
  showInactive?: Types.InputMaybe<Types.Scalars['Boolean']>
  fulfilmentMethods:
    | Array<Types.MenuItemGroupFulfillmentMethod>
    | Types.MenuItemGroupFulfillmentMethod
  endOfPrep?: Types.InputMaybe<Types.Scalars['DateTime']>
}>

export type GetOutletMenuQuery = {
  menuItemGroupsForOutlet: Array<{
    __typename: 'OutletMenuItemGroup'
    id: string
    name: string
    description: string | null
    image: string | null
    showMenuThumbnails: boolean | null
    position: number | null
    narrowFulfilmentMethods: Array<Types.NarrowFulfilmentMethodInputType>
    availabilityStartDate: string | null
    availabilityEndDate: string | null
    addOnItemsMenu: boolean
    availabilityTimesArray: Array<{
      __typename: 'AvailabilityTime'
      id: string
      start: { __typename: 'DayAndTime'; id: string; day: number; time: string }
      end: { __typename: 'DayAndTime'; id: string; day: number; time: string }
    }>
    parentMenu: {
      __typename: 'MenuItemGroup'
      id: string
      narrowFulfilmentMethods: Array<Types.NarrowFulfilmentMethodInputType>
      availabilityStartDate: string | null
      availabilityEndDate: string | null
      availabilityTimesArray: Array<{
        __typename: 'AvailabilityTime'
        id: string
        start: {
          __typename: 'DayAndTime'
          id: string
          day: number
          time: string
        }
        end: { __typename: 'DayAndTime'; id: string; day: number; time: string }
      }>
    } | null
    outletMenuItems: Array<{
      __typename: 'OutletMenuItem'
      id: string
      menuItemId: string
      name: string
      description: string | null
      price: number
      allergens: Array<Types.Allergen> | null
      soldOut: boolean | null
      spiceLevel: number
      maxPurchaseQuantity: number | null
      image: string | null
      ageRestricted: boolean | null
      featuredImages: Array<{
        __typename: 'FeaturedImage'
        id: string
        caption: string | null
        src: string
      }>
    }>
  }>
}

export const GetOutletMenuDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOutletMenu' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'outletId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showInactive' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fulfilmentMethods' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'MenuItemGroupFulfillmentMethod',
                  },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endOfPrep' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'menuItemGroupsForOutlet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'outletId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'outletId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fulfillmentMethods' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fulfilmentMethods' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endOfPrep' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endOfPrep' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getUnavailableBySchedule' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showInactive' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showInactive' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showInactive' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'showMenuThumbnails' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'narrowFulfilmentMethods' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availabilityTimesArray' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'start' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'day' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'end' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'day' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'time' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availabilityStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availabilityEndDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addOnItemsMenu' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parentMenu' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'narrowFulfilmentMethods',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availabilityStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availabilityEndDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availabilityTimesArray' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'start' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'day' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'time' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'end' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'day' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'time' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'outletMenuItems' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'showInactive' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'showInactive' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'menuItemId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allergens' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'soldOut' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'spiceLevel' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'maxPurchaseQuantity' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'featuredImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'caption' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'src' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ageRestricted' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOutletMenuQuery, GetOutletMenuQueryVariables>
