import styled from 'styled-components'

import { basketWidth } from '@src/constants/basketWidth'
import { breakpoints } from '@src/constants/breakpoints'

export const ContentWrapper = styled.main`
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 50%;
  min-height: 100vh;
`

export const MainContent = styled.div<{
  isOutletMenuView: boolean | undefined
}>`
  
  min-height: 100vh;
      padding-bottom: 32px;

  @media (max-width: ${breakpoints.tablet}px) {
    padding-bottom: 0px;
    min-height: ${({ isOutletMenuView }) =>
      !isOutletMenuView ? '100vh' : 'auto'}
`

export const Container = styled.div`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.siteBackground};
  display: flex;
`

export const CheckoutContainer = styled.aside`
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 3;

  @media (min-width: ${breakpoints.wideDesktop}px) {
    box-sizing: border-box;
    height: 100vh;
    width: ${basketWidth.regular}px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;

    position: sticky;
    top: 0;

    display: flex;
    flex-direction: column;

    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.4) 0px -8px 24px;
  }

  @media (min-width: ${breakpoints.superWideDesktop}px) {
    width: ${basketWidth.superWideDesktop}px;
  }
`
