import styled from 'styled-components'

import { CouponSVG } from '@src/components/SVGS/CouponSVG'
import { breakpoints } from '@src/constants/breakpoints'

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const MenuItemTable = styled.div`
  width: 100%;
  margin: 8px 0 4px 0;
`

export const MenuItemsSubContainer = styled(FlexContainer)`
  justify-content: space-between;
  padding: 8px 16px 0px 16px;
  &:last-child {
    padding-bottom: 4px;
    border-bottom: none;
  }
`

export const MenuItemName = styled.div`
  font-weight: 700;
  font-size: 14px;
  flex: 1;
  margin-right: 16px;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-right: 8px;
    margin-left: 10px;
  }
`

export const MenuItemPrice = styled(FlexContainer)`
  font-weight: 700;
  font-size: 14px;
`

export const StyledCouponSVG = styled(CouponSVG)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.discount};
`

export const MenuItemQuantity = styled.div`
  font-weight: 700;
  font-size: 14px;
  padding-right: 8px;
`

export const OptionItemsContainer = styled.div`
  width: 100%;
`

export const OptionItemSubContainer = styled(MenuItemsSubContainer)`
  padding: 4px 16px;
  border-bottom: none;
`

export const OptionItemName = styled.div`
  font-size: 12px;
  margin-left: 24px;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-left: 32px;
  }
`

export const OptionItemPrice = styled.div`
  font-size: 12px;
  text-align: right;
`
