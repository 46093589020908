import React from 'react'
import { useTranslation } from 'react-i18next'

import { NarrowFulfilmentMethodInputType } from '@src/../__generated__/globalTypes'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { useBasketTotals } from '@src/hooks/useTotals/useBasketTotals'

import {
  Container,
  DiscountRow,
  Row,
  TableData,
  TableHeaderRow,
  TotalsData,
  TotalsRow,
} from './Totals.styles'

export const Totals: React.FC<{
  addressIdOverride?: string
  isCheckoutTextVisible?: boolean
}> = ({ addressIdOverride, isCheckoutTextVisible = false }) => {
  const { partnerChargeDescription, partnerTableChargeDescription } =
    useMarketplace()
  const { t } = useTranslation('checkout')
  const {
    data: { currentFulfilment },
  } = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const basketTotals = useBasketTotals({
    addressIdOverride,
  })

  const formatCurrency = useFormatCurrency()

  // can only be no data if there is an error or it's on first load
  if (!basketTotals.data) {
    // handle errors
    if (basketTotals.error) {
      // unexpected error - automatically handled globally with toast
      return null
    }
    // Loading must be true if !data && !error
    // TODO: Skeleton
    return null
  }

  const {
    data: { checkoutBasketTotals },
  } = basketTotals

  const fulfilmentLabel = t(currentFulfilment.narrowType.toLowerCase())

  if (!checkoutBasketTotals) return null

  const serviceChargeLabel =
    currentFulfilment.narrowType === NarrowFulfilmentMethodInputType.TABLE
      ? partnerTableChargeDescription
      : partnerChargeDescription

  return (
    <>
      <Container isCheckoutTextVisible={isCheckoutTextVisible}>
        <thead>
          <TableHeaderRow>
            <th>{t('charge_label')}</th>
            <th>{t('cost')}</th>
          </TableHeaderRow>
        </thead>

        <tbody>
          <Row>
            <TableData>{t('subtotal')}</TableData>
            <TableData>
              {formatCurrency(checkoutBasketTotals.nonAddOnBasketItemsTotal)}
            </TableData>
          </Row>

          {checkoutBasketTotals.appliedDiscount &&
            checkoutBasketTotals.appliedDiscount.reductionAmount > 0 && (
              <DiscountRow>
                <TableData>
                  {checkoutBasketTotals.appliedDiscount.discount.name}
                </TableData>
                <TableData>
                  {formatCurrency(
                    checkoutBasketTotals.appliedDiscount.reductionAmount,
                    '-'
                  )}
                </TableData>
              </DiscountRow>
            )}

          {checkoutBasketTotals.addOnBasketItemsTotal > 0 && (
            <DiscountRow>
              <TableData>{t('offer_items')}</TableData>
              <TableData>
                {formatCurrency(checkoutBasketTotals.addOnBasketItemsTotal)}
              </TableData>
            </DiscountRow>
          )}

          {checkoutBasketTotals.fulfilmentCharge !== 0 && (
            <Row>
              <TableData>{`${t(`${fulfilmentLabel}`)} ${
                checkoutBasketTotals.isFixedFulfilment ? '' : t('from')
              }`}</TableData>
              <TableData>
                <>{formatCurrency(checkoutBasketTotals.fulfilmentCharge)}</>
              </TableData>
            </Row>
          )}

          {checkoutBasketTotals.serviceCharge > 0 && (
            <Row>
              <TableData>
                {serviceChargeLabel || t('platform_charge')}
              </TableData>
              <TableData>
                {formatCurrency(checkoutBasketTotals.serviceCharge)}
              </TableData>
            </Row>
          )}

          <TotalsRow>
            <TotalsData>{t('total')}</TotalsData>
            <TotalsData>
              {formatCurrency(checkoutBasketTotals.sumTotal)}
            </TotalsData>
          </TotalsRow>
        </tbody>
      </Container>
    </>
  )
}
