import { toUpper } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFormatCurrency } from '@src/hooks/useFormatCurrency'

import {
  ActionButton,
  Container,
  ItemCount,
  Price,
  StruckOutText,
  StyledMinusSVG,
  StyledPlusSVG,
  StyledPricePlusSVG,
} from './ItemCounter.styles'

export const ItemCounter: React.FC<{
  isDisabled?: boolean
  price: number
  oldPrice?: number
  count: number
  handleAdd?: (e: React.MouseEvent<HTMLButtonElement>) => void
  handleRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isMenuItem?: boolean
  allowAddItem?: boolean
  allowRemoveItem?: boolean
  allowZeroCount?: boolean
  addItemText?: string
  hidePrice?: boolean
  dataTooltipId?: string
  dataTestId?: string
}> = ({
  isDisabled = false,
  price,
  count,
  handleAdd,
  handleRemove,
  isMenuItem,
  allowAddItem = true,
  allowRemoveItem = true,
  allowZeroCount = false,
  addItemText,
  oldPrice,
  hidePrice,
  dataTooltipId,
  dataTestId,
}) => {
  const { t } = useTranslation('menuItem')
  const formatCurrency = useFormatCurrency()
  const priceText = () => {
    if (hidePrice) return null
    if (allowZeroCount) return null
    if (isDisabled) return null
    if (price === 0 && oldPrice === undefined)
      return <span>{toUpper(t('select'))}</span>
    return (
      <>
        {oldPrice !== undefined && (
          <StruckOutText>{formatCurrency(oldPrice)}</StruckOutText>
        )}
        <span>{formatCurrency(price)}</span>
      </>
    )
  }

  const getCounterText = () => {
    if (count === 0) {
      return allowZeroCount ? 0 : <>{formatCurrency(price)}</>
    }
    return count
  }

  if (allowRemoveItem && !allowZeroCount && count === 0)
    return (
      <Price
        isDisabled={isDisabled}
        isMenuItem={isMenuItem}
        data-test-id={dataTestId}
      >
        {priceText()}

        <StyledPricePlusSVG
          id="plus"
          data-tooltip-id={dataTooltipId}
          data-tooltip-content={t('add_item')}
          data-test-id="add_item"
          ignoreTitle
        />
      </Price>
    )

  return (
    <Container disabled={isDisabled}>
      <ActionButton
        onClick={handleRemove}
        disabled={!allowRemoveItem && count === (allowZeroCount ? 0 : 1)}
        data-tooltip-id={dataTooltipId}
        data-tooltip-content={t('remove_item')}
      >
        <StyledMinusSVG id="minus" ignoreTitle />
      </ActionButton>

      <ItemCount>{getCounterText()}</ItemCount>

      {!count && !allowZeroCount ? null : (
        <ActionButton
          onClick={handleAdd}
          disabled={!allowAddItem}
          data-tooltip-id={dataTooltipId}
          data-tooltip-content={addItemText || t('add_item')}
        >
          <StyledPlusSVG id="plus" ignoreTitle />
        </ActionButton>
      )}
    </Container>
  )
}
