export const checkout = {
  track_order: 'Track Order',
  order_confirmed: 'Thanks for your order with',
  order_no: 'Order #{{orderNumber}}',
  collection_time: 'Collection Time',
  closes: 'closes',
  directions: 'Directions',
  delivery_time: 'Delivery Time',
  confirmation_updates_sent_to: 'Confirmation & updates sent to',
  placing_order: 'Placing Order',
  place_order: 'Place Order',
  pay_now: 'Pay Now',
  pay_later: 'Pay Later',
  checkout: 'Checkout',
  collection_address: 'Collection Address',
  collection_note_example: 'e.g. Please bag sandwiches separately',
  collection_note_example_retail: 'Add a collection note for the business',
  table_note_example: 'e.g. No ice in the drink please',
  house_number: {
    label: 'House Number/Name',
  },
  street_name: {
    label: 'Street Name',
  },
  city: {
    label: 'City',
  },
  postcode: {
    label: 'Postcode',
  },
  country_code: {
    label: 'Country',
  },
  update_address: 'Update Address',
  edit: 'Edit',
  save: 'Save',
  add_address: 'Add Address',
  delivery_note: 'Delivery Note',
  delivery_note_example: 'e.g. We are the house with the red door',
  address_book: 'Address Book',
  select_or_add_address: 'Delivery Address',
  please_select_address: 'Please select or add a valid delivery address',
  payment: 'Payment',
  payment_method: 'Payment Method',
  select_payment_method: 'How would you like to pay?',
  card: 'Card',
  manual_confirmation_card: 'Card',
  cash: 'Cash',
  cash_info: 'Payment when you receive your items.',
  apple_pay: 'Apple Pay',
  google_pay: 'Google Pay',
  add_card: 'New Card / Payment Method',
  continue_to_payment: 'Continue To Payment',
  restricted_items: 'Restricted Items *',
  age_statement: 'I am over 18 years old & have valid ID',
  request_contactless: 'Request delivery to be left at door',
  your_order: 'Your Order',
  basket: 'Basket',
  allergy_notice: 'Have a Food Allergy?',
  clear: 'Clear',
  add_note: 'Note',
  order_note: 'Add Order Note',
  order_note_example_retail: 'Add a note to your order.',
  order_note_example: 'e.g. No onions on my burger please',
  charge_label: 'Charge Label',
  cost: 'Cost',
  subtotal: 'Subtotal',
  delivery: 'Delivery',
  from: 'from',
  collection: 'Collection',
  table: 'Table Service',
  how_will_we_find_you: 'How will we find you?',
  your_table: 'Select Your Table',
  select: 'Select',
  platform_charge: 'Platform Charge',
  total: 'Total',
  edit_order: 'Edit Order',
  empty_basket: 'Your basket is empty, add some items to get started.',
  view_active_orders: 'View My Active Orders',
  change: 'Change?',
  table_service_note: 'Table Service Note',
  service_note_example:
    'Include any notes for your server here, e.g. Please bring the olives as a starter',
  service_note_example_retail: 'Include any notes for your server here',
  pay: 'Pay',
  no_payment_methods: 'Sorry, no payment methods are available.',
  no_payment_methods_table:
    'Sorry, no payment methods are available for table service.',
  no_payment_methods_delivery:
    'Sorry, no payment methods are available for delivery. Please choose a different fulfilment method.',
  no_payment_methods_collection:
    'No payment methods are available for collection.',
  asap_by: 'ASAP by',
  allergy_guidance: 'Allergy Guidance',
  allergy_information:
    'Please DO NOT enter allergy related requests in your order notes. \n\nAny allergy information provided is intended as a guide. ALWAYS contact the restaurant before placing an order, as preparation and ingredients can change without notice.',
  call_restaurant: 'Call Restaurant',
  view_allergy_policy: 'View Allergy Policy',
  collection_note: 'Add Collection Note',
  discount_code: 'Discount Code',
  apply: 'Apply',
  clear_basket: 'Clear Basket?',
  clear_basket_desc:
    'Are you sure you want to remove all items from your basket?',
  remove_item: 'Remove Item?',
  remove_item_desc:
    'Are you sure you would like to remove {{itemName}} from your basket?',
  cancel: 'Cancel',
  confirm: 'Remove',
  delivery_unavailable: 'Delivery is not available for this address.',
  search_this_address: 'Search for this address instead?',
  minimum_subtotal_not_reached:
    'Minimum basket subtotal of {{minimumSubtotal}} has not been reached.',
  fulfilment_window_error: 'Please choose a {{fulfilment}} window',
  table_select_error: 'Please select your table',
  error_message: 'Sorry, something went wrong.',
  card_payment_unavailable: 'Sorry, card payment is unavailable at this time.',
  card_payment_failed:
    'Sorry, card payment could not be accepted. Please try again or use a different payment method.',
  continue: 'Continue',
  offers: 'Offers',
  sign_in_and_checkout: 'Sign In & Checkout',
  coming_soon: 'Coming Soon!',
  back: 'Back',
  discount: 'Discount',
  offer_items: 'Offer Items',
  new_discount_applied:
    'A new discount has been automatically applied and your basket has been discounted an extra {{savings}}.',
  please_confirm: 'Please check the below requirement',
  address_error: 'Please select a different address',
  select_table: 'Select Table',
  number_of_items: `{{numberOfItems}} Items`,
  remove_item_note: 'Remove item note',
  reached_max_purchase_quantity: 'You cannot order any more of this item',

  order_summary: {
    order_summary: 'Order Summary',
    collection: 'Collect {{time}}',
    delivery: 'Delivered {{time}}',
    offer_items_excluded: 'Offer items are excluded from your other discounts',
    item: 'Item',
    cost: 'Cost',
    offer_item: 'Offer Item',
    offer_item_desc: 'Offer items are discounted items offered at checkout.',
    asap: 'ASAP',
    arriving_by: 'Arriving by',
    ready_by: 'Ready by',
  },
  information_icon: 'Information icon',
  outlet_not_found: 'Sorry, this outlet is no longer accepting orders.',
  no_delivery_available:
    'Unfortunately delivery is not available for this location',
  clear_basket_title: 'Clear Basket',
  select_payment_error: 'Please enter a payment method',
  arriving_by: 'Arriving by',
  ready_by: 'Ready by',
  unexpected_create_address_error:
    'There was an unexpected error adding your address',
  field_required: '{{field}} is required',
  offers_for_you: 'Your Offers',
  fulfilment_unavailable: '{{narrowFulfilmentMethod}} is currently unavailable',
  ordering_unavailable: 'Ordering is currently unavailable',
  address_unavailable: 'Delivery is unavailable to this address',
  table_id_required: 'Please select your table',
  confirm_address_title: 'Confirm order to this address?',
  confirm_address: 'Confirm Delivery Address',
}
