import React from 'react'

export const PinSmallSVG: React.VFC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="12"
      width="12"
      viewBox="0 0 12 12"
    >
      <g fill="currentColor">
        <path
          d="m10.185,4.744c0,1.899-2.482,4.948-3.624,6.25-.299.341-.825.341-1.123,0-1.141-1.301-3.624-4.351-3.624-6.25,0-2.527,2.163-3.994,4.185-3.994s4.185,1.467,4.185,3.994Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <circle
          cx="6"
          cy="5"
          r="1"
          fill="currentColor"
          strokeWidth="0"
          data-color="color-2"
        ></circle>
      </g>
    </svg>
  )
}
