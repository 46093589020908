import styled, { css } from 'styled-components'

import { Button } from '@src/components/Button'
import { breakpoints } from '@src/constants/breakpoints'

export const Container = styled.div<{ windowHeight: number }>`
  display: flex;
  flex-direction: column;
  height: ${props => props.windowHeight - 160}px;
  padding: 0px 24px 0 24px;
  overflow-y: scroll;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0px 32px;
    margin-top: 0px;
    height: 100%;
  }
`

export const Description = styled.p`
  margin-top: 24px;
  font-size: 20px;
  font-weight: 700;
`

export const SingleItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  background-color: #f6f6f9;
  border-radius: 4px;
  padding: 8px 16px;
  padding-top: 16px;
  margin: 8px 0;
`

export const Name = styled.p`
  font-size: 15px;
  font-weight: 700;
  margin: 0px;

  @media (min-width: ${breakpoints.tablet}px) {
    font-size: 16px;
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImageContainer = styled.div`
  width: 128px;
`

export const ChilliContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2px;
`

export const SpaceBetweenRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PriceContainer = styled.div`
  display: flex;
`

export const StrikethroughPrice = styled.p`
  font-size: 14px;
  text-decoration: line-through;
  font-weight: 400;
  padding: 7px 5px;
  margin: 0;
`

export const Price = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin: 4px;
`

export const ItemImage = styled.div<{ imageURL: string }>`
  width: 128px;
  height: ${({ imageURL }) => (imageURL ? '128px' : 'unset')};
  background-color: white;
  margin: 0 8px 8px 0;
  background-image: url(${({ imageURL }) => imageURL});
  background-size: cover;
  border-radius: 4px;
`

export const AddOnItemPrice = styled.span`
  color: ${({ theme }) => theme.colors.brandHighlight};
  font-size: 16px;
  font-weight: 700;
`

export const AddOnItemRegularPrice = styled.span`
  color: ${({ theme }) => theme.colors.mainText};
  opacity: 0.6;
  font-size: 16px;
  text-decoration: line-through;
`

export const AddOnItemDetails = styled.div<{ selectedAnItem: boolean }>`
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: ${props =>
    props.selectedAnItem ? 'space-between' : 'unset'};
  > :last-child {
    margin-left: auto;
  }
  > :nth-child(2) {
    margin-left: 6px;
  }
`

export const AddOnDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 12px;
  justify-content: space-between;
  margin: 8px 0 8px 0;
`

export const AddOnItemContainer = styled.div<{
  noHover?: boolean
  isDisabled?: boolean
}>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(150, 150, 150, 0.2);
  @media (min-width: ${breakpoints.tablet}px) {
    border-width: 2px;
  }
  &:last-child {
    border-bottom: none;
  }
  ${props =>
    props.noHover
      ? css`
          &:hover {
            h4 {
              color: ${({ theme }) => theme.colors.brand};
            }
          }
          cursor: ${props.isDisabled ? 'default' : 'pointer'};
        `
      : ''}
`

export const AddOnButton = styled(Button)`
  font-size: 12px;
  height: 32px;
  min-height: 0;
  padding: 8px 8px;
  text-transform: uppercase;
`

export const AddOnPriceContainer = styled.div`
  min-width: 100px;
  display: flex;
  gap: 12px;
`

export const AllergenTagHolder = styled.div`
  margin-bottom: 8px;
`

export const HeaderHolder = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`
