import React from 'react'

export const TimeSVG: React.VFC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <polyline
          points="9 4.75 9 9 12.25 11.25"
          data-color="color-2"
        ></polyline>
        <path d="M9,1.75c4.004,0,7.25,3.246,7.25,7.25s-3.246,7.25-7.25,7.25"></path>
        <circle
          cx="3.873"
          cy="14.127"
          r=".75"
          fill="currentColor"
          data-stroke="none"
          stroke="none"
        ></circle>
        <circle
          cx="1.75"
          cy="9"
          r=".75"
          fill="currentColor"
          data-stroke="none"
          stroke="none"
        ></circle>
        <circle
          cx="3.873"
          cy="3.873"
          r=".75"
          fill="currentColor"
          data-stroke="none"
          stroke="none"
        ></circle>
        <circle
          cx="6.226"
          cy="15.698"
          r=".75"
          fill="currentColor"
          data-stroke="none"
          stroke="none"
        ></circle>
        <circle
          cx="2.302"
          cy="11.774"
          r=".75"
          fill="currentColor"
          data-stroke="none"
          stroke="none"
        ></circle>
        <circle
          cx="2.302"
          cy="6.226"
          r=".75"
          fill="currentColor"
          data-stroke="none"
          stroke="none"
        ></circle>
        <circle
          cx="6.226"
          cy="2.302"
          r=".75"
          fill="currentColor"
          data-stroke="none"
          stroke="none"
        ></circle>
      </g>
    </svg>
  )
}
