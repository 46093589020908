import React from 'react'

export const TikTokSVG: React.FC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g fill="currentColor">
        <path
          d="M6.793,5.958V8.706a2.392,2.392,0,0,0-3,2.216,2.163,2.163,0,0,0,2.312,2.3,2.155,2.155,0,0,0,2.311-2.326V0h2.719c.463,2.876,1.9,3.544,3.827,3.852V6.608a8.19,8.19,0,0,1-3.75-1.266v5.445C11.21,13.25,9.75,16,6.125,16A5.11,5.11,0,0,1,1.04,10.761,5.01,5.01,0,0,1,6.793,5.958Z"
          fill="currentColor"
        ></path>
      </g>
    </svg>
  )
}
