import React from 'react'

export const TimeSmallSVG: React.VFC<{
  className?: string
}> = ({ className }) => {
  return (
    <svg
      className={className}
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      height="12"
      width="12"
      viewBox="0 0 12 12"
    >
      <g
        strokeLinecap="round"
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        strokeLinejoin="round"
      >
        <path
          d="m7.282,11.093l-.009-.002.009.002Z"
          strokeWidth="0"
          fill="currentColor"
        ></path>
        <path d="m6,.75c2.899,0,5.25,2.35,5.25,5.25,0,2.899-2.35,5.25-5.25,5.25"></path>
        <path d="m6,3.25v2.75l2,1.75" data-color="color-2"></path>
        <circle
          cx=".75"
          cy="6"
          r=".75"
          fill="currentColor"
          strokeWidth="0"
          data-color="color-2"
        ></circle>
        <circle
          cx="1.453"
          cy="3.375"
          r=".75"
          fill="currentColor"
          strokeWidth="0"
          data-color="color-2"
        ></circle>
        <circle
          cx="3.375"
          cy="1.453"
          r=".75"
          fill="currentColor"
          strokeWidth="0"
          data-color="color-2"
        ></circle>
        <circle
          cx="1.453"
          cy="8.625"
          r=".75"
          fill="currentColor"
          strokeWidth="0"
          data-color="color-2"
        ></circle>
        <circle
          cx="3.375"
          cy="10.547"
          r=".75"
          fill="currentColor"
          strokeWidth="0"
          data-color="color-2"
        ></circle>
      </g>
    </svg>
  )
}
