export const outletCard = {
  minimum_order: 'min. order',
  no_minimum_order: 'no min. order',
  x_offers_available: '{{discountCount}} Offers Available',
  x_offers: '{{discountCount}} x OFFERS',
  x_offer: '{{discountCount}} x OFFER',
  delivery: 'Delivery',
  collection: 'Collection',
  table: 'Table Service',
}
