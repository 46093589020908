import { format } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { OutletStatusText } from '@src/graphql-types'

import {
  OutletTranslationText,
  OutletTranslationContainer,
} from '../SingleOutlet/SingleOutlet.styles'

const DEFAULT_DATETIME_FORMAT = 'EEE HH:mm'

export const OutletStatusTranslationText: React.FC<{
  statusText: OutletStatusText
  isOutletPage?: boolean
}> = ({ statusText, isOutletPage = true }) => {
  const { t } = useTranslation('outletStatus')
  const { key, datetime, datetimeFormat, days } =
    statusText.openingTimeTranslation
  let val = ''
  if (datetime) {
    val = format(new Date(datetime), datetimeFormat || DEFAULT_DATETIME_FORMAT)
  } else if (days) {
    val = days
  }

  if (isOutletPage) return <span>{t(key, { val })}</span>
  return (
    <OutletTranslationContainer>
      <OutletTranslationText
        openingStatus={statusText.orderButtonTranslation}
        isOutletPage={isOutletPage}
      >
        {t(key, { val })}
      </OutletTranslationText>
    </OutletTranslationContainer>
  )
}
