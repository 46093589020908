import { Formik, Form } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button } from '@src/components/Button/Button'
import { TextInput, CheckboxInput, SelectInput } from '@src/components/Inputs'

import {
  DefaultAddressContainer,
  DefaultAddressFont,
  StyledStarSVG,
  CheckboxWrapper,
  NameTitle,
} from './AddressForm.styles'
import { addressSchema, Address } from './validation/addressSchema'

type AddressFormProps = {
  initialValues?: {
    name: string | null
    firstLine: string
    secondLine: string | null
    thirdLine: string | null
    countryCode: string | null
    city: string
    postcode: string
    default: boolean
  }
  onSubmit: (address: Address) => void
  submitButtonText: string
  disabled?: boolean
  loading?: boolean
  availableCountries?: Array<{ value: string; name: string }>
  dataTestId?: string
}

export const AddressForm: React.FC<AddressFormProps> = ({
  initialValues = {
    name: null,
    firstLine: '', // House number
    secondLine: '', // Street name
    thirdLine: null, // Not used
    city: '',
    countryCode: 'GB',
    postcode: '',
    default: false,
  },
  onSubmit,
  submitButtonText,
  disabled = false,
  loading = false,
  // TODO where do these come from maybe we need to assign available ones to marketplace
  availableCountries = [{ value: 'GB', name: 'United Kingdom' }],
  dataTestId,
}) => {
  const { t } = useTranslation('addressForm')
  return (
    <Formik
      validationSchema={toFormikValidationSchema(addressSchema)}
      initialValues={initialValues}
      validateOnBlur
      onSubmit={values => {
        // safe parse as we do nothing here if there is a validation error.
        // The reason we do nothing is you cant submit until validation is met so this is just
        // instead of casting the values which is unsafe.
        const result = addressSchema.safeParse(values)
        if (result.success && result.data) {
          onSubmit(result.data)
        }
      }}
    >
      <Form>
        {initialValues.default && (
          <DefaultAddressContainer>
            <DefaultAddressFont>
              <StyledStarSVG />
              {t('default.already_defaulted')}
            </DefaultAddressFont>
          </DefaultAddressContainer>
        )}

        <TextInput
          name="firstLine"
          label={t('house_number.label')}
          required
          disabled={disabled || loading}
          autoComplete="off"
          focusOnLoad
          dataTestId="add-address-house-number-input"
        />

        <TextInput
          name="secondLine"
          label={t('street_name.label')}
          required
          disabled={disabled || loading}
          autoComplete="off"
          dataTestId="add-address-street-name-input"
        />

        <TextInput
          name="city"
          label={t('city.label')}
          required
          disabled={disabled || loading}
          autoComplete="off"
          dataTestId="add-address-city-input"
        />

        <SelectInput
          name="countryCode"
          label={t('country_code.label')}
          options={availableCountries}
          required
          dataTestId="add-address-country-input"
        />

        <TextInput
          name="postcode"
          label={t('postcode.label')}
          required
          disabled={disabled || loading}
          autoComplete="off"
          toUpperCase
          dataTestId="add-address-post-code-input"
        />

        {/* Prompt user to name address if they haven't already */}
        {!initialValues.name && <NameTitle>{t('name.title')}</NameTitle>}
        <TextInput
          name="name"
          label={t('name.label')}
          disabled={disabled || loading}
          autoComplete="off"
          placeholder={t('name.placeholder')}
          maxLength={30}
          dataTestId="add-address-name-input"
        />

        {/* only allow users to select as default address, not to un-select as they can do this on the address list */}
        {!initialValues.default && (
          <CheckboxWrapper>
            <CheckboxInput
              name="default"
              label={t('default.label')}
              autoComplete="off"
            />
          </CheckboxWrapper>
        )}

        <Button
          type="submit"
          content={submitButtonText}
          disabled={disabled}
          loading={loading}
          dataTestId={dataTestId}
        />
      </Form>
    </Formik>
  )
}
