import React, { ReactNode } from 'react'
import styled from 'styled-components'

import { buttonMap, buttonLinkMap, Spinner } from './Button.styles'
import { ButtonType } from './ButtonTypesEnum'

import { LinkProps } from '../Router/Link'

export { ButtonType } from './ButtonTypesEnum'

// PRIMARY: black background, white text (large button)
// SECONDARY: white background with black border, black text (large button)
// TERTIARY: white background with black border, black text (small button)

// rounded: rounds corners of the button (applicable to only tertiary buttons)
// loader: swaps content for loading button and makes it non-clickable
// disabled: puts the content at half opacity
// danger: changes the button text and outline to red (secondary or tertiary only)

export type Props = {
  buttonType?: ButtonType
  label?: string
  width?: string
  type?: 'button' | 'submit' | 'reset'
  content: string | ReactNode
  className?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  loading?: boolean
  rounded?: boolean
  disabled?: boolean
  dataTestId?: string
}

export const Button: React.FC<Props> = ({
  label,
  content,
  width,
  buttonType = ButtonType.PRIMARY,
  type = 'button',
  className,
  onClick = () => null,
  loading,
  rounded,
  disabled,
  dataTestId,
}) => {
  const Button = buttonMap[buttonType]
  return (
    <Button
      className={className}
      type={type}
      width={width}
      aria-label={label}
      onClick={onClick}
      $loading={loading}
      $rounded={rounded}
      disabled={disabled || loading}
      data-test-id={dataTestId}
    >
      {loading ? (
        <Spinner>
          <div></div>
        </Spinner>
      ) : (
        content
      )}
    </Button>
  )
}

export const ButtonLink: React.FC<
  LinkProps & {
    buttonType?: ButtonType
    disabled?: boolean
  }
> = ({ buttonType = ButtonType.PRIMARY, children, disabled, ...linkProps }) => {
  const ButtonLink = buttonLinkMap[buttonType]

  return (
    <ButtonLink $isDisabled={disabled} {...linkProps}>
      {children}
    </ButtonLink>
  )
}

export const UnstyledButton = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
  outline: none;
`
