import React from 'react'

import {
  CategorySlideContent,
  CategorySlideImage,
  CategorySlideTitle,
} from '@src/components/BusinessSegments/CategorySlide.styles'
import { UnstyledButton } from '@src/components/Button/Button'
import { baseUrlImages } from '@src/config/urls'
import { CategoryEmojiOrImage, Cuisine, MerchantType } from '@src/graphql-types'
import { useCategories } from '@src/hooks/useCategories'
import { useMarketplace } from '@src/hooks/useMarketplace'

export const CategorySlide = ({ category }: { category: Cuisine }) => {
  const marketplace = useMarketplace()
  const { setCategoryNames } = useCategories()

  const isImage =
    marketplace.categoryEmojiOrImage === CategoryEmojiOrImage.IMAGE &&
    !!category.imageSrc

  let imageToDisplay = null

  if (isImage) {
    imageToDisplay = category.imageSrc
  } else {
    imageToDisplay =
      category.emoji ||
      (marketplace.merchantType === MerchantType.RESTAURANT ? '🍽️' : '📦')
  }

  const updateCategoryParams = () => {
    setCategoryNames({
      categories: [category.name],
    })
    window.scrollTo(0, 0)
  }

  return (
    <UnstyledButton onClick={updateCategoryParams}>
      <CategorySlideContent>
        <CategorySlideImage>
          {isImage ? (
            <img
              alt={category.name}
              src={`${baseUrlImages}/${imageToDisplay}`}
            />
          ) : (
            imageToDisplay
          )}
        </CategorySlideImage>
      </CategorySlideContent>
      <CategorySlideTitle>
        <span>{category.name}</span>
      </CategorySlideTitle>
    </UnstyledButton>
  )
}
