import { useQuery } from '@apollo/client'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { BusinessSegmentCategories } from '@src/components/BusinessSegments/BusinessSegmentCategories'
import { BusinessSegmentOutlets } from '@src/components/BusinessSegments/BusinessSegmentOutlets'
import { FeaturedOutlets } from '@src/components/FeaturedOutlets'
import { FeaturedOutletsSkeleton } from '@src/components/FeaturedOutlets/FeaturedOutletsSkeleton'
import {
  BusinessSegmentType,
  FulfilmentFilterWhenType,
  LocationType,
  NarrowFulfilmentMethodInputType,
} from '@src/graphql-types'
import { useFulfilmentFilter } from '@src/hooks/useFulfilmentFilter/useFulfilmentFilter'
import { useMarketplace } from '@src/hooks/useMarketplace'
import { getOutletSearchVariables } from '@src/pages/OutletList/getOutletSearchVariables'
import { imageJitURL } from '@src/utils/imageJitURL'

import { Hero } from './Hero/Hero'
import { Container } from './Home.styles'
import { getBusinessSegmentsDocument } from './queries/__generated__/getBusinessSegments.graphql-interface'
import { getMarketplaceHeroDocument } from './queries/__generated__/getMarketplaceHero.graphql-interface'

export const Home: React.FC = () => {
  const { t } = useTranslation('homePage')
  const marketplace = useMarketplace()
  const fulfilmentFilter = useFulfilmentFilter()
  const { data, loading, error } = useQuery(getMarketplaceHeroDocument, {
    variables: { cname: window.location.hostname },
  })
  const segmentVariables = {
    fulfilmentMethod: fulfilmentFilter.data.priorityFulfilmentMethod,
    acceptedFulfilmentMethods: fulfilmentFilter.data.nonEmptyFulfilmentMethods,
  }
  const outletVariables = getOutletSearchVariables({
    fulfilmentFilter: fulfilmentFilter.data,
    marketplaceId: marketplace.id,
    cuisineIds: [],
  })

  const { postAndCountryCode, locationType, whenType, ...otherVars } =
    outletVariables.input

  const segmentsResponse = useQuery(getBusinessSegmentsDocument, {
    variables: {
      marketplaceId: marketplace.id,
      outletsInput: {
        ...otherVars,
        whenType: FulfilmentFilterWhenType.ANYTIME,
        locationType: LocationType.EVERYWHERE,
      },
      businessSegmentTypes: [BusinessSegmentType.OUTLETS],
      fulfilmentMethod: segmentVariables.fulfilmentMethod,
      acceptedFulfilmentMethods: [
        NarrowFulfilmentMethodInputType.DELIVERY,
        NarrowFulfilmentMethodInputType.COLLECTION,
        NarrowFulfilmentMethodInputType.TABLE,
      ],
      fulfilmentLocation: undefined,
    },
    skip: !data?.marketplaceByCname.id,
  })

  if (loading || !data || error) return null

  const { name, heroText, heroTextSecondary, heroImage } =
    data.marketplaceByCname

  return (
    <>
      <Container
        imageUrl={imageJitURL(heroImage, {
          resize: {
            width: 1280,
            height: 900,
            fit: 'cover',
          },
        })}
      >
        <Hero
          heroText={heroText || ''}
          heroTextSecondary={heroTextSecondary || ''}
        />
      </Container>
      {segmentsResponse.loading && <FeaturedOutletsSkeleton />}

      {!segmentsResponse.loading &&
        !segmentsResponse.data?.businessSegments.length && (
          <FeaturedOutlets
            title={t('now_on', {
              marketplaceName: name,
            })}
          />
        )}
      {data?.marketplaceByCname.id &&
        !!segmentsResponse.data?.businessSegments.length &&
        segmentsResponse.data?.businessSegments.map((segment, index) => (
          <>
            {segment.type === BusinessSegmentType.OUTLETS && (
              <BusinessSegmentOutlets
                key={`segmentOutlets-${index}`}
                businessSegment={segment}
              />
            )}
            {segment.type === BusinessSegmentType.CATEGORIES && (
              <BusinessSegmentCategories
                key={`segmentCategories-${index}`}
                segment={segment}
              />
            )}
          </>
        ))}
    </>
  )
}
