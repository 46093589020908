import * as Types from '../../../../../graphql-types'

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type getCardStripeSecretIdQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type getCardStripeSecretIdQuery = { getCardClientSecret: string }

export const getCardStripeSecretIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCardStripeSecretId' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getCardClientSecret' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  getCardStripeSecretIdQuery,
  getCardStripeSecretIdQueryVariables
>
